import React from "react";
import { Box, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import "./DashHome.css";
import DashboardLogo from "../../images/Group 33033.png";
import community from "../../images/icon/vmd.jpg";
import recipes from "../../images/restro.svg";
import location from "../../images/location.svg";
import dating from "../../images/dating.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "../../constants/toast";

const DashHome = () => {
  const user_data = useSelector((state) => state?.dashboard?.user_data);

  const datingOffNotification = () => {
    toast.error('Your dating feature is disabled!')
  }
  return (
    <>
      {/* <Box className="dashhome_mainBox_style">
        <img className="dashboard_logo" src={DashboardLogo} alt="logo" />
        <Grid container spacing={5} sx={{ p: 5 }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Link to="/DCommunity" id="dashhome-text-deco">
              <Paper className="paper" elevation={2} style={{ textAlign: "center", alignItems: "center", display: "flex", justifyContent: "center", flexDirection: "column", }} >
                <img id="paper-img" src={community} alt="community--image" /> <br /> <p id="dashhome-p">  <strong style={{ fontWeight: "800" }}>Community</strong> </p>
              </Paper>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Link to="/DPlace" id="dashhome-text-deco">
              <Paper className="paper" elevation={3} style={{ textAlign: "center", alignItems: "center", display: "flex", justifyContent: "center", flexDirection: "column", }} >
                <img id="paper-img" src={location} alt="community--image" /> <br /> <p id="dashhome-p"> <strong style={{ fontWeight: "800" }}>Places</strong> </p>
              </Paper>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} >
            {user_data?.dating == 1 ?
              <Link to="/DDating" id="dashhome-text-deco">
                <Paper className="paper" elevation={3} style={{ textAlign: "center", alignItems: "center", display: "flex", justifyContent: "center", flexDirection: "column", }} >
                  <img id="paper-img" src={dating} alt="community--image" /> <br /> <p id="dashhome-p"> <strong style={{ fontWeight: "800" }}>Dating</strong> </p>
                </Paper>
              </Link>
              : <Paper onClick={() => datingOffNotification()} className="paper" elevation={3} style={{ textAlign: "center", alignItems: "center", display: "flex", justifyContent: "center", flexDirection: "column", }} >
                <img id="paper-img" src={dating} alt="community--image" /> <br /> <p id="dashhome-p"> <strong style={{ fontWeight: "800" }}>Dating</strong> </p>
              </Paper>}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Link to="/DRacipes" id="dashhome-text-deco">
              <Paper className="paper" elevation={3} style={{ textAlign: "center", alignItems: "center", display: "flex", justifyContent: "center", flexDirection: "column", }} >
                <img id="paper-img" src={recipes} alt="community--image" /> <br /> <p id="dashhome-p"> <strong style={{ fontWeight: "800" }}>Recipes</strong> </p>
              </Paper>
            </Link>
          </Grid>
        </Grid>
      </Box> */}
      <div>
        <div style={{ position: "relative" }}>
          <img className="dashboard_logo" src={DashboardLogo} alt="logo" />
          <div className="row g-xxl-5 g-4">
            <div className="col-md-6 col-lg-6">
              <Link to="/DCommunity" id="dashhome-text-deco">
                <Paper className="paper" elevation={2} style={{ textAlign: "center", alignItems: "center", display: "flex", justifyContent: "center", flexDirection: "column", }} >
                  <img id="paper-img" src={community} alt="community--image" /> <br /> <p id="dashhome-p">  <strong style={{ fontWeight: "800" }}>Community</strong> </p>
                </Paper>
              </Link>
            </div>
            <div className="col-md-6 col-lg-6">
              <Link to="/DPlace" id="dashhome-text-deco">
                <Paper className="paper" elevation={3} style={{ textAlign: "center", alignItems: "center", display: "flex", justifyContent: "center", flexDirection: "column", }} >
                  <img id="paper-img" src={location} alt="community--image" /> <br /> <p id="dashhome-p"> <strong style={{ fontWeight: "800" }}>Places</strong> </p>
                </Paper>
              </Link>
            </div>
            <div className="col-md-6 col-lg-6">
              {user_data?.dating == 1 ?
                <Link to="/DDating" id="dashhome-text-deco">
                  <Paper className="paper" elevation={3} style={{ textAlign: "center", alignItems: "center", display: "flex", justifyContent: "center", flexDirection: "column", }} >
                    <img id="paper-img" src={dating} alt="community--image" /> <br /> <p id="dashhome-p"> <strong style={{ fontWeight: "800" }}>Dating</strong> </p>
                  </Paper>
                </Link>
                : <Paper onClick={() => datingOffNotification()} className="paper" elevation={3} style={{ textAlign: "center", alignItems: "center", display: "flex", justifyContent: "center", flexDirection: "column", }} >
                  <img id="paper-img" src={dating} alt="community--image" /> <br /> <p id="dashhome-p"> <strong style={{ fontWeight: "800" }}>Dating</strong> </p>
                </Paper>}
            </div>
            <div className="col-md-6 col-lg-6">
              <Link to="/DRacipes" id="dashhome-text-deco">
                <Paper className="paper" elevation={3} style={{ textAlign: "center", alignItems: "center", display: "flex", justifyContent: "center", flexDirection: "column", }} >
                  <img id="paper-img" src={recipes} alt="community--image" /> <br /> <p id="dashhome-p"> <strong style={{ fontWeight: "800" }}>Recipes</strong> </p>
                </Paper>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DashHome;