import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import "./Profile.css";
import { Button, Image } from "antd";
import dummyPic from "../../../images/CW/profile.jpeg";
import ProfileRecipe from "./ProfileRecipe";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ImgUrl } from "../../../constants/const";
import ProfileCommunity from "./ProfileCommunity";
import ProfileGallery from "./ProfileGallery";
import Spinner from "../../../constants/Spinner";


const Profile = () => {
  const [active, setActive] = useState("U");
  const [activeIndex, setActiveIndex] = useState("1");
  const [loading, setLoading] = useState(true);
  const user_data = useSelector((state) => state.dashboard.user_data);
  const htmlString = user_data?.bio;

  const NavigationButton = [{
    id: "1",
    title: "Uploads",
    fun: "U",
  }, {
    id: "2",
    title: "Recipes",
    fun: "R",
  }, {
    id: "3",
    title: "Community",
    fun: "C",
  }];

  useEffect(() => {
    setLoading(true)
    if(user_data){
      setLoading(false)
    }
  }, [user_data]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <Box style={{ marginBottom: "20px" }}>
            <Typography className="common_medium_font mb-3">Profile</Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box style={{ display: "flex", flexDirection: "row", boxSizing: "border-box", }} className="profile_image_ansh">
                  <Image className="imgSection--profile" src={user_data?.profile_pic !== "" ? `${ImgUrl}${user_data?.profile_pic}` : dummyPic} fallback={dummyPic} alt="profile_img" style={{ borderRadius: "50%" }} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} style={{ display: "grid", alignItems: "center" }}>
                <Box>
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "10px", }}>
                    <Typography> <strong style={{ fontWeight: "600" }}>Name</strong> </Typography>
                    <Typography> <strong style={{ fontWeight: "600" }}> {user_data?.full_name} </strong> </Typography>
                  </Box>
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "10px", }} >
                    <Typography> <strong style={{ fontWeight: "600" }}>Username</strong> </Typography>
                    <Typography> <strong style={{ fontWeight: "600" }}> {user_data?.username} </strong> </Typography>
                  </Box>
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "10px", }} >
                    <Typography>  <strong style={{ fontWeight: "600" }}>Email</strong> </Typography>
                    <Typography> <strong style={{ fontWeight: "600" }}> {user_data?.email} </strong> </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Box>
              <Typography className="common_medium_font mb-3">About Me</Typography>
              {user_data?.bio?.length > 0 ?
                <div style={{ maxHeight: '8rem', overflowY: 'auto' }} dangerouslySetInnerHTML={{ __html: htmlString }} />
                : ""}
            </Box>

            <div className="d-flex mb-3">
              {NavigationButton.map((data, key) => {
                return (
                  <div className="col-4 col-lg-3">
                    <Button className="common_btn w-100" onClick={() => { setActiveIndex(data.id); setActive(data.fun); }} style={{ backgroundColor: activeIndex === data.id ? "#F8931F" : "#fff", }} key={key} >
                      <Typography className="profile_btn_card" style={{ color: activeIndex === data.id ? "#fff" : "#F8931F", }} > {data.title} </Typography>
                    </Button>
                  </div>
                );
              })}
            </div>
            {active === "U" && <ProfileGallery />}
            {active === "R" && <ProfileRecipe />}
            {active === "C" && <ProfileCommunity />}
          </Box>
          <div className="row">
            <div className="col-6 col-sm-4 col-lg-3 mx-auto">
              <Link to="/EditProfile">
                <Button className="common_btn w-100" >Edit Profile</Button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Profile;