import React, { useEffect, useState } from "react";
import "./Feedback.css";
import { Box, Typography } from "@mui/material";
import { Input, Spin, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import toast from "../../../constants/toast";
import { sendFeedbackForm } from "../../../redux/action/dashboard";
import { setCallResponse } from "../../../redux/action/auth";

const { TextArea } = Input;
const Feedback = () => {
  const dispatch = useDispatch();
  const [feedback, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const call_response = useSelector((state) => state.dashboard?.call_response);
  
  const sendFeedback = async () => {
    if (feedback == "") {
      toast.error("Please Add Feedback First");
      return false;
    }
    setLoading(true)
    const successCb = (resp) => {
      toast.success(resp?.message);
      setLoading(false)
      return;
    };
    dispatch(sendFeedbackForm({ feedback }, successCb));
  };

  useEffect(() => {
    if (call_response) {
      if (call_response.status) {
        setTimeout(() => {
          setMsg("");
        }, [500]);
        dispatch(setCallResponse(null));
        setLoading(false)
      } else {
        toast.error(call_response?.message);
        setLoading(false)
      }
      dispatch(setCallResponse(null));
    }
  }, [call_response]);

  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle
        adsbygoogle.push({})
      } catch (e) {
        console.error('error', e)
      }
    }

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd()
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval)
      }
    }, 300)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Box>
      <Typography className="common_medium_font mb-3">Send feedback</Typography>
      <Box style={{ width: "100%" }} className="" >
        <Box className="feedback_width_style">
          <TextArea placeholder="Your Message here" autoSize={{ minRows: 10, maxRows: 12 }} className="feedback_input_box"
            value={feedback} onChange={(e) => setMsg(e.target.value)} sm={{ width: "100%" }} />
          <div className="text-center my-4 w-50 mx-auto">
            <Button loading={loading} className="common_btn px-4 px-md-0" onClick={() => sendFeedback()}> Submit</Button>
          </div>
        </Box>
        {/* <GoogleAdSense
          html={
            '<ins class="adsbygoogle"\n' +
            'style="display:block"\n' +
            'data-ad-client="ca-pub-5665970448524979"\n' +
            'data-ad-slot="6602811062"\n' +
            'data-full-width-responsive="true"></ins>'
          }
        /> */}
      </Box>
    </Box>
  );
};
export default Feedback;