// AUTH_ACTION
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const USER_TOKEN = "USER_TOKEN";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const VERIFICATION_OTP = "VERIFICATION_OTP";
export const VERIFY_REGISTRATION_OTP = "VERIFY_REGISTRATION_OTP";

// Dashboard
export const SEND_FEEDBACK = "SEND_FEEDBACK";
export const UPDATE_GALLERY = "UPDATE_GALLERY";
export const GET_USER_DETAIL = "GET_USER_DETAIL";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_GALLERY = "GET_USER_GALLERY";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const ADD_PROFILE_GALLERY = "ADD_PROFILE_GALLERY";
export const DELETE_PROFILE_IMAGE = "DELETE_PROFILE_IMAGE";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";

// Common
export const CALL_RESPONSE = "CALL_RESPONSE";
export const START_TIMER = "START_TIMER";

// COMMUNITY
export const GET_COMMUNITY_POST = "GET_COMMUNITY_POST";
export const GET_COMMUNITY_GRID = "GET_COMMUNITY_GRID";
export const GET_MY_COMMUNITY_POST = "GET_MY_COMMUNITY_POST";
export const ADD_COMMUNITY_POST = "ADD_COMMUNITY_POST";
export const EDIT_COMMUNITY_POST = "EDIT_COMMUNITY_POST";
export const EDIT_COMMUNITY_POST_DETAIL = "EDIT_COMMUNITY_POST_DETAIL";
export const ADD_COMMENT_COMMUNITY = "ADD_COMMENT_COMMUNITY";
export const GET_POST_COMMENT = "GET_POST_COMMENT";
export const LIKE_POST_OF_COMMUNITY = "LIKE_POST_OF_COMMUNITY";
export const GET_COMMUNITY_POST_LIKE = "GET_COMMUNITY_POST_LIKE";
export const GET_COMMUNITY_POST_DETAIL = "GET_COMMUNITY_POST_DETAIL";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_FRIEND = "DELETE_FRIEND";
export const DELETE_COMMUNITY_POST = "DELETE_COMMUNITY_POST";
export const SEARCH_COMMUNITY = "SEARCH_COMMUNITY";
export const GET_MY_FOLLOW_REQUEST = "GET_MY_FOLLOW_REQUEST";
export const ACCEPT_REJECT_REQUEST = "ACCEPT_REJECT_REQUEST";
export const ACCEPT_REJECT_REQUEST_MULTIPLE = "ACCEPT_REJECT_REQUEST_MULTIPLE";
export const GET_FRIEND_LIST = "GET_FRIEND_LIST";
export const SEARCH_BY_USERNAME = "SEARCH_BY_USERNAME";
export const SEARCH_BY_USERNAME_COPY = "SEARCH_BY_USERNAME_COPY";
export const GET_MY_LIKE_NOTIFICATION = "GET_MY_LIKE_NOTIFICATION";
export const GET_ALL_NOTIFICATION = "GET_ALL_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const ACCEPT_REJECT_FOLLOW_REQUEST = "ACCEPT_REJECT_FOLLOW_REQUEST";
export const ACCEPT_REJECT_FOLLOW_REQUEST_MULTIPLE = "ACCEPT_REJECT_FOLLOW_REQUEST_MULTIPLE";
export const SEND_FOLLOW_REQUEST = "SEND_FOLLOW_REQUEST";
export const GET_MY_CONNECTION = "GET_MY_CONNECTION";
export const GET_COMMUNITY_COMMENTS = "GET_COMMUNITY_COMMENTS";
export const ADD_COMMUNITY_COMMENTS = "ADD_COMMUNITY_COMMENTS";
export const LIKE_COMMUNITY_POST = "LIKE_COMMUNITY_POST";
export const MARK_AS_READ = "MARK_AS_READ";

// Recipes
export const GET_MENUS = "GET_MENUS";
export const GET_RECIPE_POST = "GET_RECIPE_POST";
export const GET_RECIPE_POST_DETAILS = "GET_RECIPE_POST_DETAILS";
export const EDIT_RECIPE = "EDIT_RECIPE";
export const GET_MY_RECIPE = "GET_MY_RECIPE";
export const DELETE_RECIPE_POST = "DELETE_RECIPE_POST";
export const ADD_RECIPE_COMMENT = "ADD_RECIPE_COMMENT";
export const ADD_RECIPE = "ADD_RECIPE";
export const GET_RECIPE_COMMENT = "GET_RECIPE_COMMENT";
export const LIKE_RECIPE = "LIKE_RECIPE";
export const GET_OTHER_USER_DETAIL = "GET_OTHER_USER_DETAIL";
export const RECIPE_POST_DETAIL = "RECIPE_POST_DETAIL";
export const SEARCH_RECIPE = "SEARCH_RECIPE";

// MEET
export const GET_HOME_CARDS = "GET_HOME_CARDS";
export const LEFT_SWIPE = "LEFT_SWIPE";
export const RIGHT_SWIPE = "RIGHT_SWIPE";
export const GET_REQUEST_LIST = "GET_REQUEST_LIST";
export const UPDATE_LAT_LONG = "UPDATE_LAT_LONG";
export const DELETE_MY_CONNECTION = "DELETE_MY_CONNECTION";

export const SELECTEDCHAT = "SELECTEDCHAT";
export const CONTACT_US = "CONTACT_US";
export const BADGESDATA_SIDEBAR = "BADGESDATA_SIDEBAR";

export const TERMS_ABOUT = "TERMS_ABOUT";
export const HELP = "HELP";
export const GOOGLE_PLACE_API = "GOOGLE_PLACE_API";
export const RESTRO_DETAILS = "RESTRO_DETAILS";
export const STORE_GENDER_DATA = "STORE_GENDER_DATA";
