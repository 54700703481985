import { Avatar, Box, Card, CardActions, CardContent, CardHeader, CardMedia, DialogTitle, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import AddIcon from "@mui/icons-material/Add";
import CommunityCard from "./CommunityCard";
import "./communityStyle.css";
import SearchIcon from "@mui/icons-material/Search";
import { InputBase } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Explore from "./Explore";
import { FaUserPlus, FaUserCheck } from 'react-icons/fa';
import leafImg from "../../../images/CW/Icon awesome-leaf@2x.png";
import greenLeafImg from "../../../images/CW/Icon awesome-green-leaf@2x.png";
import commentImg from "../../../images/CW/Icon awesome-comment@2x.png";
import shareImg from "../../../images/CW/Icon ionic-ios-share-alt@2x.png";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Addperson from "../../../images/CW/Icon ionic-ios-person-add@2x.png";
import { useDispatch, useSelector } from "react-redux";
import {
  addCommunityComments,
  getCommunityComments,
  getCommunityPostGrid,
  searchByUsername,
  sendFollowRequest,
  likeCommunityPost,
  communityPostDetail,
  deleteCommunityComment,
  getCommunityPostLike,
} from "../../../redux/action/community";
import dummyRestro from "../../../images/CW/no_images.png";
import dummyProfile from "../../../images/CW/profile.jpeg";
import { getDiff, ImgUrl } from "../../../constants/const";
import SideLogo from "../../../images/Group 33033.png";
import Spinner from "../../../constants/Spinner";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
// Share
import { FacebookShareButton } from "react-share";
import toast from "../../../constants/toast";
import Modal from "react-bootstrap/Modal";
import ReactHlsPlayer from 'react-hls-player';

const DCommunity = () => {
  const dispatch = useDispatch();
  const [toggled, setToggled] = useState(true);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState("LV");
  const [activeIndex, setActiveIndex] = useState("1");
  const [model, setModel] = useState(false);
  const [search, setSearch] = useState("");
  const [popLike, setPopLike] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [commId, setCommId] = useState("");
  const [show, setShow] = useState(false);
  // const [followStatus, setFollowStatus] = useState('');

  const community_explore = useSelector((state) => state.community?.community_explore?.data);
  const search_api_data = useSelector((state) => state.community?.search_by_username?.data);
  const getCommunityPostComment = useSelector((state) => state?.community?.get_community_comment?.data);
  const getCommunityPostDetail = useSelector((state) => state?.community?.community_post_detail?.data);
  const getCommunityPostLikesss = useSelector((state) => state?.community?.get_community_post_likes);
  const userData = useSelector((state) => state.dashboard?.user_data);

  // Like Modal Start
  const handleShowRB = (id) => {
    setShow(true);
    const successCb = (res) => {
      return;
    };
    let data = {
      community_post_id: id,
      comment: message,
      page: 1,
    };
    dispatch(getCommunityPostLike(data, successCb));
  };
  const handleCloseRB = () => setShow(false);

  const toggleImage = (dataId) => {
    setToggled(!toggled)
    const successCb = () => {
      dispatch(getCommunityPostGrid({ page: '1' }));
      const successCb = () => { };
      let data = { community_post_id: popLike?._id, };
      dispatch(communityPostDetail(data, successCb));
      return;
    };
    let data = {
      community_post_id: dataId?._id,
      page: 1,
    };
    dispatch(likeCommunityPost(data, successCb));
  };
  useEffect(() => {
    toggleImage()
    // dispatch(getCommunityPostGrid({ page: '1' }));
  }, [userData])

  const handleClickOpen = (id) => {
    setOpen(true);
    setCommId(id);
    let data = {
      community_post_id: id,
      page: 1,
    };
    dispatch(getCommunityComments(data));
  };

  const handleCloseSecond = () => {
    setOpen(false);
  };
  // To Add Comments
  const addComment = () => {
    const successCb = (res) => {
      let data = {
        community_post_id: commId,
        page: 1,
      };
      dispatch(getCommunityComments(data));
      setMessage("");
      return;
    };
    let data = {
      community_post_id: commId,
      comment: message,
      page: 1,
    };
    dispatch(addCommunityComments(data, successCb));
  };

  useEffect(() => {
    const successCb = (res) => { }
    let data = {
      community_post_id: commId,
      page: 1,
    };
    dispatch(communityPostDetail(data, successCb));
  }, [getCommunityPostComment])

  useEffect(() => {
    let token = localStorage.getItem("token");
    let data = { keyword: search };
    dispatch(searchByUsername(data, token));
    dispatch(getCommunityPostGrid({ page: '1' }));
  }, []);

  // Search Data Preview
  const searchData = (e) => {
    setSearch(e.target.value);
    let data = {
      keyword: e.target.value,
    };
    dispatch(searchByUsername(data));
  };

  const handleClose = () => {
    setModel(false);
  };

  // communityPostDetail
  const getData = (val) => {
    setPopLike(val)
    const successCb = () => { return; };
    let data = { community_post_id: val?._id, };
    dispatch(communityPostDetail(data, successCb));
    return setModel(true);
  };

  const deleteComment = (dataId) => {
    const successCb = (res) => {
      let data = {
        community_post_id: commId,
        page: 1,
      };
      dispatch(getCommunityComments(data));
      return;
    };
    let data = {
      community_post_id: dataId?._id,
      comment_id: dataId?._id,
    };
    dispatch(deleteCommunityComment(data, successCb));
  };

  // Follow Request
  const followRequest = (id) => {
    const successCb = (resp) => {
      // setFollowStatus(resp?.data?.status)
      toast.success(resp?.message)
    };
    let data = {
      follow_to: id,
    };
    dispatch(sendFollowRequest(data, successCb));
  };

  // Loading
  useEffect(() => {
    if (community_explore && loading) {
      setLoading(false);
    }
  }, [community_explore]);

  // Navigation Button
  const communityTabButton = [
    {
      id: "1",
      text: "List View",
      fun: "LV",
    },
    {
      id: "2",
      text: "Explore",
      fun: "E",
    },
  ];

  return (
    <div>
      <Box className="community_header_style">
        {/* Community Title */}
        <Box className="comm_btn_center_style">
          <Typography className="common_medium_font"> Community </Typography>
          <Typography style={{ display: "flex", flexDirection: "row", justifyContent: "right", alignItems: "center", }} >
            <Link style={{ color: "orange" }} to="/add_post">
              <AddIcon fontSize="large" style={{ borderRadius: "50%", background: "#fff", boxShadow: "3px 3px 25px -7px rgba(0,0,0,0.75)", cursor: "pointer", }} />
            </Link>
          </Typography>
        </Box>

        {/* Search bar */}
        {/* <Box className="search_bar_comm mt-3">
          <Box style={{ borderRadius: "16px", background: "#ececec", padding: "0 10px" }} >
            <IconButton onClick={searchData} aria-label="search" >
              <SearchIcon />
            </IconButton>
            <InputBase style={{ height: "50px" }} placeholder="Search" inputProps={{ "aria-label": "Search" }} value={search} onChange={searchData} />
          </Box>
          <Box style={{ maxHeight: "90vh", overflowY: "scroll", position: "absolute", zIndex: "1", background: "#fff", }} >
            {search_api_data?.map((data, id) => {
              return (
                <>
                  <Box key={id}>
                    <Link to={`/c_profile/${data?._id}`} style={{ color: "orange" }} >
                      <List sx={{ width: "100%", maxWidth: "97%", bgcolor: "background.paper", }} >
                        <ListItem className="liked_margin_padding_mui">
                          <Box className="liked_text_style">
                            <Box>
                              <ListItemAvatar>
                                <Avatar src={data?.profile_pic !== "" ? `${ImgUrl}${data?.profile_pic}` : `${dummyProfile}`} className="liked_avatar_style" />
                              </ListItemAvatar>
                            </Box>
                            <ListItemText primary={data?.full_name} />
                          </Box>
                        </ListItem>
                      </List>
                    </Link>
                  </Box>
                </>
              );
            })}
          </Box>
        </Box> */}

        {/* Search End here... */}

        {/* Buttons for xs and sm */}
        <Box className="d-block d-md-none col-12  py-2 mt-3 d-flex">
          {communityTabButton.map((data, key) => {
            return (
              <Button
                onClick={() => {
                  setActiveIndex(data.id);
                  setActive(data.fun);
                }}
                style={{
                  backgroundColor: activeIndex === data.id ? "#F8931F" : "#fff",

                }}
                key={key}
                className="common_btn w-100">
                <Typography
                  style={{
                    color: activeIndex === data.id ? "#fff" : "#000",

                  }}>
                  {data.text}
                </Typography>
              </Button>
            );
          })}
        </Box>

        {/* Community Post & Community Explore for full screen */}
        <div className="row">
          <div className="d-none d-md-block col-12 col-md-7 mt-2">
            <CommunityCard />
          </div>
          <div className="d-none d-md-block col-12 col-md-5 mt-3 ">
            <Card className="communityExploreStyle">
              <Typography className="common_medium_font">EXPLORE</Typography>
              {loading ? (<Spinner />) : (
                <Grid
                  container
                  spacing={2}
                  style={{ margin: "15px 0", boxSizing: "border-box", maxWidth: "-webkit-fill-available", maxHeight: "65vh", overflowY: "scroll" }}>
                  {community_explore?.length > 0 ? (
                    community_explore?.map((data, id) => {
                      return (
                        <Grid item xs={12} sm={12} md={12} lg={6} key={id} style={{ position: "relative", boxSizing: "border-box", padding: "0 10px 10px 0" }}>
                          <Card sx={{ width: "1", borderRadius: "12px", cursor: "pointer" }} onClick={() => getData(data)}> {data?.media_type === "image" ? (
                            <CardMedia component="img" image={data?.media !== "" ? `${ImgUrl}${data?.media}` : dummyRestro} alt="dish_image" style={{ borderRadius: "10px 10px 0 0px", width: "100%", maxHeight: "200px", objectFit: "cover", height: "200px" }} />
                          ) : (
                            data?.media.includes('mp4') ?
                              <video style={{ width: "100%", maxHeight: "200px", objectFit: "cover" }} controls>
                                <source src={data?.media !== "" ? `${ImgUrl}${data?.media}` : ''} type="video/mp4" />
                              </video>
                              :
                              <ReactHlsPlayer
                                src={`${ImgUrl}${data?.media}`}
                                autoPlay={false}
                                width="100%"
                                height="200px"
                                controls
                                typeof="video/mp4"
                                style={{ objectFit: "cover" }}
                              />
                          )}
                          </Card>
                        </Grid>
                      );
                    })
                  ) : (
                    <Box style={{ display: "flex", color: "orange", margin: "20px 0 0 30px" }}> No Data Avaliable </Box>
                  )}
                </Grid>
              )}
            </Card>
          </div>
        </div>
      </Box>
      <div className="d-block d-md-none" style={{ minHeight: '45vh' }}>
        {active === "LV" && <CommunityCard />}
        {active === "E" &&
          <>
            <Card className="px-2 mt-3">
              {loading ? (<Spinner />) : (
                <div
                  className="row g-3"
                  style={{ margin: "15px 0", boxSizing: "border-box", maxWidth: "-webkit-fill-available", maxHeight: "65vh", overflowY: "scroll" }}>
                  {community_explore?.length > 0 ? (
                    community_explore?.map((data, id) => {
                      return (
                        <div className="col-sm-6" key={id}>
                          <Card sx={{ width: "1", borderRadius: "12px", cursor: "pointer" }} onClick={() => getData(data)}> {data?.media_type === "image" ? (
                            <CardMedia component="img" image={data?.media !== "" ? `${ImgUrl}${data?.media}` : dummyRestro} alt="dish_image" style={{ borderRadius: "10px 10px 0 0px", maxHeight: 200 }} />
                          ) : (
                            data?.media.includes('mp4') ?
                              <video style={{ width: "100%" }} controls>
                                <source src={data?.media !== "" ? `${ImgUrl}${data?.media}` : ''} type="video/mp4" />
                              </video>
                              :
                              <ReactHlsPlayer
                                src={`${ImgUrl}${data?.media}`}
                                autoPlay={false}
                                width="100%"
                                height="200px"
                                controls
                                typeof="video/mp4"
                                style={{ objectFit: "cover" }}
                              />
                          )}
                          </Card>
                        </div>
                      );
                    })
                  ) : (
                    <Box style={{ display: "flex", color: "orange", margin: "20px 0 0 30px" }}> No Data Avaliable </Box>
                  )}
                </div>
              )}
            </Card>
          </>
        }
      </div>
      {/* Modals for complete description */}
      {/* <Dialog open={model} aria-labelledby="responsive-dialog-title" style={{ zIndex: "0" }} >
        <DialogContent>
          <Box>
            <KeyboardBackspaceIcon onClick={handleClose} fontSize="large" style={{ flex: "start", cursor: "pointer", marginLeft: "-15px" }} />
            <Box style={{ display: "flex", justifyContent: 'center', marginTop: '-25px' }}> <Typography>{getCommunityPostDetail?.title}</Typography> </Box>
          </Box>
          <DialogContentText style={{ color: "#000", fontFamily: "Montserrat", fontWeight: "normal" }}>
            <Box>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Link to={`/c_profile/${getCommunityPostDetail?.user_id?._id}`}>
                  <CardHeader
                    avatar={<Avatar style={{ border: "2px solid #F89601" }} alt="per_img" src={getCommunityPostDetail?.user_id?.profile_pic !== "" ? `${ImgUrl}${getCommunityPostDetail?.user_id?.profile_pic}` : `${dummyProfile}`} sx={{ width: 56, height: 56 }} />}
                    title={getCommunityPostDetail?.user_id?.full_name}
                    subheader={getDiff(getCommunityPostDetail?.created_at)}
                    style={{ border: "none" }}
                  />
                </Link>
                {getCommunityPostDetail?.user_id?._id !== userData?._id ?
                  <IconButton aria-label="settings" style={{ background: "transparent" }}
                    onClick={() => followRequest(getCommunityPostDetail?.user_id?._id)} >
                    <FaUserPlus size={30} />
                  </IconButton>
                  : <IconButton aria-label="settings" style={{ background: "transparent" }} > <img src={SideLogo} alt="" height={35} /> </IconButton>}
              </Box>
              {getCommunityPostDetail?.media_type === "image" ? (
                <CardMedia style={{ maxHeight: 400 }} component="img" image={getCommunityPostDetail?.media !== "" ? `${ImgUrl}${getCommunityPostDetail?.media}` : `${dummyRestro}`} alt="dish_image" />
              ) : (
                getCommunityPostDetail?.media.includes('mp4') ?
                  <video style={{ width: "100%", maxHeight: "300px", height: "auto" }} controls> <source src={getCommunityPostDetail?.media !== "" ? `${ImgUrl}${getCommunityPostDetail?.media}` : ''} type="video/mp4" /> </video>
                  :
                  <ReactHlsPlayer
                    src={`${ImgUrl}${getCommunityPostDetail?.media}`}
                    autoPlay={false}
                    width="100%"
                    controls
                  />
              )}
              <CardContent>
                <Typography variant="body2" color="#000000"> <strong>{getCommunityPostDetail?.title}</strong> </Typography>
                <Typography variant="body2" color="#000000"> {getCommunityPostDetail?.description} </Typography>
              </CardContent>
              <CardActions disableSpacing style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }} >
            
                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer" }} >
                  <IconButton aria-label="add to favorites" onClick={() => toggleImage(getCommunityPostDetail)} >
                    <img src={getCommunityPostDetail?.is_liked === 0 ? leafImg : greenLeafImg} alt="like_img" height={20} />
                  </IconButton>&nbsp;
                  <Box onClick={() => handleShowRB(getCommunityPostDetail?._id)}>
                    <Typography style={{ color: `${getCommunityPostDetail?.is_liked === 0 ? "black" : "green"}` }} >
                      {`${getCommunityPostDetail?.total_likes ?? 0} ${getCommunityPostDetail?.total_likes > 1 ? "Likes" : "Like"}`}
                    </Typography>
                  </Box>
                </Box>
                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer", }} onClick={() => handleClickOpen(getCommunityPostDetail?._id)} >
                  <IconButton aria-label="comment"> <img src={commentImg} alt="like_img" height={20} /></IconButton>&nbsp;
                  <Typography> {getCommunityPostDetail?.total_comments ?? 0} Comment </Typography>
                </Box>
                <FacebookShareButton
                  url={`${ImgUrl}${getCommunityPostDetail?.media}`}
                  quote={`"${getCommunityPostDetail?.title}"`}
                  hashtag={`#${getCommunityPostDetail?.title}`}
                  description={getCommunityPostDetail?.description}
                  className="Demo__some-network__share-button">
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer", }} >
                    <IconButton aria-label="share">  <img src={shareImg} alt="like_img" height={20} /> </IconButton>&nbsp;<Typography>Share</Typography>
                  </Box>
                </FacebookShareButton>
              </CardActions>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog> */}

      <Dialog open={model} aria-labelledby="responsive-dialog-title" style={{ zIndex: "0" }} >
        <DialogContent>
          <KeyboardBackspaceIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          <Typography className="text-center common_medium_font">{getCommunityPostDetail?.title}</Typography>
          <DialogContentText >
            <Box>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Link to={`/c_profile/${getCommunityPostDetail?.user_id?._id}`}>
                  <CardHeader
                    avatar={<Avatar style={{ border: "2px solid #F89601" }} alt="per_img" src={getCommunityPostDetail?.user_id?.profile_pic !== "" ? `${ImgUrl}${getCommunityPostDetail?.user_id?.profile_pic}` : `${dummyProfile}`} sx={{ width: 56, height: 56 }} />}
                    title={getCommunityPostDetail?.user_id?.full_name}
                    subheader={getDiff(getCommunityPostDetail?.created_at)}
                    style={{ border: "none", padding: "1rem 0" }}
                  />
                </Link>
                {getCommunityPostDetail?.user_id?._id !== userData?._id ?
                  <IconButton aria-label="settings" style={{ background: "transparent" }} onClick={() => followRequest(getCommunityPostDetail?.user_id?._id)} >
                    <img src={Addperson} alt="add" height={25} />
                  </IconButton>
                  :
                  <IconButton aria-label="settings" style={{ background: "transparent" }} > <img src={SideLogo} alt="" height={35} /> </IconButton>}
              </Box>
              {getCommunityPostDetail?.media_type === "image" ? (
                <CardMedia className="community_card" image={getCommunityPostDetail?.media !== "" ? `${ImgUrl}${getCommunityPostDetail?.media}` : `${dummyRestro}`} alt="dish_image" style={{ objectFit: "fill" }} />
              ) : (
                getCommunityPostDetail?.media.includes('mp4') ?
                  <video style={{ width: "100%", maxHeight: "300px", height: "auto" }} controls> <source src={getCommunityPostDetail?.media !== "" ? `${ImgUrl}${getCommunityPostDetail?.media}` : ''} type="video/mp4" /> </video>
                  :
                  <ReactHlsPlayer
                    src={`${ImgUrl}${getCommunityPostDetail?.media}`}
                    autoPlay={false}
                    className="community_card"
                    controls
                  />
              )}
              <div className="py-2">
                <Typography variant="body2" color="#000000"> <strong>{getCommunityPostDetail?.title}</strong> </Typography>
                <Typography variant="body2" color="#000000"> {getCommunityPostDetail?.description} </Typography>
              </div>
              <div className="d-flex justify-content-between">
                {/* Share, Comment, Like */}
                <div>
                  <IconButton aria-label="add to favorites" onClick={() => toggleImage(getCommunityPostDetail)} >
                    <img src={getCommunityPostDetail?.is_liked === 0 ? leafImg : greenLeafImg} alt="like_img" height={15} />
                    <div onClick={() => handleShowRB(getCommunityPostDetail?._id)}>
                      <Typography className="small_font" style={{ color: `${getCommunityPostDetail?.is_liked === 0 ? "black" : "green"}` }} >
                        {getCommunityPostDetail?.total_likes}Like
                      </Typography>
                    </div>
                  </IconButton>&nbsp;
                </div>
                <div onClick={() => handleClickOpen(getCommunityPostDetail?._id)} >
                  <IconButton aria-label="comment">
                    <img src={commentImg} alt="like_img" height={15} className="me-1" />
                    <Typography className="text-dark small_font"> {getCommunityPostDetail?.total_comments}Comment </Typography>
                  </IconButton>
                </div>
                <div>
                  <FacebookShareButton
                    url={`${ImgUrl}${getCommunityPostDetail?.media}`}
                    quote={`"${getCommunityPostDetail?.title}"`}
                    hashtag={`#${getCommunityPostDetail?.title}`}
                    description={getCommunityPostDetail?.description}
                    className="Demo__some-network__share-button"
                  >
                    <div>
                      <IconButton aria-label="share">
                        <img src={shareImg} alt="like_img" height={15} />
                        <Typography className="text-dark small_font">Share</Typography>
                      </IconButton>
                    </div>
                  </FacebookShareButton>
                </div>
              </div>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Comment Box */}
      <Dialog open={open} keepMounted onClose={handleCloseSecond} aria-describedby="alert-dialog-slide-description" style={{ zIndex: "999" }} >
        <DialogTitle style={{ fontWeight: "bold" }}>
          <KeyboardBackspaceIcon onClick={handleCloseSecond} fontSize="large" cursor="pointer" />
          <span style={{ fontWeight: "bold", marginLeft: "30px" }}> Comments </span>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ height: "30vh", padding: "10px 0", overflowY: "scroll" }} >
            {getCommunityPostComment?.map((data, id) => {
              return (
                <Box className="liked_main_style" key={id}>
                  <List sx={{ width: "100%", bgcolor: "background.paper", }} >
                    <ListItem secondaryAction={<IconButton edge="end" aria-label="delete" style={{ color: "orange" }} onClick={() => deleteComment(data)} > <DeleteIcon /> </IconButton>} >
                      <ListItemAvatar> <Avatar sx={{ width: 56, height: 56 }} style={{ marginRight: 10 }} src={data?.user_id?.profile_pic != '' ? `${ImgUrl}${data?.user_id?.profile_pic}` : dummyProfile} /> </ListItemAvatar>
                      <ListItemText style={{ wordBreak: 'break-all' }} primary={data?.user_id?.full_name} secondary={<>
                        <p style={{ padding: '0', margin: '0' }}> "{data?.comment}" </p>
                        <p style={{ padding: '0', margin: '0' }}>{getDiff(data?.created_at)}</p>
                      </>} />
                    </ListItem>
                  </List>
                </Box>
              )
            })}
          </Typography>
          <Box style={{ display: "flex", border: "1px solid orange", margin: "5px 0 0 0", justifyContent: "space-between", padding: '5px 0' }} >
            <input autoFocus maxLength={50} placeholder="Your Message..." size="large" value={message} onChange={(e) => setMessage(e.target.value)} style={{ outline: "none", border: "none", width: "100%", marginLeft: 10 }} />
            <Box onClick={() => addComment()} style={{ color: "orange", padding: '2px 5px', cursor: 'pointer' }}> <SendIcon /> </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Like Box */}
      <Modal show={show} onHide={handleCloseRB} backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title style={{ display: "flex", justifyContent: "center" }} > Likes </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {getCommunityPostLikesss?.data?.map((data, id) => (
            <Box className="liked_main_style" key={id}>
              <List sx={{ width: "100%", bgcolor: "background.paper", }} >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={data?.user_id?.profile_pic != '' ? `${ImgUrl}${data?.user_id?.profile_pic}` : dummyProfile} />
                  </ListItemAvatar>
                  <ListItemText
                    style={{ color: "orange", fontWeight: "bolder" }}
                    primary={data?.user_id?.full_name}
                    secondary={getDiff(data?.updated_at)}
                  />
                </ListItem>
              </List>
            </Box>
          ))}
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default DCommunity;