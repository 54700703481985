import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import "../DRacipes/Card.css";
import { Box } from "@mui/system";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton } from "@mui/material";
import { CameraFilled } from "@ant-design/icons";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "react-bootstrap/Modal";
import { ImgUrl } from "../../../constants/const";
import { useDispatch, useSelector } from "react-redux";
import dummyRestro from "../../../images/CW/dummyRestro.png";
import { addProfileGallery, deleteProfileImage, getUserGallery, updateGallery } from "../../../redux/action/dashboard";
import toast from '../../../constants/toast'
import { Spin } from "antd";
import ReactHlsPlayer from 'react-hls-player';
import { GET_USER_GALLERY } from "../../../redux/constants";

const styles = {
  position: "absolute",
  top: "8px",
  right: "8px",
  background: "#fff",
  height: "25px",
  width: "25px",
  padding: "5px",
  borderRadius: "50%",
  color: "#F89601",
  cursor: "pointer",
  boxShadow: "0px 3px 6px #00000029",
};
const styles2 = {
  position: "absolute",
  top: "40px",
  right: "8px",
  background: "#fff",
  height: "25px",
  width: "25px",
  padding: "5px",
  borderRadius: "50%",
  color: "#F89601",
  cursor: "pointer",
  boxShadow: "0px 3px 6px #00000029",
};

// gallery_media
export default function EditProfileGallery() {
  const dispatch = useDispatch();
  const [gallery_media, setMedia] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const [idData, setIdData] = useState(null);
  const [loading, setLoading] = useState(false)
  // For Edit
  const [show, setShow] = useState(false);
  const [gallery_media1, setGallery_media1] = useState(null);
  const [imageUrl1, setImageUrl1] = useState(null);
  const [idData1, setIdData1] = useState(null);
  const [media1, setMedia1] = useState(null);
  const usergallerydata = useSelector((state) => state.dashboard?.getuser_gallery?.data);
  // Delete Confirmation Start
  const handleClickOpen = (id) => {
    setOpen(true);
    setIdData(id);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // Delete Confirmation End

  // Edit Start
  const handleCloseEdit = () => {
    setShow(false)
    setGallery_media1(null)
    setImageUrl1(null)
    setMedia1(null)
    setIdData1(null)
  };

  const handleShowEdit = (data) => {
    setShow(true);
    setIdData1(data?._id);
    setMedia1(data);
  };

  const EditGallery = async () => {
    setLoading(true)
    const successCb = (resp) => {
      setShow(false);
      setLoading(false)
      dispatch(getUserGallery())
      setMedia1(null)
      setIdData1(null)
      setGallery_media1(null)
    }
    let data = {
      _id: idData1,
      gallery_media: gallery_media1,
    };
    dispatch(updateGallery(data, successCb));
  };

  useEffect(() => {
    if (gallery_media1) {
      setImageUrl1(URL.createObjectURL(gallery_media1));
    }
  }, [gallery_media1]);
  // Edit End

  const DeleteProfileGallery = async () => {
    setLoading(true)
    const successCb = (resp) => {
      dispatch(getUserGallery())
      toast.success(resp?.message);
      setOpen(false);
      setLoading(false)
      dispatch({
        type: GET_USER_GALLERY,
      });
    }
    let data = { _id: idData };
    dispatch(deleteProfileImage(data, successCb));
  };

  const handleUploadMedia = (e) => {
    const file = e.target.files[0]
    if (file?.type == "image/jpeg" && file?.size > 10 * 1024 * 1024) {
      toast.error("Image should be less than 10MB!");
      return
    }
    setMedia(file)
    setGallery_media1(file)
  }

  const handleUploadFile = (e) => {
    const file = e.target.files[0]
    if (file?.type == "image/jpeg" && file?.size > 10 * 1024 * 1024) {
      toast.error("Image should be less than 10MB!");
      return
    }
    setGallery_media1(file)
  }

  const uploadImage = () => {
    setLoading(true)
    let successCb = (resp) => {
      if (resp?.message == "Nothing to upload") {
        setLoading(false)
        toast.error(resp?.message)
      } else {
        dispatch(getUserGallery())
        toast.success(resp?.message)
        setMedia("");
        setLoading(false)
      }
    }
    let data = { gallery_media };
    dispatch(addProfileGallery(data, successCb));
  };

  useEffect(() => {
    dispatch(getUserGallery())
  }, [])

  useEffect(() => {
    if (gallery_media) {
      setImageUrl(URL.createObjectURL(gallery_media));
    }
  }, [gallery_media]);

  return (
    <Box style={{ margin: "0 20px" }}>
      <Grid container columnSpacing={1} rowSpacing={1} style={{ maxHeight: "40vh", overflowY: "scroll", padding: "10px 0" }}>
        <Card className="editProfileUploadImage"
          style={{ boxShadow: "9px 9px 31px -10px rgba(0,0,0,0.75)", borderRadius: "10px", display: "flex", flexDirection: "column", width: 'auto' }}>
          {gallery_media == null || gallery_media?.type == undefined ?
            <Box component="label" style={{
              border: "1px dotted black", width: "100%", display: "flex", justifyContent: "center", flexDirection: "column",
              height: "25vh", alignItems: "center", borderRadius: "15px", cursor: "pointer", padding: '0 20px'
            }}>
              <CameraFilled style={{ color: "#000", fontSize: "34px", cursor: "pointer" }} /> Upload Media
              <input hidden type="file" accept=".jpg, .jpeg, .png, video/mp4" name="file" onChange={handleUploadMedia} />
            </Box> : ''}
          {imageUrl && gallery_media && (<Box mt={2} textAlign="center">{gallery_media?.type != "video/mp4" ? <img src={imageUrl} alt="img" height="100px" width='150px' />
            : <video height="100px" width="150px" controls > <source src={imageUrl} type="video/mp4" /> </video>}</Box>)}
          <Spin spinning={loading}>
            <Button style={{ width: "100%", margin: "10px 0 -10px 0", fontWeight: "bold", color: "orange", border: '1px solid orange' }} onClick={() => uploadImage()}> Upload </Button>
          </Spin>
        </Card>
        {usergallerydata?.map((data, id) => {
          return (
            <Grid item xs={12} sm={4} md={4} lg={2} key={id}>
              <Card style={{ boxShadow: "9px 9px 31px -10px rgba(0,0,0,0.75)", borderRadius: "10px", position: "relative" }} >
                {data?.media_type == "image" ? (
                  <CardMedia component="img" image={data?.media !== "" ? `${ImgUrl}${data?.media}` : dummyRestro} alt="media"
                    style={{ objectFit: "cover", height: "200px" }} />
                ) : (
                  data?.media.includes('mp4') ?
                    <video height="200" controls>
                      <source src={data?.media !== "" ? `${ImgUrl}${data?.media}` : ''} type="video/mp4" />
                    </video>
                    :
                    <ReactHlsPlayer
                      src={`${ImgUrl}${data?.media}`}
                      autoPlay={false}
                      width="100%"
                      height="200px"
                      controls
                      style={{ objectFit: "cover" }}
                    />
                )}
                <EditIcon style={styles} onClick={() => handleShowEdit(data)} />
                <DeleteIcon style={styles2} onClick={() => handleClickOpen(data?._id)} />
              </Card>
            </Grid>
          );
        })}
      </Grid>

      {/* Delete Pop-Up */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title">
        <DialogContent>
          <DialogContentText
            style={{
              color: "#000",
              fontFamily: "Montserrat",
              fontWeight: "normal",
              marginTop: "15px 0",
            }}>
            Are you sure you want to delete this picture?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={handleClose} style={{ color: "#000" }}>
            <strong>No</strong>
          </Button>
          <Spin spinning={loading}>
            <Button onClick={() => DeleteProfileGallery()} style={{ color: "#F8931F" }}>
              <strong>Yes</strong>
            </Button>
          </Spin>
        </DialogActions>
      </Dialog>

      {/* Edit Pop-Up */}
      <Modal show={show} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Gallery Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Box>
            <Box>
              {gallery_media1 == null || gallery_media1?.type == undefined ?
                <Box component="label" className="user_background_img_setting"
                  style={{
                    border: "1px dotted black",
                    width: "100%", display: "flex",
                    justifyContent: "center", flexDirection: "column",
                    height: "400px", alignItems: "center", borderRadius: "10px", cursor: "pointer",
                  }}
                >
                  <h2 className="btn btn-dark btn-sm">upload</h2>
                  {media1?.media_type == "video" && media1?.media.includes('mp4') ?
                    <video height="300px" controls width="100%">
                      <source src={`${ImgUrl}${media1?.media}`} type="video/mp4" />
                    </video>
                    :
                    media1?.media_type == "image" ?
                      <img className="community_card" src={`${ImgUrl}${media1?.media}`} />
                      :
                      <ReactHlsPlayer
                        src={`${ImgUrl}${media1?.media}`}
                        autoPlay={false}
                        className="community_card"
                        controls
                      />
                  }
                  <input hidden type="file" name="file" accept=".jpg, .jpeg, .png, video/mp4" onChange={handleUploadFile} />
                </Box>
                : ''}
              {imageUrl1 && gallery_media1 && (
                <Box mt={2} textAlign="center"> {gallery_media1?.type != "video/mp4" ? <img src={imageUrl1} alt={gallery_media1.name} height="300px" width="100%" />
                  :
                  <video height="300px" controls width="100%">
                    <source src={`${imageUrl1}`} type="video/mp4" />
                  </video>
                }</Box>
              )}
            </Box>
            <div className="text-center mt-2">
              <Spin spinning={loading}>
                <Button className="AddRacipes-btn" onClick={() => EditGallery()}> Save </Button>
              </Spin>
            </div>
          </Box>
        </Modal.Body>
      </Modal>
    </Box>
  );
}