import {
  ADD_COMMUNITY_POST,
  ACCEPT_REJECT_REQUEST,
  GET_COMMUNITY_POST,
  GET_COMMUNITY_GRID,
  GET_COMMUNITY_POST_LIKE,
  LIKE_POST_OF_COMMUNITY,
  GET_FRIEND_LIST,
  GET_MY_LIKE_NOTIFICATION,
  GET_ALL_NOTIFICATION,
  SEARCH_COMMUNITY,
  GET_MY_FOLLOW_REQUEST,
  ACCEPT_REJECT_FOLLOW_REQUEST,
  DELETE_FRIEND,
  SEARCH_BY_USERNAME,
  DELETE_NOTIFICATION,
  SEND_FOLLOW_REQUEST,
  GET_MY_CONNECTION,
  GET_COMMUNITY_COMMENTS,
  ADD_COMMUNITY_COMMENTS,
  LIKE_COMMUNITY_POST,
  DELETE_COMMENT,
  GET_COMMUNITY_POST_DETAIL,
  DELETE_COMMUNITY_POST,
  EDIT_COMMUNITY_POST,
  MARK_AS_READ,
  ACCEPT_REJECT_FOLLOW_REQUEST_MULTIPLE,
  ACCEPT_REJECT_REQUEST_MULTIPLE
} from "../constants";
import { BASE_URL, authToken } from "../../constants/const";
import { ApiCall } from "../../api";

// Done
export const addCommunityPost = (data, successCb) => async (dispatch) => {
  const formData = new FormData();
  formData.append("title", data.title);
  formData.append("description", data.description);
  formData.append("community_media", data.community_media);
  let config = {
    method: "post",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "multipart/form-data",
    },
    url: `${BASE_URL}users/add_community_post`,
    data: formData,
  };
  let response = await ApiCall(config, dispatch);
  if (response?.data?.status) {
    successCb(response?.data);
  }
  dispatch({
    type: ADD_COMMUNITY_POST,
    payload: response?.data,
  });
};

// Done
export const editCommunityPost = (data, successCb) => async (dispatch) => {
  const formData = new FormData();
  formData.append("post_id", data.post_id);
  formData.append("title", data.title);
  formData.append("description", data.description);
  formData.append("community_media", data.community_media);
  let config = {
    method: "post",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "multipart/form-data",
    },
    url: `${BASE_URL}users/edit_community_post`,
    data: formData,
  };
  let response = await ApiCall(config, dispatch);
  if (response?.data?.status) {
    successCb(response?.data);
  }
  dispatch({
    type: EDIT_COMMUNITY_POST,
    payload: response?.data,
  });
};

// Done
export const getMyConnection = (successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_my_connections`,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response.data);
  }
  dispatch({
    type: GET_MY_CONNECTION,
    payload: response?.data,
  });
};

// Done
export const getCommunityPost = (token, data) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_community_posts`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  dispatch({
    type: GET_COMMUNITY_POST,
    payload: response?.data,
  });
};

// Done
export const getCommunityPostGrid = (data) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_community_post_grid`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  dispatch({
    type: GET_COMMUNITY_GRID,
    payload: response?.data,
  });
};

// Done
export const getMyFollowRequest = (token, data) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${token}` },
    url: `${BASE_URL}users/get_my_follow_request`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  dispatch({
    type: GET_MY_FOLLOW_REQUEST,
    payload: response?.data,
  });
};

// Done
export const likePostOfCommunity = (token, data) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${token || ""}` },
    url: `${BASE_URL}users/like_community_post`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  dispatch({
    type: LIKE_POST_OF_COMMUNITY,
    payload: response?.data,
  });
};

// Done
export const getFriendList = (successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_friend_list`,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response.data);
  }
  dispatch({
    type: GET_FRIEND_LIST,
    payload: response?.data,
  });
};

// Done
export const likedBy = () => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_my_like_notification`,
  };
  let response = await ApiCall(config, dispatch);
  dispatch({
    type: GET_MY_LIKE_NOTIFICATION,
    payload: response?.data,
  });
};

// Done
export const notification = () => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_all_notifications`,
  };
  let response = await ApiCall(config, dispatch);
  dispatch({
    type: GET_ALL_NOTIFICATION,
    payload: response?.data,
  });
};

// Done 
export const searchCommunity = (data, successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/search_community`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response.data);
  }
  dispatch({
    type: SEARCH_COMMUNITY,
    payload: response?.data,
  });
};

// Done
export const acceptRejectRequest = (token, data, successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${token}` },
    url: `${BASE_URL}users/accept_reject_request`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response.data);
  }
  dispatch({
    type: ACCEPT_REJECT_REQUEST,
    payload: response.data,
  });
};

// Done
export const acceptRejectFollowRequest = (token, data, successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${token}` },
    url: `${BASE_URL}users/accept_reject_follow_request`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response.data);
  }
  dispatch({
    type: ACCEPT_REJECT_FOLLOW_REQUEST,
    payload: response?.data,
  });
};

// Done
export const deleteFriend = (data, token, successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${token}` },
    url: `${BASE_URL}users/delete_friend`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response.data);
  }
  dispatch({
    type: DELETE_FRIEND,
    payload: response?.data,
  });
};

// Done
export const deleteNotification = (successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/clear_notification`,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response?.data);
  }
  dispatch({
    type: DELETE_NOTIFICATION,
    payload: response?.data,
  });
};

// Done
export const searchByUsername = (data, successCb, failureCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/search_by_username`,
    data,
  };
  try {
    let response = await ApiCall(config, dispatch);
    if (response?.data?.status) {
      successCb(response?.data); // Call the success callback function
      dispatch({
        type: SEARCH_BY_USERNAME,
        payload: response?.data,
      });
    } else {
      failureCb(response?.data); // Call the failure callback function
    }
  } catch (response) {
    if (typeof failureCb === 'function') {
      failureCb(response?.data);
    } else {
      console.error('failureCb is not a function');
    }
    
  }
};


// Done
export const sendFollowRequest = (data, successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/send_follow_request`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response?.data);
  }
  dispatch({
    type: SEND_FOLLOW_REQUEST,
    payload: response?.data,
  });
};

// Done
export const getCommunityComments = (data) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_community_comments`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  dispatch({
    type: GET_COMMUNITY_COMMENTS,
    payload: response?.data,
  });
};

// Done
export const addCommunityComments = (data, successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/add_community_comment`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response?.data);
  }
  dispatch({
    type: ADD_COMMUNITY_COMMENTS,
    payload: response?.data,
  });
};

// Done
export const likeCommunityPost = (data, successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/like_community_post`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response?.data);
  }
  dispatch({
    type: LIKE_COMMUNITY_POST,
    payload: response?.data,
  });
};

// Done
export const deleteCommunityComment = (data, successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/delete_comment`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response?.data);
  }
  dispatch({
    type: DELETE_COMMENT,
    payload: response?.data,
  });
};

// Done
export const deleteCommunityPost = (data, successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/delete_community_post`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response?.data);
  }
  dispatch({
    type: DELETE_COMMUNITY_POST,
    payload: response?.data,
  });
};

// Done
export const communityPostDetail = (data, successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_community_post_detail`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response?.data);
  }
  dispatch({
    type: GET_COMMUNITY_POST_DETAIL,
    payload: response?.data,
  });
};

// Done
export const getCommunityPostLike = (data, successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_community_post_likes`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response?.data);
  }
  dispatch({
    type: GET_COMMUNITY_POST_LIKE,
    payload: response?.data,
  });
};

// Done
export const markAsRead = (data, successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/mark_as_read`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response?.data);
  }
  dispatch({
    type: MARK_AS_READ,
    payload: response?.data,
  });
};

// Done Community -- Accept All
export const acceptRejectFollowRequestMultiple = (data, successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/accept_reject_follow_request_multiple`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response.data);
  }
  dispatch({
    type: ACCEPT_REJECT_FOLLOW_REQUEST_MULTIPLE,
    payload: response?.data,
  });
};

// Done Dating -- Accept All
export const acceptRejectRequestMultiple = (data, successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/accept_reject_request_multiple`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response.data);
  }
  dispatch({
    type: ACCEPT_REJECT_REQUEST_MULTIPLE,
    payload: response.data,
  });
};