import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import "../DRacipes/Card.css";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import { GOOGLE_MAP_APIKEY } from "../../../constants/const";
import NoImage from "../../../images/CW/no_images.png";
import Spinner from "../../../constants/Spinner";
import { useDispatch, useSelector } from "react-redux";

import { googlePlaceApi } from '../../../redux/action/dashboard'

export default function RestroCard({ search, recieveData }) {
  const dispatch = useDispatch();
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [placeItem, setPlaceItem] = useState([]);
  const [loading, setLoading] = useState(false);

  const childData = () => {
    recieveData(placeItem);
  }
  const getRestroData = useSelector((state) => state?.dashboard?.google_place?.results);

  const loadAllData = () => {
    setLoading(true);
    let locationStr = lat + "," + lng;
    locationStr = locationStr.trim();
    locationStr = locationStr == "," ? "" : locationStr;
    if (locationStr != "") {
      const successCb = () => {
        setLoading(false);
      }
      const failureCallback = () => {
        setLoading(false);
      }
      const data = {
        coordinates: locationStr,
        type:'vegan',
      }
      dispatch(googlePlaceApi(data, successCb, failureCallback))
    }
  };

  useEffect(() => {
    setPlaceItem(getRestroData)
  }, []);

  useEffect(() => {
    if(lat == "" && lng == ""){
      navigator.geolocation.getCurrentPosition((position) => {
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
    } else {
      loadAllData();
    }
  }, [lat, lng]);

  useEffect(() => {
    if (placeItem?.length > 0) {
      setLoading(false);
      childData();
    }
  }, [placeItem]);

  return loading ? (<Spinner />) : (
    <Box style={{ maxHeight: "90vh", overflowY: "scroll", padding:"1rem 0" }} >
      <Grid container spacing={2}>
        <>
          {getRestroData?.length > 0 ? getRestroData?.filter((val) => {
            if (search === "") {
              return val
            } else if (val?.name?.toLowerCase()?.includes(search?.toLowerCase())) {
              return val
            }
          })
            ?.map((value) => {
              return (
                <>
                  <Grid item xs={12} sm={6} md={4} lg={3} key={value?.place_id}>
                    <Card style={{ boxShadow: "9px 9px 31px -10px rgba(0,0,0,0.75)", borderRadius: "12px", cursor: "pointer", }} onClick={() => (window.location.href = `/#/restaurant/${value?.place_id}`)} >
                      <CardMedia
                        component="img"
                        alt="No Image Uploaded"
                        height="160"
                        image={
                          value.photos?.length > 0
                            ? `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&maxheight=400&photoreference=` +
                            value?.photos[0]?.photo_reference +
                            "&key=" +
                            GOOGLE_MAP_APIKEY
                            : NoImage
                        }
                        style={{ margin: "0", padding: "0" }}
                      />
                      <Typography
                        className="card_footer_text_style"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "16px",
                          fontWeight: "800",
                          padding: "10px",
                          borderTop: "1px solid gray",
                        }}
                      >
                        {value?.name.slice(0, 12)}...
                      </Typography>
                    </Card>
                  </Grid>
                </>
              );
            }) : <p style={{ display: "flex", color: "orange", margin: "20px 0 0 30px" }} >OOps! No places found near about you!</p>}
        </>
      </Grid>
    </Box>
  );
}