import React, { useState } from "react";
import { Box, CardMedia, IconButton, InputBase, Paper, Typography } from "@mui/material";
import "./DPlace.css";
import SearchIcon from "@mui/icons-material/Search";
import viewMap from "../../../images/CW/Group 17280@2x.png";
import viewMapAlternet from "../../../images/CW/Group 17336.png";
import RestroCard from "./RestroCard";
import FMCard from "./FMCard";
import MapCard from "./MapCard";
import { useEffect } from "react";
import Spinner from "../../../constants/Spinner";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

const DPlace = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("RS");
  const [activeIndex, setActiveIndex] = useState("1");
  const [search, setSearch] = useState("");
  const [restroData, setRestroData] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchData = (e) => {
    setSearch(e.target.value);
  };

  const placeDataButton = [{
    id: "1",
    text: "Restaurants",
    fun: "RS",
  }, {
    id: "2",
    text: "Farmer Markets",
    fun: "FM",
  }];

  const recieveData = (data) => {
    setRestroData(data);
  };

  useEffect(() => {
    recieveData();
  }, []);

  useEffect(() => {
    localStorage.setItem("ai", activeIndex??"1")
  },[activeIndex])

  return loading ? (<Spinner />) : (
    <>
      <Box className="text_map">
        <Typography className="common_medium_font">Places</Typography>
        <Typography onClick={() => navigate("/show_map")} className="mapView_icon_tablet" style={{ fontSize: "10px", cursor: "pointer" }}>
          <CardMedia component="img" alt="view_map" height="70" image={active == "M" ? viewMapAlternet : viewMap} />
        </Typography>
      </Box>
      <Box className="">
        <Box className="btn--search">
          <Box className="dplace_search_width_style_top">
            <Box sx={{ p: "0 4px", display: "flex", alignItems: "center", background: "#ececec", borderRadius: "36px", }} style={{ height: "50px", width: "100%", font: "normal 18px/20px Montserrat", }} >
              <InputBase sx={{ ml: 1, flex: 1, color: "#000" }} placeholder="Search Places" inputProps={{ "aria-label": "Search" }} value={search} onChange={(e) => searchData(e)} />
              <IconButton sx={{ p: "10px" }} aria-label="search" onClick={(e) => searchData(e)} >
                <SearchIcon style={{ color: "rgba(136,132,132)", fontSize: "32px", fontWeight: "900" }} /> </IconButton>
            </Box>
          </Box>
          <div className="d-flex w-100">
            {placeDataButton.map((data, key) => {
              return (
                <Button className="common_btn  me-lg-3 my-3" onClick={() => { setActiveIndex(data.id); setActive(data.fun); }} style={{ backgroundColor: activeIndex === data.id ? "#F8931F" : "#fff", }} key={key} >
                  <Typography style={{ color: activeIndex === data.id ? "#fff" : "#F8931F", textTransform: "capitalize", fontSize: "13px", fontFamily: "Montserrat", fontWeight: "500", }} > {data.text} </Typography>
                </Button>
              );
            })}
          </div>
          {/* Search Box */}
          <Box className="map_search_button">
            <Box className="dplace_search_width_style">
              <Paper sx={{ p: "0 4px", display: "flex", alignItems: "center", width: "1", background: "#F89601", }} style={{ height: "50px", width: "100%", font: "normal 18px/20px Montserrat", }} >
                <InputBase sx={{ ml: 1, flex: 1, color: "#fff" }} placeholder="Search Places" inputProps={{ "aria-label": "Search" }} value={search} onChange={searchData} />
                <IconButton sx={{ p: "10px" }} aria-label="search" onClick={searchData} > <SearchIcon style={{ color: "#fff", fontSize: "32px", fontWeight: "900", }} /> </IconButton>
              </Paper>
            </Box>
            {/* Map View */}
            <Typography onClick={() => navigate("/show_map")} className="mapView_icon" style={{ cursor: "pointer" }} > <CardMedia component="img" alt="view_map" height="70" image={active == "M" ? viewMapAlternet :  viewMap} /> </Typography>
          </Box>

        </Box>

        {active === "RS" && (
          <RestroCard search={search} recieveData={recieveData} />
        )}
        {active === "FM" && (
          <FMCard search={search} recieveData={recieveData} />
        )}
        {active === "M" && <MapCard search={search} restroData={restroData} />}
      </Box>
    </>
  );
}
export default DPlace;