import {
  LOGIN,
  LOGOUT,
  REGISTER,
  USER_TOKEN,
  REFRESH_TOKEN,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  FORGET_PASSWORD,
  VERIFICATION_OTP,
  VERIFY_REGISTRATION_OTP,
  SELECTEDCHAT,
  CONTACT_US
} from "../constants";

const INIT_STATE = {
  token: "",
  user_data: null,
  call_response: null,
  selectedChat: null,
  contact_us: null,
  login_success: null,
  register_success: null,
  forget: null,
  verification: null,
  reset_pass: null,
  change_pass: null,
};

export default (state = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN:
      if (payload.status) {
        return {
          ...state,
          token: payload.data.token,
          user_data: payload.data.data,
          login_success: payload,
        };
      }
      return {
        ...state,
        login_success: payload,
      };
    case REGISTER:
      return {
        ...state,
        register_success: payload,
      };

    case USER_TOKEN:
      return {
        ...state,
        token: payload,
      };
    case FORGET_PASSWORD:
      return {
        ...state,
        forget: payload,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        change_pass: payload,
      };
    case VERIFICATION_OTP:
      return {
        ...state,
        verification: payload,
        login_success: payload,
        // token: payload.data.token,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        reset_pass: payload,
      };
    case LOGOUT:
      return {
        ...state,
        user_data: null,
        token: "",
        call_response: payload,
      };
    case SELECTEDCHAT:
      return {
        ...state,
        selectedChat: payload
      };
    case CONTACT_US:
      return {
        ...state,
        contact_us: payload
      };
    default:
      return state;
  }
};
