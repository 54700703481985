import React, { useEffect, useState } from "react";
import { CameraFilled } from "@ant-design/icons";
import { Box, Typography } from "@mui/material";
import { Input, Button } from "antd";
import { useDispatch } from "react-redux";
import { addCommunityPost } from "../../../redux/action/community";
import toast from "../../../constants/toast";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import advtImg from "../../../images/CW/advertise.jpeg";

const { TextArea } = Input;

const AddPost = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDesc] = useState("");
  const [community_media, setCommunity_media] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false)

  let mediatype = community_media?.type.split("/")
  useEffect(() => {
    if (community_media) {
      setImageUrl(URL.createObjectURL(community_media));
    }
  }, [community_media]);

  const sendCommunityPost = async () => {
    setLoading(true)
    if (title === "" || description === "") {
      toast.error("Following fields are required : title, description");
      setLoading(false);
      return false;
    }
    const successCb = () => {
      setLoading(false);
      toast.success("Post uploaded successfully!");
      navigate("/DCommunity");
    };
    dispatch(
      addCommunityPost(
        { title, description, community_media },
        successCb
      )
    );
  };

  // Clear the Image box
  const clearImage = () => {
    setImageUrl(null);
    setCommunity_media(null);
  };

  // FOR ADVT.
  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle
        adsbygoogle.push({})
      } catch (e) {
        console.error('error', e)
      }
    }

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd()
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval)
      }
    }, 300)

    return () => {
      clearInterval(interval)
    }
  }, [])

  // Upload files function
  const handleUploadFile = (e) => {
    const file = e.target.files[0]
    if (file?.type == "image/jpeg" && file?.size > 10 * 1024 * 1024) {
      toast.error("Image should be less than 10MB!");
      return
    }
    setCommunity_media(file)
  }

  return (
    <>
      <Typography className="common_medium_font mb-3">Community Add Post</Typography>
      <div className="row">
        <div style={{}} className="d-none d-lg-block col-lg-3" >
          {/* <GoogleAdSense
            html={
              '<ins class="adsbygoogle"\n' +
              'style="display:block"\n' +
              'data-ad-client="ca-pub-5665970448524979"\n' +
              'data-ad-slot="4528146213"\n' +
              'data-ad-format="auto"\n' +
              'data-full-width-responsive="true"></ins>'
            }
          /> */}
          {/* <script>
            (adsbygoogle = window.adsbygoogle || []).push({ });
          </script> */}
        </div>
        <div  className="col-12 col-lg-6">
          <Box>
            {community_media === null ?
              <Box component="label" style={{ border: "1px dotted black", width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", height: "25vh", alignItems: "center", borderRadius: "15px", }} >
                <CameraFilled style={{ color: "#000", fontSize: "34px" }} /> Upload Media
                <input hidden type="file" name="file" accept="video/mp4,image/*"
                  onChange={handleUploadFile} />
              </Box> : ''}
            {imageUrl && community_media && (
              <Box mt={2} textAlign="center">
                <Box
                  onClick={() => clearImage()}
                  style={{
                    cursor: "pointer",
                    float: "right"
                  }}>
                  <CloseIcon />
                </Box>
                {mediatype[0] == "image" ?
                  <img src={imageUrl} alt={community_media?.name} height="200px" width="auto" />
                  : <video height="250" controls > <source src={imageUrl} type="video/mp4" /> </video>}
              </Box>
            )}
            <Box>
              <Input placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)}
                style={{ margin: "20px 0", borderRadius: "16px !important", boxShadow: "0px 3px 6px #00000050", border: "2px", padding: "20px", }}
              />
              <TextArea maxLength={200} rows={6} value={description} onChange={(e) => setDesc(e.target.value)}
                placeholder="Description (Maximum characters : 200 words)"
                style={{ borderRadius: "16px", boxShadow: "0px 3px 6px #00000050 ", border: "2px", padding: "20px", }}
              />
            </Box>
            <Box className="addRecipes--btn mt-4" style={{ textTransform: "capitalize" }} >
              <Button loading={loading} className="common_btn" onClick={() => sendCommunityPost()} > Publish </Button>
            </Box>
          </Box>
        </div>
        <div style={{}} className="d-none d-lg-block col-lg-3" >
          {/* <GoogleAdSense
            html={
              '<ins class="adsbygoogle"\n' +
              'style="display:block"\n' +
              'data-ad-client="ca-pub-5665970448524979"\n' +
              'data-ad-slot="4528146213"\n' +
              'data-ad-format="auto"\n' +
              'data-full-width-responsive="true"></ins>'
            }
          /> */}
        </div>
      </div>
    </>

  );
};
export default AddPost;