import {
  SEND_FEEDBACK,
  UPDATE_GALLERY,
  GET_USER_DETAIL,
  GET_USER_PROFILE,
  GET_USER_GALLERY,
  UPDATE_USER_PROFILE,
  ADD_PROFILE_GALLERY,
  DELETE_PROFILE_IMAGE,
  BADGESDATA_SIDEBAR,
  TERMS_ABOUT,
  HELP,
  GOOGLE_PLACE_API,
  RESTRO_DETAILS,
  DELETE_ACCOUNT
} from "../constants";
const INIT_STATE = {
  token: "",
  user_data: null,
  call_response: null,
  add_profile_gallery: null,
  badgesdata_sidebar: null,
  getuser_gallery: null,
  terms_about: null,
  help: null,
  google_place: null,
  restro_detail: null,
  delete_account: null,
};

export default (state = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SEND_FEEDBACK:
      return {
        ...state,
        call_response: payload,
      };
    case UPDATE_GALLERY:
      return {
        ...state,
        call_response: payload,
      };
    case GET_USER_DETAIL:
      return {
        ...state,
        call_response: payload,
      };
    case GET_USER_PROFILE:
      return {
        ...state,
        user_data: payload.status ? payload.data : null
      };
    case GET_USER_GALLERY:
      return {
        ...state,
        getuser_gallery: payload,
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        call_response: payload,
        user_data: payload.status ? payload.data : null
      };
    case ADD_PROFILE_GALLERY:
      return {
        ...state,
        add_profile_gallery: payload,
      };
    case DELETE_PROFILE_IMAGE:
      return {
        ...state,
        call_response: payload,
      };
    case BADGESDATA_SIDEBAR:
      return {
        ...state,
        badgesdata_sidebar: payload,
      };
      case TERMS_ABOUT:
        return {
          ...state,
          terms_about: payload,
        };
      case HELP:
        return {
          ...state,
          help: payload,
        };
      case GOOGLE_PLACE_API:
        return {
          ...state,
          google_place: payload,
        };
      case RESTRO_DETAILS:
        return {
          ...state,
          restro_detail: payload,
        };
      case DELETE_ACCOUNT:
        return {
          ...state,
          delete_account: payload,
        };
    default:
      return state;
  }
};
