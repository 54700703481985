import React, { useEffect } from 'react'
import '../Help/Help.css'
import { Box, Typography } from '@mui/material'
import { useDispatch, useSelector } from "react-redux";
import { termsAbout } from '../../../redux/action/dashboard';

const Terms = () => {
  const dispatch = useDispatch();
  const termsData = useSelector((state) => state?.dashboard?.terms_about?.data?.terms_conditions);
  const htmlString = termsData;

  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle
        adsbygoogle.push({})
      } catch (e) {
        console.error('error', e)
      }
    }

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd()
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval)
      }
    }, 300)
    dispatch(termsAbout())
    return () => {
      clearInterval(interval)
    }
  }, [])
  return (
    <Box style={{ background: '#fff' }} className="tc_styling">
      <Typography style={{ fontSize: 22 }}>Terms & Conditions</Typography>
      <div style={{ maxHeight: '65vh', overflowY: 'auto' }} dangerouslySetInnerHTML={{ __html: htmlString }} />
      {/* <GoogleAdSense
        html={
          '<ins class="adsbygoogle"\n' +
          'style="display:block"\n' +
          'data-ad-client="ca-pub-5665970448524979"\n' +
          'data-ad-slot="6602811062"\n' +
          'data-full-width-responsive="true"></ins>'
        }
      /> */}
    </Box>
  )
}
export default Terms