import React, { useEffect, useState } from "react";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { likedBy, markAsRead } from "../../../redux/action/community";
import { getDiff, ImgUrl } from "../../../constants/const";
import Spinner from "../../../constants/Spinner";
import "./Liked.css";
import dummyProfile from "../../../images/CW/profile.jpeg";

const Liked = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const likeByData = useSelector((state) => state.community?.like_by);
  const userData = useSelector((state) => state.dashboard?.user_data);

  useEffect(() => {
    const successCb = (resp) => {}
    let data = {
      user_id: userData?._id,
      type: "community_like"
    }
    dispatch(markAsRead(data, successCb))
    dispatch(likedBy())
  }, [])

  useEffect(() => {
    if (likeByData && loading) {
      setLoading(false);
    }
  }, [likeByData]);

  return (
    <>
      {loading ? (<Spinner />) : (
        <div>
          <Typography className="common_medium_font">Liked By</Typography>
          <Box className="scroll_function">
            {likeByData?.data?.length > 0 ? (
              likeByData?.data?.map((data, id) => {
                return (
                  <Box className="liked_main_style" key={id}>
                    <List sx={{ width: "100%", maxWidth: "97%", bgcolor: "background.paper" }}>
                      <ListItem secondaryAction={getDiff(data?.created_at)} className="liked_margin_padding_mui">
                        <Box className="liked_text_style">
                          <Box> <ListItemAvatar>
                            <Avatar className="liked_avatar_style" src={data?.sent_by.profile_pic !== "" ? `${ImgUrl}${data?.sent_by.profile_pic}` : `${dummyProfile}`} />
                          </ListItemAvatar> </Box>
                          <ListItemText primary={data?.title} secondary={<Typography sx={{ display: "inline" }} component="span" variant="body2" style={{ color: "#444444" }}> {data?.body} </Typography>} />
                        </Box>
                      </ListItem>
                    </List>
                  </Box>
                );
              })
            ) : (
              <Typography  style={{ display: "flex", color: "orange"}}> No Liked Found! </Typography>)}
          </Box>
        </div>
      )}
    </>
  );
};
export default Liked;