import React, { useEffect, useState } from "react";
import { Button, Card, CardMedia, Grid } from "@mui/material";
import { Box } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CardContent from "@mui/material/CardContent";
import { useDispatch, useSelector } from "react-redux";
import { ImgUrl } from "../../../constants/const";
import dummyRestro from "../../../images/CW/dummyRestro.png";
import { Image } from "antd";
import { getUserGallery } from "../../../redux/action/dashboard";
import { Input } from "antd";
import { Modal } from "react-bootstrap";
import ReactHlsPlayer from "react-hls-player";
const { TextArea } = Input;

const ProfileGallery = () => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [imageset, setImageset] = useState(null);
  const [type, setType] = useState(null);

  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const gallery = useSelector((state) => state.dashboard?.getuser_gallery?.data);
  useEffect(() => {
    dispatch(getUserGallery())
  }, [])

  const handleClickOpenDesc = (data) => {
    setShow(true)
    setImageset(data?.media)
    setType(data?.media_type)
  }
  const handleCloseDesc = () => {
    setShow(false);
    setImageset('')
    setType('')
  };

  return (
    <Box>
      <Box style={{ display: "flex", flexDirection: "row-reverse", color: "#000" }}>
        <Button className="see_more_card_btn" onClick={handleClickOpen}> See All </Button>
      </Box>

      <Grid container spacing={1} style={{ maxHeight: "35vh", overflowY: "scroll", padding:"1.5rem 0" }}>
        {gallery?.length > 0 ? gallery?.map((data, id) => {
          return (
            <Grid item xs={12} sm={3} md={3} lg={2} key={id}>
              <Card onClick={() => handleClickOpenDesc(data)} style={{ margin: "0", padding: "0", boxSizing: "border-box", position: "relative", boxShadow: "9px 9px 31px -10px rgba(0,0,0,0.75)", borderRadius: "10px", border: "2px solid orange", objectFit: "cover", }} >
                {data?.media_type === "image" ? (
                  <CardMedia component="img" image={data?.media !== "" ? `${ImgUrl}${data?.media}` : `${dummyRestro}`} alt="dish_image" height="200px" style={{ objectFit: 'cover' }} />
                ) : (
                  data?.media.includes('mp4') ?
                    <video src={data?.media !== "" ? `${ImgUrl}${data?.media}` : ''} width="100%" height="200px" controls="controls" />
                    :
                    <ReactHlsPlayer
                      src={`${ImgUrl}${data?.media}`}
                      autoPlay={false}
                      width="100%"
                      height="200px"
                      style={{ objectFit: "cover" }}
                      controls
                    />
                )}
              </Card>
            </Grid>
          );
        }) : <p className="d-flex text-center mx-4" >No data added yet!</p>}
      </Grid>
      <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title" >
        <DialogContent>
          <Box style={{ display: "flex", alignItems: "center", }} >
            <KeyboardBackspaceIcon onClick={handleClose} fontSize="large" style={{ flex: "start", cursor: "pointer" }} />
          </Box>
          <DialogContentText style={{ color: "#000", fontFamily: "Montserrat", fontWeight: "normal", display: "flex", textAlign: "center", }} >
            <Box style={{ margin: "0", padding: "0" }}>
              <CardContent>
                <Box>
                  <Grid container spacing={2}>
                    {gallery?.length > 0 ? gallery?.map((data, id) => {
                      return (
                        <Grid item xs={12} sm={4} md={4} lg={4} key={id}>
                          {data?.media_type === "image" ? (
                            <CardMedia component="img" image={data?.media !== "" ? `${ImgUrl}${data?.media}` : `${dummyRestro}`} alt="dish_image" style={{ borderRadius: "10px 10px 0 0px", height: "100%", }} />
                          ) : (
                            data?.media.includes('mp4') ?
                              <video src={data?.media !== "" ? `${ImgUrl}${data?.media}` : ''} width="200" height="200" controls="controls" />
                              :
                              <ReactHlsPlayer
                                src={`${ImgUrl}${data?.media}`}
                                autoPlay={false}
                                width="100%"
                                height="200px"
                                style={{ objectFit: "cover" }}
                                controls
                              />
                          )}
                        </Grid>
                      );
                    }) : <p className="d-flex text-center mx-4" >No data added yet!</p>}
                  </Grid>
                </Box>
              </CardContent>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Show Details */}
      <Modal show={show} onHide={handleCloseDesc}>
        <Modal.Header closeButton />
        <Modal.Body>
          <Box>
            <Box mt={2} textAlign="center">
              {type == "image" ?
                <img src={`${ImgUrl}${imageset}`} alt='img' className="community_card" />
                :
                imageset?.includes('mp4') ?
                  <video className="community_card" controls src={`${ImgUrl}${imageset}`} />
                  :
                  <ReactHlsPlayer
                    src={`${ImgUrl}${imageset}`}
                    className="community_card"
                    controls
                  />
              }
            </Box>
          </Box>
        </Modal.Body>
      </Modal>
    </Box>
  );
};

export default ProfileGallery;
