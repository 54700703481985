import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import "../DRacipes/Card.css";
import { Box } from "@mui/system";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { CameraFilled } from "@ant-design/icons";

import { ImgUrl } from "../../../constants/const";
import { useDispatch, useSelector } from "react-redux";
import dummyRestro from "../../../images/CW/dummyRestro.png";
import { deleteRecipePost, editRecipePost, getRecipePostDetail, getMyRecipe } from "../../../redux/action/recipe";
import Modal from "react-bootstrap/Modal";
import { Input, Spin } from "antd";
import { getUserData } from "../../../redux/action/dashboard";
import ReactHlsPlayer from 'react-hls-player';

const { TextArea } = Input;
const styles = {
  position: "absolute",
  top: "8px",
  right: "8px",
  background: "#fff",
  height: "25px",
  width: "25px",
  padding: "5px",
  borderRadius: "50%",
  color: "#F89601",
  cursor: "pointer",
  boxShadow: "0px 3px 6px #00000029",
};
const styles2 = {
  position: "absolute",
  top: "40px",
  right: "8px",
  background: "#fff",
  height: "25px",
  width: "25px",
  padding: "5px",
  borderRadius: "50%",
  color: "#F89601",
  cursor: "pointer",
  boxShadow: "0px 3px 6px #00000029",
};

export default function EditProfileRecipe() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [idData, setIdData] = useState(null);
  // For Edit
  const editRecipeData = useSelector((state) => state?.recipe?.get_recipe_post_details?.data);
  const [show, setShow] = useState(false);
  const [idData1, setIdData1] = useState(null);
  const [title, setTitle] = useState(editRecipeData?.title);
  const [description, setDesc] = useState("");
  const [recipe_media, setRecipe_media] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (recipe_media) {
      setImageUrl(URL.createObjectURL(recipe_media));
    }
  }, [recipe_media]);

  const userRecipeData = useSelector((state) => state.recipe?.get_my_recipe?.data);

  // Delete Confirmation Start
  const handleClickOpen = (id) => {
    setOpen(true);
    setIdData(id);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // Delete Confirmation End

  // Edit Start
  const handleCloseEdit = () => setShow(false);
  const handleShowEdit = (id) => {
    setShow(true);
    setIdData1(id);
    // alert(id)
    const successCb = (res) => {
      setTitle(res?.data?.title)
      setDesc(res?.data?.description)
      return res;
    };
    let data = {
      recipe_post_id: id,
    };
    dispatch(getRecipePostDetail(data, successCb));
  };

  const editRecipeSave = () => {
    setLoading(true)
    setShow(false);
    const successCb = (res) => {
      setLoading(false)
      dispatch(getMyRecipe())
      return res;
    };
    let data = {
      recipe_post_id: idData1,
      title,
      description,
      recipe_media,
    };
    dispatch(editRecipePost(data, successCb));
  };
  // Edit End

  // Delete Recipe
  const deleteRecipe = async () => {
    setOpen(false);
    setLoading(true)
    const successCb = (res) => {
      setLoading(false)
      dispatch(getMyRecipe())
      return res;
    };
    let data = {
      recipe_post_id: idData,
    };
    dispatch(deleteRecipePost(data, successCb));
  };

  useEffect(() => {
    dispatch(getMyRecipe())
  }, [])

  return (
    <Box style={{ margin: "0  20px" }}>
      <Grid container columnSpacing={2} rowSpacing={1} style={{ maxHeight: "40vh", overflowY: "scroll" }}>
        {userRecipeData?.length > 0 ? userRecipeData?.map((data, id) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={2} key={id} >
              <Card
                style={{
                  boxShadow: "9px 9px 31px -10px rgba(0,0,0,0.75)",
                  borderRadius: "10px",
                  position: "relative",
                }}
              >
                {data?.media_type === "image" ? (
                  <CardMedia
                    component="img"
                    image={
                      data?.media !== ""
                        ? `${ImgUrl}${data?.media}`
                        : dummyRestro
                    }
                    alt="media"
                    height={200}
                  />
                ) : (
                  data?.media.includes('mp4') ?
                    <video width="100%" height="200px" controls >
                      <source src={data?.media !== "" ? `${ImgUrl}${data?.media}` : ''} type="video/mp4" />
                    </video>
                    :
                    <ReactHlsPlayer
                      src={`${ImgUrl}${data?.media}`}
                      autoPlay={false}
                      width="100%"
                      height="200px"
                      controls
                      style={{ objectFit: "cover" }}
                    />
                )}
                <EditIcon
                  style={styles}
                  onClick={() => handleShowEdit(data?._id)}
                />
                <DeleteIcon
                  style={styles2}
                  onClick={() => handleClickOpen(data?._id)}
                />
              </Card>
            </Grid>
          );
        }) : <h2 className="small_font">No data added yet</h2>}
      </Grid>

      {/* Delete Pop-Up */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText
            style={{
              color: "#000",
              fontFamily: "Montserrat",
              fontWeight: "normal",
              marginTop: "15px 0",
            }}
          >
            Are you sure you want to delete this picture?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button onClick={handleClose} style={{ color: "#000" }}>
            <strong>No</strong>
          </Button>
          <Button onClick={() => deleteRecipe()} style={{ color: "#F8931F" }}>
            <Spin spinning={loading}><strong>Yes</strong></Spin>
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Pop-Up */}
      <Modal show={show} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Recipe Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Box>
            {recipe_media == null || recipe_media?.type == undefined ?
              <Box component="label" className="user_background_img_setting"
                style={{
                  border: "1px dotted black",
                  width: "100%", display: "flex",
                  justifyContent: "center", flexDirection: "column",
                  height: "400px", alignItems: "center", borderRadius: "10px", cursor: "pointer",
                }}
              >
                <h2 className="btn btn-dark btn-sm">upload</h2>
                {editRecipeData?.media_type == "video" && editRecipeData?.media.includes('mp4') ?
                  <video className="community_card">
                    <source src={`${ImgUrl}${editRecipeData?.media}`} type="video/mp4" />
                  </video>
                  :
                  editRecipeData?.media_type == "image" ?
                    <img className="community_card" src={`${ImgUrl}${editRecipeData?.media}`} />
                    :
                    <ReactHlsPlayer
                      src={`${ImgUrl}${editRecipeData?.media}`}
                      autoPlay={false}
                      className="community_card"
                      style={{ objectFit: "cover" }}
                      controls
                    />
                }
                <input hidden type="file" name="file" onChange={(e) => setRecipe_media(e.target.files[0])} />
              </Box>
              : ''}

            <Box>
              <Input className="common_input_fields" value={title} onChange={(e) => setTitle(e.target.value)} style={{ margin: "20px 0 10px 0", borderRadius: "36px !important", boxShadow: "0px 3px 6px #00000050", border: "2px", padding: "20px", }} />
              <TextArea maxLength={200} rows={6} value={description} onChange={(e) => setDesc(e.target.value)} className="common_input_fields rounded mt-2" />
            </Box>
            <Box className="addRecipes--btn" style={{ textTransform: "capitalize" }} >
              <Spin spinning={loading}><Button className="AddRacipes-btn" onClick={() => editRecipeSave()} >Save</Button></Spin>
            </Box>
          </Box>
        </Modal.Body>
      </Modal>
    </Box>
  );
}
