import { Box } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { termsAbout } from "../../../redux/action/dashboard";
import "./DAbout.css";

const DAbout = () => {
  const dispatch = useDispatch();
  const aboutData = useSelector((state) => state?.dashboard?.terms_about?.data?.about);
  const htmlString = aboutData;

  useEffect(() => {
    dispatch(termsAbout())
  }, [])

  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle
        adsbygoogle.push({})
      } catch (e) {
        console.error('error', e)
      }
    }

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd()
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval)
      }
    }, 300)

    return () => {
      clearInterval(interval)
    }
  }, [])
  return (
    <Box className="about_alignment_style" style={{ background: '#fff' }}>
      <div style={{ maxHeight: '80vh', overflowY: 'auto', minHeight: '50vh', letterSpacing: 1, lineHeight: 25 }} dangerouslySetInnerHTML={{ __html: htmlString }} />
      {/* <GoogleAdSense
        html={
          '<ins class="adsbygoogle"\n' +
          'style="display:block"\n' +
          'data-ad-client="ca-pub-5665970448524979"\n' +
          'data-ad-slot="6602811062"\n' +
          'data-full-width-responsive="true"></ins>'
        }
      /> */}
    </Box>
  );
};
export default DAbout;