import { combineReducers } from "redux";
import dashboard from "./dashboard";
import auth from "./auth";
import common from "./common";
import community from "./community";
import meet from "./meet";
import recipe from "./recipe";

const root = combineReducers({
    auth,
    dashboard,
    meet,
    recipe,
    community,
    common
});

export default root;