import {
  LOGIN,
  REGISTER,
  REFRESH_TOKEN,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  VERIFICATION_OTP,
  USER_TOKEN,
  CALL_RESPONSE,
  SELECTEDCHAT,
  CONTACT_US
} from "../constants";
import { BASE_URL, authToken } from "../../constants/const";
import { ApiCall } from "../../api";

const APP_ID = "ab0e65d266354bf684b91530ee7e481a"
// const APP_Certificate = "1cdbde0b148240c4afe045178304ad1f"
const APP_Certificate = "6f47bdb274d049ae9eac9ff9e0cbfa6b"
const UID = 0

export const login = (data, successCb, failureCallback) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { 'Content-Type': 'application/json' },
    url: `${BASE_URL}users/login`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response.data);
  } else {
    failureCallback(response.data)
  }
  dispatch({
    type: LOGIN,
    payload: response?.data,
  });
};

export const setSelectedChat = (rk) => async (dispatch) => {
  dispatch({
    type: SELECTEDCHAT,
    payload: rk
  });
};

export const sendChatNotification = (data, successCb) => async (dispatch) => {
  try {
    let config = {
      method: "post",
      headers: { Authorization: `Bearer ${authToken}` },
      url: `${BASE_URL}users/send_chat_notification`,
      data,
    };
    let response = await ApiCall(config, dispatch);
    if (response.data.status) {

    }
  } catch (err) {
    console.log(" error in catch", err)
  } finally {
    successCb({ status: true, data: {} });
  }

};

export const generateAgoraToken = (data, retrieveToken) => async (dispatch) => {
  let formData = new FormData()
  formData.append("appCertificate", APP_Certificate)
  formData.append("appID", data.appID)
  formData.append("uid", UID)
  formData.append("channel_name", data.channel_name)
  const myObj = {
    appCertificate:APP_Certificate,
    appId:data.appID,
    uid:UID,
    channel_name:data.channel_name,
  }
  let config = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    url: `https://api.vegansmeetdaily.com/api/v1/users/create_agora_token`,
    data: myObj
  };
  let response = await ApiCall(config, dispatch);
  // console.log("generateAgoraToken response",response?.data)
  if (response?.data?.status) {
    retrieveToken(response?.data);
  }
};

export const registerUser = (data, successCb, failureCallback) => async (dispatch) => {
  let config = {
    method: "post",
    headers: {},
    url: `${BASE_URL}users/register`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response.data);
  } else {
    failureCallback(response.data)
  }
  dispatch({
    type: REGISTER,
    payload: response?.data,
  });
};

export const forgotPassword = (data, successCb, failureCallback) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/forgot_password`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response?.data);
  } else {
    failureCallback(response.data)
  }
  dispatch({
    type: FORGET_PASSWORD,
    payload: response?.data,
  });
};

export const ResetPassword = (data, successCb, failureCallback) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/reset_password`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response?.data);
  } else {
    failureCallback(response.data)
  }
  dispatch({
    type: RESET_PASSWORD,
    payload: response?.data,
  });
};

export const userChangePassword = (data, successCb, failureCallback) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/change_password`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response?.data);
  } else {
    failureCallback(response?.data)
  }
  dispatch({
    type: CHANGE_PASSWORD,
    payload: response?.data,
  });
};

export const contactUs = (data, successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/contact_us`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response?.data);
  }
  // dispatch({
  //   type: CONTACT_US,
  //   payload: response?.data,
  // });
};

export const validateOtp = (data, successCb, failureCallback) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { 'Content-Type': 'application/json' },
    url: `${BASE_URL}users/verify_otp`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response?.data);
  } else {
    failureCallback(response.data)
  }
  dispatch({
    type: VERIFICATION_OTP,
    payload: response?.data,
  });
};

export const setCallResponse = (data) => async (dispatch) => {
  dispatch({
    type: CALL_RESPONSE,
    payload: data,
  });
};

export const setUserToken = (data) => async (dispatch) => {
  dispatch({
    type: USER_TOKEN,
    payload: data,
  });
};

/* resend OTP */
export const resendOTP = (data, successCb, failureCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/resend_otp`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  try {
    if (response?.data?.status) {
      successCb(response?.data);
    } else {
      failureCb(response)
    }
  } catch (err) {
    failureCb(err)
  }
};
