import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import { InputBase } from "@mui/material";
import ReactHlsPlayer from 'react-hls-player';
import leafImg from "../../../images/CW/Icon awesome-leaf@2x.png";
import greenLeafImg from "../../../images/CW/Icon awesome-green-leaf@2x.png";
import commentImg from "../../../images/CW/Icon awesome-comment@2x.png";
import shareImg from "../../../images/CW/Icon ionic-ios-share-alt@2x.png";
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "react-bootstrap/Modal";
// Images
import SideLogo from "../../../images/Group 33033.png";
import dummyRestro from "../../../images/CW/no_images.png";
import dummyProfile from "../../../images/CW/profile.jpeg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addCommunityComments, getCommunityComments, getCommunityPost, searchByUsername, likeCommunityPost, deleteCommunityComment, getCommunityPostLike, getNewCommunityPost } from "../../../redux/action/community";
import { ImgUrl } from "../../../constants/const";
import { getDiff } from "../../../constants/const";
import Spinner from "../../../constants/Spinner";
// For Share
import { FacebookShareButton } from "react-share";
import { Spin } from "antd";

const CommunityCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [spinLoad, setSpinLoad] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [commId, setCommId] = useState("");
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [searchingModal, setSearchingModal] = useState(false);
  const [usersData, setUsersData] = useState([]);

  const get_community = useSelector((state) => state?.community?.get_community?.data);
  const search_api_data = useSelector((state) => state.community?.search_by_username?.data);
  const getCommunityPostComment = useSelector((state) => state?.community?.get_community_comment?.data);
  const getCommunityPostLikesss = useSelector((state) => state?.community?.get_community_post_likes);
  const userData = useSelector((state) => state.dashboard?.user_data);
  // console.log(userData?._id, '>>>>>>>>>><<<<<<<<<userDatassssssssss')


  // Like Modal Start
  const handleShowRB = (id) => {
    setShow(true);
    const successCb = (res) => {
      return;
    };
    let data = {
      community_post_id: id,
      comment: message,
      page: 1,
    };
    dispatch(getCommunityPostLike(data, successCb));
  };

  const handleCloseRB = () => setShow(false);
  // Like Modal End

  const handleClickOpen = (dataId) => {
    setSpinLoad(true);
    setOpen(true);
    setCommId(dataId);
    let data = {
      community_post_id: dataId,
      page: 1,
    };
    dispatch(getCommunityComments(data));
  };

  const addComment = () => {
    if (!message) {
      return
    }
    setSpinLoad(true)
    const successCb = (res) => {
      setMessage("");
      setSpinLoad(false)
      setTimeout(() => {
        let data = {
          community_post_id: commId,
          page: 1,
        };
        dispatch(getCommunityComments(data));
        let token = localStorage.getItem("token");
        dispatch(getCommunityPost(token, { page: '1' }));
      }, 500);
      return;
    };
    let data = {
      community_post_id: commId,
      comment: message,
      page: 1,
    };
    dispatch(addCommunityComments(data, successCb));
  };

  const deleteComment = (dataId) => {
    setSpinLoad(true)
    const successCb = (res) => {
      setSpinLoad(false)
      let data = {
        community_post_id: commId,
        page: 1,
      };
      dispatch(getCommunityComments(data));
      let token = localStorage.getItem("token");
      dispatch(getCommunityPost(token, { page: '1' }));
      return;
    };
    let data = {
      community_post_id: dataId?._id,
      comment_id: dataId?._id,
    };
    dispatch(deleteCommunityComment(data, successCb));
  };

  const toggleImage = (dataId) => {
    const successCb = () => {
      let token = localStorage.getItem("token");
      dispatch(getCommunityPost(token, { page: '1' }));
      return;
    };
    let data = {
      community_post_id: dataId,
      page: 1,
    };
    dispatch(likeCommunityPost(data, successCb));
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    dispatch(getCommunityPost(token, { page: '1' }));
  }, []);

  const searchData = () => {
    setSearchLoader(true)
    const success = (res) => {
      setUsersData(res?.data)
      setSearchLoader(false)
    }
    const failure = (res) => {
      setSearchLoader(false)
      setUsersData([])
    }
    let data = {
      keyword: '',
      limit: '',
      page: 1
    };
    dispatch(searchByUsername(data, success, failure));
  };

  useEffect(() => {
    searchData();
  }, [])

  const handleSearchInput = (val) => {
    setSearch(val)
    const searchResult = search_api_data?.filter(e => e?.full_name?.toLowerCase().includes(val?.toLowerCase()))
    setUsersData(searchResult)
  }

  useEffect(() => {
    if (get_community && loading) {
      setLoading(false);
    }
  }, [get_community]);

  useEffect(() => {
    if (getCommunityPostComment) {
      setSpinLoad(false);
    }
  }, [getCommunityPostComment]);



  return (
    <>
      {loading ? (<Spinner />) : (
        <Box className="community_card_style">
          {/* Search Box */}
          <Box className="search_bar_comm_card">
            <Box onClick={() => setSearchingModal(true)} style={{ borderRadius: "36px" }} sx={{ p: "2px 4px", m: "10px 0", display: "flex", alignItems: "center", width: "1", background: "#ececec", }} >
              <IconButton sx={{ p: "10px" }} aria-label="search"  >
                <SearchIcon />
              </IconButton>
              <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search Community" />
            </Box>
            {/* <Box style={{ maxHeight: "55vh", overflowY: "scroll", position: "absolute", zIndex: "1", width: "48%", background: "#fff", }} >
              {search_api_data?.map((data, id) => {
                return (
                  <>
                    <Box key={id}>
                      <Link to={`/c_profile/${data?._id}`} style={{ color: "orange" }} >
                        <List sx={{ width: "100%", maxWidth: "97%", bgcolor: "background.paper", }} >
                          <ListItem className="">
                            <Box className="">
                              <Box>
                                <ListItemAvatar>
                                  <Avatar src={data?.profile_pic !== "" ? `${ImgUrl}${data?.profile_pic}` : `${dummyProfile}`} className="liked_avatar_style" />
                                </ListItemAvatar>
                              </Box>
                            </Box>
                            <ListItemText primary={data?.full_name} />
                          </ListItem>
                        </List>
                      </Link>
                    </Box>
                  </>
                );
              })}
            </Box> */}
          </Box>

          <Grid container spacing={2} style={{ maxHeight: "90vh", overflowY: "scroll", marginTop: "10px" }} >
            {get_community?.length > 0 ? (
              get_community?.map((data, id) => {
                return (
                  <Grid item xs={12} sm={12} md={12} lg={12} key={id} style={{ paddingTop: '0px', marginTop: '0px' }}>
                    <Card sx={{ width: 1 }} style={{ borderRadius: "12px", margin: "0px 10px 10px 0", }} >
                      <Link to={`/c_profile/${data?.user_id?._id}`} style={{ color: "orange" }} >
                        <CardHeader
                          avatar={<Avatar style={{ border: "2px solid #F89601" }} alt="per_img" src={data?.user_id?.profile_pic !== "" ? `${ImgUrl}${data?.user_id?.profile_pic}` : `${dummyProfile}`} sx={{ width: 56, height: 56 }} />}
                          action={<IconButton aria-label="settings"> <img src={SideLogo} alt="" height={35} /> </IconButton>}
                          title={data?.user_id?.full_name}
                          subheader={getDiff(data?.created_at)}
                          style={{ background: "#fff", border: "none" }}
                        />
                      </Link>
                      {data?.media_type === "image" ? (
                        <CardMedia component="img" image={data?.media !== "" ? `${ImgUrl}${data?.media}` : dummyRestro} alt="post_image" className="community_card" />
                      ) : (
                        data?.media.includes('mp4') ?
                          <video width="100%" controls>
                            <source src={ImgUrl + data?.media} type="video/mp4" />
                          </video>
                          :
                          <ReactHlsPlayer
                            src={`${ImgUrl}${data?.media}`}
                            autoPlay={false}
                            controls
                            className="community_card"
                          />
                      )}
                      <CardContent>
                        <Typography variant="body1" color="#000000"> {data?.title} </Typography>
                        <Typography variant="body2" color="#000000"> {data?.description} </Typography>
                      </CardContent>
                      {/* Like, Share, Comment */}
                      <CardActions disableSpacing style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }} >
                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer" }} >
                          <IconButton aria-label="add to favorites" onClick={() => toggleImage(data?._id)} >
                            <img src={data?.is_liked == 0 ? leafImg : greenLeafImg} alt="like_img" height={20} />&nbsp;
                            <Box onClick={() => handleShowRB(data?._id)}>
                              <Typography className="small_font" style={{ color: `${data?.is_liked == 0 ? "black" : "green"}`, }}>
                                {`${data?.total_likes} ${data?.total_likes > 1 ? "Likes" : "Like"}`}
                              </Typography>
                            </Box>
                          </IconButton>
                        </Box>
                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer", }} onClick={() => handleClickOpen(data?._id)} >
                          <IconButton aria-label="comment">
                            <img src={commentImg} alt="like_img" height={20} className="me-1" />
                            <Typography className="text-dark small_font"> {data?.total_comments} Comment </Typography>
                          </IconButton>
                        </Box>
                        <FacebookShareButton url={`${ImgUrl}${data?.media}`} quote={`"${data?.title}"`} hashtag={`#${data?.title}`} description={data?.description} className="Demo__some-network__share-button" >
                          <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer", }} >
                            <IconButton aria-label="share">
                              <img src={shareImg} alt="like_img" height={20} />
                              <Typography className="text-dark small_font">Share</Typography>
                            </IconButton>&nbsp;
                          </Box>
                        </FacebookShareButton>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })
            ) : (
              <Box style={{ display: "flex", color: "orange", margin: "20px 0 0 30px", }} > No Post to show </Box>)}
          </Grid>

        </Box>
      )}

      {/* Comment Box */}
      <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description" >
        <DialogTitle style={{ fontWeight: "bold" }}>
          <KeyboardBackspaceIcon onClick={handleClose} fontSize="large" cursor="pointer" />
          <span style={{ fontWeight: "bold", marginLeft: "30px" }}> Comments </span>
        </DialogTitle>
        <DialogContent>
          <Spin spinning={spinLoad}>
            <Typography style={{ height: "30vh", padding: "10px 0", overflowY: "scroll", }} >
              {getCommunityPostComment?.length > 0 ? getCommunityPostComment?.map((data, id) => {
                return (
                  <Box className="liked_main_style" key={id}>
                    <List sx={{ width: "100%", bgcolor: "background.paper", }} >
                      <ListItem secondaryAction={data?.user_id?._id == userData?._id && <IconButton edge="end" aria-label="delete" style={{ color: "orange" }} onClick={() => deleteComment(data)} > <DeleteIcon /> </IconButton>} >
                        <ListItemAvatar> <Avatar sx={{ width: 56, height: 56 }} style={{ marginRight: 10 }} src={data?.user_id?.profile_pic != '' ? `${ImgUrl}${data?.user_id?.profile_pic}` : dummyProfile} /> </ListItemAvatar>
                        <ListItemText style={{ wordBreak: 'break-all' }} primary={data?.user_id?.full_name} secondary={<>
                          <p style={{ padding: '0', margin: '0' }}> "{data?.comment}" </p>
                          <p style={{ padding: '0', margin: '0' }}>{getDiff(data?.created_at)}</p>
                        </>} />
                      </ListItem>
                    </List>
                  </Box>
                );
              }) : <Box><p>No coments Found!</p></Box>}
            </Typography>
            <Box style={{ display: "flex", border: "1px solid orange", margin: "5px 0 0 0", justifyContent: "space-between", padding: '5px 0' }} >
              <input autoFocus placeholder="Your Message..." size="large" value={message} onChange={(e) => setMessage(e.target.value)} style={{ outline: "none", border: "none", width: "100%", marginLeft: 10 }} />
              <Box onClick={() => addComment()} style={{ color: "orange", padding: '2px 5px', cursor: 'pointer' }} ><SendIcon /></Box>
            </Box>
          </Spin>
        </DialogContent>
      </Dialog>

      {/* Like Box */}
      <Modal show={show} onHide={handleCloseRB} backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title style={{ display: "flex", justifyContent: "center" }} > Likes </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {getCommunityPostLikesss?.data?.map((data, id) => (
            <Box className="liked_main_style" key={id}>
              <List sx={{ width: "100%", bgcolor: "background.paper", }} >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={data?.user_id?.profile_pic != '' ? `${ImgUrl}${data?.user_id?.profile_pic}` : dummyProfile} />
                  </ListItemAvatar>
                  <ListItemText
                    style={{ color: "orange", fontWeight: "bolder" }}
                    primary={data?.user_id?.full_name}
                    secondary={getDiff(data?.updated_at)}
                  />
                </ListItem>
              </List>
            </Box>
          ))}
        </Modal.Body>
      </Modal>

      {/* Search community modal */}
      <Modal show={searchingModal} onHide={() => setSearchingModal(false)} backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          {/* <Modal.Title style={{ display: "flex", justifyContent: "center" }} >Search Community </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Box style={{ display: "flex", border: "1px solid orange", margin: "5px 0 0 0", justifyContent: "space-between", padding: '5px 0' }} >
            <input value={search} onChange={(e) => handleSearchInput(e.target.value)} autoFocus placeholder="Search Community..." size="large" style={{ outline: "none", border: "none", width: "100%", marginLeft: 10 }} />
          </Box>
          <Spin spinning={searchLoader}>
            <div classname="" style={{ height: "50vh", overflow: "auto" }}>
              {usersData?.length > 0 && usersData?.map((item, indx) => {
                return (
                  <div onClick={() => navigate(`/c_profile/${item?._id}`)} key={indx} style={{ margin: "1rem 0 0.5rem 0", display: "flex", alignItems: "center", gap: "10px", cursor: "pointer" }}>
                    <Avatar src={`${ImgUrl}${item?.profile_pic}`} /> <Typography style={{ color: "orange", fontWeight: "500" }}>{item?.full_name}</Typography>
                  </div>
                )
              })}
            </div>
          </Spin>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CommunityCard;