import React, { useEffect, useState } from "react";
import { Paper, Grid, CardMedia, CardContent, Typography, ButtonGroup } from "@mui/material";
import { Box } from "@mui/system";
import "./DDating.css";
import "./Refinesearch.css";
import filterImg from "../../../images/datingasset/filtersmall.png";
import infoImg from "../../../images/datingasset/path.png";
import wrong from "../../../images/datingasset/wrong.png";
import correct from "../../../images/datingasset/correct.png";
import dummyProfile from "../../../images/CW/profile.jpeg";
import Spinner from "../../../constants/Spinner";
import Matching from "./Matching";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getHomeConnection, leftSwip, rightSwip } from "../../../redux/action/meet";
import { ImgUrl } from "../../../constants/const";
import { Button, Slider } from "antd";
import { Modal } from "react-bootstrap";
import TinderCard from "react-tinder-card";
import toast from "../../../constants/toast";
import { STORE_GENDER_DATA } from "../../../redux/constants";
import { storeCommonData } from "../../../redux/action/common";

const DDating = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isDisplay, setIsDisplay] = useState(false);
  const [itsMatch, setItsMatch] = useState(null);
  const [show, setShow] = useState(false);
  const [minAge, setMinAge] = useState('');
  const [maxAge, setMaxAge] = useState('');
  const [distance, setDistance] = useState('');
  const [active, setActive] = useState("");
  const [gender, setGender] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState('0');
  const [readMoreModal, setReadMoreModal] = useState(false);
  const [readMoreValue, setReadMoreValue] = useState('');

  const datingData = useSelector((state) => state.meet?.get_dating_card);
  const senderData = useSelector((state) => state?.dashboard?.datingData);
  // const genderData = useSelector((state) => state?.common?.gender_data?.payload);

  // console.log(genderData, '>>>>>>>>>><<<<<<<<genderData')

  const btnData = [
    {
      id: "1",
      title: "female",
    },
    {
      id: "2",
      title: "male",
    },
  ];

  const onClickHide = () => {
    setIsDisplay(!isDisplay);
  };

  useEffect(() => {
    if (datingData && loading) {
      setLoading(false);
    }
  }, [datingData]);

  const rejectUser = async (id) => {
    setLoading(true)
    const successCb = (resp) => {
      if (isFilterApplied == 0) {
        dispatch(getHomeConnection());
      } else {
        let data = {
          gender,
          min_age: minAge,
          max_age: maxAge,
          user_lat: lat,
          user_long: lng,
          miles: distance,
        }
        const successCb = (resp) => {
          setShow(false);
        }
        const failureCb = (resp) => {
          setShow(false);
        }
        dispatch(getHomeConnection(data, successCb, failureCb));
      }
      setLoading(false)
      toast.normal("Declined")
    };
    let data = {
      rejected_to: id,
    };
    dispatch(leftSwip(successCb, data));
  };

  const acceptUser = async (receiver) => {
    setLoading(true)
    const successCb = (resp) => {
      if (isFilterApplied == 0) {
        dispatch(getHomeConnection());
      } else {
        let data = {
          gender,
          min_age: minAge,
          max_age: maxAge,
          user_lat: lat,
          user_long: lng,
          miles: distance,
        }
        const successCb = (resp) => {
          setShow(false);
        }
        const failureCb = (resp) => {
          setShow(false);
        }
        dispatch(getHomeConnection(data, successCb, failureCb));
      }
      setLoading(false)
      toast.success(resp?.message)
      if (resp.status && resp?.data?.status == 'connected') {
        let d = {
          sender_data: senderData, receiver_data: receiver
        }
        setItsMatch(d)
      }
    };
    let data = {
      requested_to: receiver?._id,
    };
    dispatch(rightSwip(successCb, data));
  };

  useEffect(() => {
    dispatch(getHomeConnection());
  }, []);

  // Tinder card function start************************
  let isSwiped = false;
  const swiped = (direction, receiver) => {
    if (!isSwiped) {
      isSwiped = true;
      if (direction == 'left') {
        const successCb = (resp) => {
          toast.normal("Declined")
          return resp;
        };
        let data = {
          rejected_to: receiver?._id,
        };
        dispatch(leftSwip(successCb, data));
      } else {
        // console.log("testttt", receiver?._id, Math.random())
        const successCb = (resp) => {
          toast.success(resp?.message)
          if (resp.status && resp?.data?.status == 'connected') {
            let d = { sender_data: senderData, receiver_data: receiver }
            setItsMatch(d)
          }
        };
        let data = {
          requested_to: receiver?._id,
        };
        dispatch(rightSwip(successCb, data));
      }
    }
  };

  // Reset the flag for the next swipe
  const outOfFrame = () => {
    isSwiped = false;
    getHomeConnection();
  };
  // Tinder card function End********************

  const refresh = () => {
    window.location.reload(true)
  }

  // For filter
  const handleCloseFilter = () => {
    setShow(false);
  };

  const handleClickOpenFilter = (data) => {
    setShow(true);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
    });
  }, []);

  const resetSetting = () => {
    setIsFilterApplied(0)
    window.location.reload();
  }

  const filterSearch = () => {
    setLoading(true)
    setIsFilterApplied(1)

    let data = {
      gender,
      min_age: minAge,
      max_age: maxAge,
      user_lat: lat,
      user_long: lng,
      miles: distance,
    }
    const successCb = (resp) => {
      setLoading(false)
      setShow(false);
    }
    const failureCb = (resp) => {
      setLoading(false)
      setShow(false);
    }
    dispatch(getHomeConnection(data, successCb, failureCb));
  }

  const onChangeAge = (value) => {
    setMinAge(value[0])
    setMaxAge(value[1])
  };

  const onChangeDistance = (value) => {
    setDistance(value)
  };

  function truncateText(text) {
    if (text.length > 15) {
      return text.slice(0, 15);
    }
    return text;
  }

  const readmoreModal = (val) => {
    setReadMoreValue(val)
    setReadMoreModal(true)
  }

  const handleGenderSelect = (button) => {
    setActive(button?.id);
    setGender(button?.title)
    // dispatch(storeCommonData({
    //   payload: { gender: button?.title },
    // }));
  }

  return (
    <div>
      {loading ? <Spinner /> : (
        itsMatch ? <Matching itsMatch={itsMatch} /> :
          <Box >
            <Box className="header_text_style mb-3">
              <Typography className="common_medium_font"> Dating</Typography>
              <img className="image-filter" src={filterImg} alt="filter" onClick={() => handleClickOpenFilter()} />
            </Box>

            <div className="row d-none d-md-block d-lg-block">
              <div className="col-md-12 col-lg-10 mx-auto">
                {datingData?.data?.length > 0 ? datingData?.data?.map((data, id) => {
                  const truncatedBio = truncateText(data?.bio);
                  return (
                    <TinderCard
                      key={id}
                      onSwipe={(dir) => swiped(dir, data)}
                      onCardLeftScreen={outOfFrame}
                    >
                      <div className="tinder-div">
                        <div className="tinder-card">
                          <div className="row">
                            <div className="col-md-6 col-lg-6">
                              <div className="tinder-card-img"
                                style={{
                                  backgroundImage: `url(${data?.profile_pic ? ImgUrl + data?.profile_pic : dummyProfile})`
                                }}
                              />
                            </div>
                            <div className="col-md-6 col-lg-6">
                              <Link to={`/cc_profile/${data?._id}`} style={{ color: "orange" }} >
                                <Typography className="tinder-font text-copied-disable">
                                  {data?.full_name} &nbsp;
                                  <img src={infoImg} alt="infoimage" height={20} />
                                </Typography>
                              </Link>
                              <Typography className="tinder-about-font mt-2 text-copied-disable">About me</Typography>
                              <div className="d-flex">
                                <Typography className="text-copied-disable"
                                  dangerouslySetInnerHTML={{ __html: data?.bio ? truncatedBio : "----" }}
                                />
                                {data?.bio ?
                                  <Typography className="text-copied-disable" onClick={() => readmoreModal(data?.bio)} style={{ cursor: "pointer", color: "orange", fontWeight: "600" }}>
                                    ....Read More
                                  </Typography> : ""}
                              </div>
                              <Typography className="text-copied-disable" style={{ fontWeight: "600", fontSize: "1.2rem", marginTop: "1rem" }}>Username</Typography>
                              <Typography className="text-copied-disable" >{data?.username != "" ? data?.username : "---"}</Typography>
                              <div className="swipe-btn">
                                <div className="mt-5">
                                  <img className="text-copied-disable tinder_btn" src={wrong} alt="wrong" style={{ cursor: "pointer" }}
                                    onClick={() => { rejectUser(data?._id); onClickHide() }}
                                  />
                                  <img src={correct} alt="correct" className="ms-4 text-copied-disable tinder_btn" style={{ cursor: "pointer" }}
                                    onClick={() => acceptUser(data)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TinderCard>
                  )
                }) :
                  <>
                    <Box
                      style={{ display: "flex", justifyContent: "center", flexDirection: 'column', alignItems: "center", color: "orange", height: "70vh" }}>
                      <Button onClick={refresh} className="dating-btn-refresh">Refresh</Button>
                      <p>No data Found!</p>
                    </Box>
                  </>
                }
              </div>
            </div>
            {/* {datingData?.data?.length > 0 ? (
                datingData?.data?.map((data, id) => {
                  return (
                    <>
                      <TinderCard className="swipe" id="tinder--card" key={id} preventSwipe={["up", "down"]} onSwipe={(dir) => swiped(dir, data)} onCardLeftScreen={() => outOfFrame()} >
                        <Box className="paper-style-box">
                          <Paper elevation={0} style={{ borderRadius: "1em", border: "none" }}>
                            <Grid container>
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <CardMedia component="img" className="card-media-image" style={{ objectFit: "fill" }}
                                  image={data?.profile_pic != "" ? `${ImgUrl}${data?.profile_pic}` : `${dummyProfile}`}
                                  alt={data?.full_name}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} lg={6} style={{}} >
                                <Box>
                                  <CardContent style={{}}>
                                    <Link to={`/cc_profile/${data?._id}`} style={{ color: "orange" }} >
                                      <Typography gutterBottom variant="h4" style={{ font: "normal normal bold Montserrat", fontSize: '42px' }}>
                                        {data?.full_name} &nbsp;
                                        <img src={infoImg} alt="infoimage" height={20} />
                                      </Typography>
                                    </Link>
                                    <Box variant="h6" style={{ font: "normal normal bolder  Montserrat", fontWeight: "600", fontSize: '32px' }}>
                                      About me
                                    </Box>
                                    <div dangerouslySetInnerHTML={{ __html: data?.bio !== "" ? data?.bio : "----" }} />
                                    <Box variant="h6" style={{ font: "normal normal bolder  Montserrat", fontWeight: "600", fontSize: '22px', padding: '10px 0' }}>
                                      Username
                                    </Box>
                                    <Typography variant="body2" color="text.secondary" fontSize="16px">
                                      {data?.username != "" ? data?.username : "---"}
                                    </Typography>
                                  </CardContent>
                                </Box>
                                <Box className="spacingOnLarge" />
                                <Box style={{ display: "flex", justifyContent: "space-around" }} className="buttonStyle_sm">
                                  <Box onClick={() => { rejectUser(data?._id); onClickHide() }} >
                                    <img src={wrong} alt="wrong" style={{ maxWidth: "70%", cursor: "pointer" }} />
                                  </Box>
                                  <Box onClick={() => acceptUser(data)} id="love">
                                    <img src={correct} alt="correct" style={{ maxWidth: "70%", cursor: "pointer" }} />
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Box>
                      </TinderCard>
                    </>
                  );
                })
              ) : (
                <>
                  <Box
                    style={{ display: "flex", justifyContent: "center", flexDirection: 'column', alignItems: "center", color: "orange", margin: "20px 0 0 30px", height: "70vh" }}>
                    <Button onClick={refresh} className="dating-btn-refresh">Refresh</Button>
                    <p>No data Found!</p>
                  </Box>
                </>
              )} */}

            <div className="row d-block d-md-none d-lg-none">
              <div className="col-12">
                {datingData?.data?.length > 0 ? datingData?.data?.map((data, id) => {
                  return (
                    <TinderCard
                      key={id}
                      onSwipe={(dir) => swiped(dir, data)}
                      onCardLeftScreen={outOfFrame}
                    >
                      <div className="tinder-div">
                        <div className="tinder-card p-0">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="tinder-card-img" style={{
                                backgroundImage: `url(${data?.profile_pic ? ImgUrl + data?.profile_pic : dummyProfile})`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                height: '320px',
                              }} />
                            </div>
                            <Link to={`/cc_profile/${data?._id}`}>
                              <Typography style={{ position: 'absolute', bottom: 0, margin: 5, fontWeight: 'bold', color: '#fff' }}>{data?.full_name} <img src={infoImg} alt="infoimage" height={20} />
                              </Typography>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </TinderCard>
                  );
                })
                  : <Box className="noDisplay_onLarge" style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "orange", margin: "20px 0 0 30px", height: "80vh" }}>
                    <Button onClick={refresh} className="dating-btn-refresh">Refresh</Button>
                  </Box>
                }
              </div>
            </div>

          </Box>
      )}

      <Modal show={show} onHide={handleCloseFilter}>
        <Modal.Header closeButton >
          <Typography variant="h4" className="refine-text-style">Refine Search </Typography>
        </Modal.Header>
        <Modal.Body>
          <Button style={{ color: "#000", background: "none", cursor: "pointer", border: 'none', outline: 'none', float: 'right' }} onClick={() => resetSetting()}>Reset</Button>
          {/* Gender */}
          <Box style={{ display: "flex", justifyContent: "cenetr", alignItems: "center", flexDirection: "column", padding: '10px 0', marginTop: 10 }} >
            <Box style={{ width: '100%' }}>
              <Typography variant="body2">Gender</Typography>
              <br />
              <ButtonGroup>
                {btnData.map((button, i) => (
                  <Button key={i} className="gender-button " onClick={() => handleGenderSelect(button)} style={{ backgroundColor: active === button.id ? "#F8931F" : "#fff" }} >
                    <Typography variant="" style={{ color: active === button.id ? '#fff' : "#000" }}>{button.title}</Typography>
                  </Button>
                ))}
              </ButtonGroup>
            </Box>
          </Box>
          {/* Locations */}
          <Box style={{ marginTop: 10 }}>
            <Typography variant="body2">Location</Typography>
            <Box sx={{ m: 3 }} />
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="">Miles Away</Typography>
              <Typography variant="">{distance} Miles</Typography>
            </Box>
            <Slider min={1} defaultValue={distance} onChange={onChangeDistance} />
            <Box sx={{ m: 5 }} />
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="">Age Range</Typography>
              <Typography variant="">{minAge} - {maxAge}</Typography>
            </Box>
            <Slider range defaultValue={[minAge, maxAge]} onChange={onChangeAge} min={18} />
            <Box className='text-center mt-5' >
              <Button loading={loading} className="common_btn" onClick={filterSearch}> Search </Button>
            </Box>
          </Box>
        </Modal.Body>
      </Modal>

      {/* Read More Modal */}
      <Modal show={readMoreModal}>
        <div className="p-3">
          <Typography className="tinder-about-font text-center mb-3">About Me</Typography>
          <Typography className="tinder-sm-font" dangerouslySetInnerHTML={{ __html: readMoreValue }} />
          <div className="btn_div mt-5 text-center">
            <Button className="common_btn" onClick={() => setReadMoreModal(false)}>Cancel</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default DDating;