import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { ImgUrl } from "../../../constants/const";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../constants/Spinner";
import OtherUserGallery from "./OtherUserGallery";
import OtherUserRecipe from "./OtherUserRecipe";
import OtherUserCommunity from "./OtherUserCommunity";
import { getOtherUserDetail, getOtherUserDetails } from "../../../redux/action/recipe";
import dummyPic from "../../../images/CW/profile.jpeg";
import { acceptRejectFollowRequest } from "../../../redux/action/community";
import toast from '../../../constants/toast'

const NavigationButton = [
  {
    id: "1",
    title: "Uploads",
    fun: "U",
  },
  {
    id: "2",
    title: "Recipes",
    fun: "R",
  },
  {
    id: "3",
    title: "Community",
    fun: "C",
  },
];

const UserProfilePR = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState("U");
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState("1");
  const [otherUserData, setOtherUserData] = useState("");

  let { id } = useParams();
  // const otherUserData = useSelector((state) => state.recipe?.other_user_detail?.data);
  const htmlString = otherUserData?.bio;
  const handleClickOpenAccept = async (status, _id) => {
    if (status === "accepted") {
      setLoading(true);
      let token = localStorage.getItem("token");
      const successCb = (resp) => {
        if (resp.status === true) {
          setLoading(false);
        }
      };
      dispatch(acceptRejectFollowRequest(token, { status, _id }, successCb));
    } else {
      setLoading(true);
      let token = localStorage.getItem("token");
      const successCb = (resp) => {
        toast.success(resp?.message)
        if (resp.status === true) {
          setLoading(false);
        }
      };
      dispatch(acceptRejectFollowRequest(token, { status, _id }, successCb));
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    let other_user_id = id;
    dispatch(getOtherUserDetail({ other_user_id }, token));
  }, []);


  // useEffect(() => {
  //   if (otherUserData && loading) {
  //     setLoading(false);
  //   }
  // }, [otherUserData]);

  const getUserData = () => {
    let token = localStorage.getItem("token");
    setLoading(true)
    const success = (res) => {
      setLoading(false)
      setOtherUserData(res?.data)
    }
    const failure = (res) => {
      setLoading(false)
    }
    let data = {
      other_user_id: id,
    }
    dispatch(getOtherUserDetails(data, success, failure))
  }

  useEffect(() => {
    getUserData();
  }, [])

  return (
    <>
      {loading ? (<Spinner />) : (
        <Box>
          <Box style={{ marginBottom: "20px" }}>
            <Box className="profile__text__btn" style={{ margin: "0 0 20px 0" }} > <Typography style={{ fontSize: "26px", fontFamily: "Poppins", fontWeight: "600", }} > <strong style={{ fontWeight: "600" }}>Profile</strong> </Typography> </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <Box style={{ display: "flex", flexDirection: "row", boxSizing: "border-box", }} className="profile_image_ansh" >
                  <img className="imgSection--profile" src={otherUserData?.profile_pic !== "" ? `${ImgUrl}${otherUserData?.profile_pic}` : dummyPic} alt="profile_img" style={{ borderRadius: "50%" }} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={9} style={{ display: "grid", alignItems: "center" }}>
                <Box>
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "10px 0px" }} >
                    <Typography> <strong style={{ fontWeight: "600" }}>Name</strong> </Typography>
                    <Typography> <strong style={{ fontWeight: "600" }}> {otherUserData?.full_name ? otherUserData?.full_name : "---"} </strong> </Typography>
                  </Box>
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "10px 0px", }} >
                    <Typography> <strong style={{ fontWeight: "600" }}>Username</strong> </Typography>
                    <Typography> <strong style={{ fontWeight: "600" }}> {otherUserData?.email ? otherUserData?.email : "---"} </strong> </Typography>
                  </Box>
                  <Button style={{ padding: "0 2rem", fontWeight: "bold", background: "green", color: "#f7f7f7", height: "45px", marginRight: "10px", borderRadius: "12px", }} onClick={() => handleClickOpenAccept("accepted", otherUserData?._id)} > Accept </Button>
                  <Button style={{ padding: "0 2rem", fontWeight: "bold", background: "#eb5331", color: "#f7f7f7", height: "45px", borderRadius: "12px", }} onClick={() => handleClickOpenAccept("rejected", otherUserData?._id)} > Decline </Button>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Typography style={{ margin: "10px 10px 10px 0" }}> <strong style={{ fontWeight: "600", fontSize: "22px" }}> About Me </strong> </Typography>
              {otherUserData?.bio?.length > 0 ?
                <div style={{ maxHeight: '8rem', overflowY: 'auto' }} dangerouslySetInnerHTML={{ __html: htmlString }} />
                : "---"}
              {/* <Typography> {otherUserData?.bio ? otherUserData?.bio : "---"} </Typography> */}
            </Box>

            <Box className="d-flex my-4">
              {NavigationButton.map((data, key) => {
                return (
                  <Button className="common_btn me-2" onClick={() => { setActiveIndex(data.id); setActive(data.fun); }} style={{ backgroundColor: activeIndex === data.id ? "#F8931F" : "#fff", borderRadius: '50px' }} key={key} >
                    <Typography style={{ color: activeIndex === data.id ? "#fff" : "#F8931F", }} > {data.title} </Typography>
                  </Button>
                );
              })}
            </Box>
            
            {active === "U" && <OtherUserGallery />}
            {active === "R" && <OtherUserRecipe />}
            {active === "C" && <OtherUserCommunity />}
          </Box>
        </Box>
      )}
    </>
  );
};
export default UserProfilePR;