import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardMedia, Divider, Grid, Typography } from "@mui/material";
import ImgLogo from "../../../images/Group 33033.png";
import remove from "../../../images/CW/removeIcon.png";
import info from "../../../images/CW/Path 22517.png";
import chat from "../../../images/CW/chatbox-ellipses.png";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "antd";
import VMDLogo from "../../../images/Group 33033@2x.png";
import { List } from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import CallIcon from "@mui/icons-material/Call";
import SmsIcon from "@mui/icons-material/Sms";
import "../CConnection/CConnection.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import dummyProfile from "../../../images/CW/profile.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { getMyConnection } from "../../../redux/action/community";
import { ImgUrl } from "../../../constants/const";
import { deleteMyConnection } from "../../../redux/action/meet";
import { db } from "../../../firebase";
import Spinner from "../../../constants/Spinner";
import moment from "moment";

const DDatingCon = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [data1, setData1] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const userData = useSelector((state) => state.dashboard?.user_data);
  const navigate = useNavigate();

  const handleClickOpen = (_id) => {
    setOpen(true);
    setData1(_id);
  };

  const showModal = (data) => {
    navigate(`/cc_profile/${data}`)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // API Integration
  const datingConnectionData = useSelector((state) => state?.community?.get_connection);

  const handleCloseAccept = () => {
    setOpen(false);
    setLoading(true);
    let token = localStorage.getItem("token");
    const successCb = (resp) => {
      if (resp.status === true) {
        dispatch(getMyConnection(successCb));
        setLoading(false);
      }
    };
    let connection_id = data1;
    dispatch(deleteMyConnection(token, { connection_id }, successCb));
  };

  useEffect(() => {
    const successCb = (resp) => {
    };
    dispatch(getMyConnection(successCb));
  }, []);

  useEffect(() => {
    if (datingConnectionData && loading) {
      setLoading(false);
    }
  }, [datingConnectionData]);

  const getRoomName = (data) => {
    let { sender_id, receiver_id } = data
    let temp = [sender_id, receiver_id].sort();
    let roomname = temp.join('_');
    return roomname;
  };

  const manageChat = async (data) => {
    // data?.sender_data?._id + "_" + data?.receiver_data?._id
    let rk = getRoomName({ sender_id: data?.sender_data?._id?.toString(), receiver_id: data?.receiver_data?._id?.toString() })
    // let senderSideKey = [user_data?._id,id].sort((a,b)=>a-b).join('_')
    let senderSideKey = rk
    db.collection("Users").doc(data?.sender_data?._id?.toString()).collection("Chats").doc(senderSideKey?.toString()).get().then((senderKeyRef) => {
      if (!senderKeyRef.exists) {
        db.collection("Users").doc(data?.sender_data?._id?.toString()).collection("Chats").doc(senderSideKey?.toString()).set({
          date: moment().format("MM/DD/YYYY"),
          key: senderSideKey?.toString(),
          lastMsg: "start chatting...",
          lastMsgTime: new Date().getTime(),
          to: data?.receiver_data?.full_name,
          toProfileImg: data?.receiver_data?.profile_pic,
          toUid: data?.receiver_data?._id,
          type: "TEXT",
          unreadCount: 0
        })
      }
    })
    db.collection("Users").doc(data?.receiver_data?._id?.toString()).collection("Chats").doc(senderSideKey?.toString()).get().then((receiverKeyRef) => {
      if (!receiverKeyRef.exists) {
        db.collection("Users").doc(data?.receiver_data?._id?.toString()).collection("Chats").doc(senderSideKey?.toString()).set({
          date: moment().format("MM/DD/YYYY"),
          key: senderSideKey?.toString(),
          lastMsg: "start chatting...",
          lastMsgTime: new Date().getTime(),
          to: data?.sender_data?.full_name,
          toProfileImg: data?.sender_data?.profile_pic,
          toUid: data?.sender_data?._id,
          type: "TEXT",
          unreadCount: 0
        })
      }
    })
    db.collection("Chats").doc(senderSideKey?.toString()).get().then((ref) => {
      if (!ref.exists) {
        db.collection("Chats").doc(senderSideKey?.toString()).set({
          from: data?.sender_data?.full_name,
          fromUid: data?.sender_data?._id,
          key: senderSideKey?.toString(),
          msg: "Hi",
          time: moment().format("h:mma"),
          timestamp: moment().unix(),
          to: data?.receiver_data?.full_name,
          toProfileImg: data?.receiver_data?.profile_pic,
          toUid: data?.receiver_data?._id,
          type: "TEXT"
        })
      }
    })
    setLoading(true)
    setTimeout(() => {
      sessionStorage.setItem("activeChat", data?.receiver_data?._id)
      setLoading(false)
      navigate("/inbox")
    }, 2000)
  }

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div>
            <Typography className="common_medium_font mb-3">Dating Connection</Typography>
            <Box style={{ maxHeight: "90vh", overflowY: "auto" }}>
              <Grid container spacing={2}>
                {datingConnectionData?.data?.length > 0 ? (
                  datingConnectionData?.data?.map((data, id) => {
                    return (
                      <>
                        {data?.requested_to?._id === userData?._id ? (
                          <Grid item xs={12} sm={6} md={4} lg={2} key={id}>
                            <Card style={{ boxSizing: "border-box", position: "relative", boxShadow: "9px 9px 31px -10px rgba(0,0,0,0.75)", borderRadius: "12px", cursor: "pointer", border: "2px solid #F8931F", height: 200, background: '#F8931F' }}>
                              <CardMedia component="img" alt="vegan_meet_daily" style={{ height: '85%' }} image={data?.requested_by?.profile_pic !== "" ? `${ImgUrl}${data?.requested_by?.profile_pic}` : `${dummyProfile}`} onClick={() => showModal(data?.requested_by?._id)} />
                              <Typography className="pr-dating-logo-text-icon-style">
                                <img src={ImgLogo} style={{ height: "35px", cursor: "pointer" }} alt="logo-img" />
                              </Typography>
                              <Typography className="pr-dating-remove-icon-style">
                                <img src={remove} style={{ height: "25px", cursor: "pointer" }} alt="logo-img" onClick={() => handleClickOpen(data?._id)} />
                              </Typography>
                              <Typography
                                style={{ fontSize: "19px" }}
                                className="pr-dating-text-icon-style">
                                <Link style={{ color: "#fff", fontWeight: '600' }} to={`/cc_profile/${data?.requested_by?._id}`}>{data?.requested_by?.full_name}&nbsp;
                                  <img src={info} style={{ height: "13px", cursor: "pointer", }} alt="logo-img" />
                                </Link>
                              </Typography>
                              <Typography className="pr-dating-chat-icon-style">
                                <img onClick={() => manageChat({ sender_data: userData, receiver_data: data?.requested_by })} src={chat} style={{ height: "22px", cursor: "pointer" }} alt="logo-img" />
                              </Typography>
                            </Card>
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={6} md={4} lg={2} key={id}>
                            <Card style={{ boxSizing: "border-box", position: "relative", boxShadow: "9px 9px 31px -10px rgba(0,0,0,0.75)", borderRadius: "12px", cursor: "pointer", border: "2px solid #F8931F", height: 200, background: '#F8931F' }}>
                              <CardMedia component="img" alt="vegan_meet_daily" style={{ height: '85%' }} image={data?.requested_to?.profile_pic !== "" ? `${ImgUrl}${data?.requested_to?.profile_pic}` : `${dummyProfile}`} onClick={() => showModal(data?.requested_by?._id)} />
                              <Typography className="pr-dating-logo-text-icon-style">
                                <img src={ImgLogo} style={{ height: "35px", cursor: "pointer" }} alt="logo-img" />
                              </Typography>
                              <Typography className="pr-dating-remove-icon-style">
                                <img src={remove} style={{ height: "25px", cursor: "pointer" }} alt="logo-img" onClick={() => handleClickOpen(data?._id)} />
                              </Typography>
                              <Typography style={{ fontSize: "19px" }} className="pr-dating-text-icon-style">
                                <Link style={{ color: "#fff", fontWeight: '600' }} to={`/cc_profile/${data?.requested_to?._id}`}>{data?.requested_to?.full_name}&nbsp;
                                  <img src={info} style={{ height: "13px", cursor: "pointer", }} alt="logo-img" />
                                </Link>
                              </Typography>
                              <Typography className="pr-dating-chat-icon-style">
                                <img onClick={() => manageChat({ sender_data: userData, receiver_data: data?.requested_to })} src={chat} style={{ height: "22px", cursor: "pointer", }} alt="logo-img" />
                              </Typography>
                            </Card>
                          </Grid>
                        )}
                      </>
                    );
                  })
                ) : (<Box style={{ display: "flex", color: "orange", margin: "20px 0 0 30px" }}> No Connection to show </Box>)}
              </Grid>
            </Box>
            <Modal
              visible={isModalVisible}
              onCancel={handleCancel}
              footer={null}
              className="popup_section">
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}>
                <img src={VMDLogo} height={55} alt="vmd_logo" />
              </Typography>
              <Link style={{ color: "#000" }} to="/video_call">
                <List
                  style={{
                    display: "flex",
                    gap: "10px",
                    cursor: "pointer",
                    alignItems: "center",
                    marginTop: "10px",
                    marginBottom: "-20px",
                  }}>
                  <VideocamIcon />
                  <Typography>Video Call</Typography>
                </List>
              </Link>
              <Divider color="#000" />
              <Link style={{ color: "#000" }} to="/call">
                <List
                  style={{
                    display: "flex",
                    gap: "10px",
                    cursor: "pointer",
                    alignItems: "center",
                  }}>
                  <CallIcon />
                  <Typography>Voice Call</Typography>
                </List>
              </Link>
              <Divider color="#000" />
              <Link style={{ color: "#000" }} to="/inbox">
                <List
                  style={{
                    display: "flex",
                    gap: "10px",
                    cursor: "pointer",
                    alignItems: "center",
                  }}>
                  <SmsIcon />
                  <Typography>Chat</Typography>
                </List>
              </Link>
            </Modal>
            {/* Delete Connection Popup */}
            <Dialog
              fullScreen={fullScreen}
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title">
              <DialogContent>
                <DialogContentText
                  style={{
                    color: "#000",
                    fontFamily: "Montserrat",
                    fontWeight: "normal",
                  }}>
                  Are you sure you want to unfriend this connection?
                </DialogContentText>
              </DialogContent>
              <DialogActions
                style={{ display: "flex", justifyContent: "space-between" }}>
                <Button onClick={handleClose} style={{ color: "#000" }}>
                  <strong>No</strong>
                </Button>
                <Button
                  onClick={() => handleCloseAccept("reject")}
                  style={{ color: "#F8931F" }}>
                  <strong>Yes</strong>
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
      )}
    </>
  );
};
export default DDatingCon;