import React, { useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { getHelp } from "../../../redux/action/dashboard";

const Help = () => {
  const dispatch = useDispatch();
  const getHelpData = useSelector((state) => state?.dashboard?.help?.data);

  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle
        adsbygoogle.push({})
      } catch (e) {
        console.error('error', e)
      }
    }

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd()
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval)
      }
    }, 300)

    dispatch(getHelp())
    return () => {
      clearInterval(interval)
    }
  }, [])
  return (
    <>
      <Box>
        {getHelpData?.map((data, index) => {
          return (
            <Accordion style={{ margin: "0", padding: "15px" }} key={data?._id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" >
                <Typography style={{ fontWeight: "bold" }}>{(index + 1) + ". " + data?.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{data?.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
      {/* <GoogleAdSense
        html={
          '<ins class="adsbygoogle"\n' +
          'style="display:block"\n' +
          'data-ad-client="ca-pub-5665970448524979"\n' +
          'data-ad-slot="6602811062"\n' +
          'data-full-width-responsive="true"></ins>'
        }
      /> */}
    </>
  );
};
export default Help;