import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Button, Input, Spin } from "antd";
import "./Account.css";
import toast from "../../../constants/toast";
import { useDispatch, useSelector } from "react-redux";
import { userChangePassword, setCallResponse } from "../../../redux/action/auth";
import { useNavigate } from "react-router-dom";
// To Show Password
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { LOGOUT } from "../../../redux/constants";
import { db } from "../../../firebase";

const Account = () => {
  const call_response = useSelector((state) => state.auth.call_response);
  const dispatch = useDispatch();
  const [old_password, setOld_password] = useState("");
  const [new_password, setNew_password] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [loading, setLoading] = useState(false);

  const user_data = useSelector((state) => state.dashboard.user_data);

  const changePassword = async () => {
    setLoading(true)
    db.collection("Users").doc(user_data?._id).update({
      online: false
    })
    if (new_password === retypePassword) {
      const successCb = (resp) => {
        toast.success(resp?.message);
        setLoading(false);
        setOld_password("");
        setNew_password("");
        setRetypePassword("");
      };
      const failureCb = (resp) => {
        toast.error(resp?.message);
        setLoading(false)
      }
      dispatch(userChangePassword({ old_password, new_password }, successCb, failureCb));
    } else {
      toast.error(`Both password doesn't match`);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (call_response) {
  //     if (call_response.status) {
  //       setTimeout(() => {
  //         setOld_password("");
  //         setNew_password("");
  //         setRetypePassword("");
  //       }, [500]);
  //       dispatch(setCallResponse(null));
  //     } else {
  //       setLoading(false);
  //       toast.error(call_response?.message);
  //     }
  //     dispatch(setCallResponse(null));
  //   }
  // }, [call_response]);

  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle
        adsbygoogle.push({})
      } catch (e) {
        console.error('error', e)
      }
    }

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd()
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval)
      }
    }, 300)

    return () => {
      clearInterval(interval)
    }
  }, [])
  return (
    <>
      <Box className="account-setting-styling">
        <Typography className="common_medium_font"> Account settings </Typography>
        <Typography className="common_medium_font text-center"> Change Password </Typography>
        <div className="row">
          <div className="col-lg-7 mx-auto mt-4">
            <div initialValues={{ remember: false }} autoComplete="off">
              <Input.Password autoFocus placeholder="Current Password" className="common_input_fields borderd" value={old_password} onChange={(e) => setOld_password(e.target.value)}
                iconRender={(visible) => (visible ? <VisibilityIcon style={{ cursor: 'pointer', color: 'gray' }} /> : <VisibilityOffIcon style={{ cursor: 'pointer', color: 'gray' }} />)} />
              <Input.Password placeholder="New Password" minLength={6} className="common_input_fields" value={new_password} onChange={(e) => setNew_password(e.target.value)}
                iconRender={(visible) => (visible ? <VisibilityIcon style={{ cursor: 'pointer', color: 'gray' }} /> : <VisibilityOffIcon style={{ cursor: 'pointer', color: 'gray' }} />)} />
              <Input.Password placeholder="Confirm Password" minLength={6} className="common_input_fields" value={retypePassword} onChange={(e) => setRetypePassword(e.target.value)}
                iconRender={(visible) => (visible ? <VisibilityIcon style={{ cursor: 'pointer', color: 'gray' }} /> : <VisibilityOffIcon style={{ cursor: 'pointer', color: 'gray' }} />)} />
              <div className="text-center">
                <Button loading={loading} className="common_btn" onClick={changePassword}> Save </Button>
              </div>
            </div>
          </div>
        </div>
        {/* <GoogleAdSense
          html={
            '<ins class="adsbygoogle"\n' +
            'style="display:block"\n' +
            'data-ad-client="ca-pub-5665970448524979"\n' +
            'data-ad-slot="6602811062"\n' +
            'data-full-width-responsive="true"></ins>'
          }
        /> */}
      </Box>
    </>
  );
};

export default Account;
