import React, { useState, useEffect } from "react";
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import CallEndIcon from '@mui/icons-material/CallEnd';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sendChatNotification } from "../../../redux/action/auth";
import { db } from "../../../firebase"
import { load, manageMic } from "./voiceCall"
import { ImgUrl } from "../../../constants/const";
import DummyProfile from "../../../images/CW/profile.jpeg"
import "./callVideo.css";

const Call = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  let payload = null
  const [audio, setAudio] = useState(true)
  const [timer, setTimer] = useState(false)
  const [seconds, setSeconds] = useState(0)
  const [callPayload, setCallPayload] = useState(null)
  const timerStatus = useSelector((state) => state.common.timer_status)
  let callInterval = null

  const showTimer = () => {
    setTimer(true)
    let secs = seconds
    callInterval = setInterval(() => {
      secs++
      setSeconds(secs)
    }, 1000)
  }

  const time_convert = (num) => {
    var minutes = Math.floor(num / 60);
    if (minutes <= 9) {
      minutes = "0" + minutes
    }
    var seconds = num % 60;
    if (seconds <= 9) {
      seconds = "0" + seconds
    }
    return minutes + ":" + seconds;
  }

  // for remote user
  const leaveCall = () => {
    sessionStorage.removeItem("start-timer")
    clearInterval(callInterval)
    setTimer(null)
    setSeconds(0)
    let notifPayload = {
      action: "end-call"
    }
    let data = {
      user_id: callPayload?.sender_id,
      payload: notifPayload
    }
    const cb = () => {
      db.collection('channel').doc(callPayload?.sender_id ?? callPayload?.channel).set({ join: false }, { merge: true })
      sessionStorage.removeItem("callPayload")
      navigate('/inbox')
      window.location.reload()
    }
    dispatch(sendChatNotification(data, cb))
    dispatch(sendChatNotification({ user_id: callPayload?.receiver_id, payload: notifPayload }, cb))
  }

  const handleMic = (state) => {
    setAudio(!state)
    manageMic(audio)
  }

  const renderName = () => {
    let p = sessionStorage.getItem("callPayload") ? JSON.parse(sessionStorage.getItem("callPayload")) : null
    return p?.call_type == "Outgoing" ? p?.receiver_name ?? "unknown" : p?.call_type == "Incoming" ? p?.sender_name ?? "unknown" : "unknown"
  }

  const renderImage = () => {
    let p = sessionStorage.getItem("callPayload") ? JSON.parse(sessionStorage.getItem("callPayload")) : null
    let img = p?.call_type == "Outgoing" ? p?.receiver_profile ?? "unknown" : p?.call_type == "Incoming" ? p?.sender_image ?? "unknown" : "unknown"
    if (img == "unknown" || img == "") {
      return DummyProfile
    } else {
      return img = ImgUrl + img
    }
  }

  useEffect(() => {
    if(timerStatus) {
      showTimer()
    }
  }, [timerStatus])

  useEffect(() => {
    payload = JSON.parse(sessionStorage.getItem("callPayload"))
    setCallPayload(payload)
    load()
  }, [])

  return (
    <>
      <ArrowBackIcon className="m-4 mb-0" style={{ cursor: "pointer" }} onClick={() => leaveCall()} />
      <div class="row m-4 d-flex align-items-end" style={{ background: "#7db886", height: "65vh", border: "2px solid #ccc", borderRadius: "5px", overflow: "hidden" }}>
        <div className="col-12 mt-auto mb-4 text-center">
          <img src={renderImage()} className="img-thumbnail mb-5" style={{ maxHeight: "150px" }} />
          <p style={{ fontWeight: 400, fontSize: "16px", color: "white" }}>{renderName()}</p>
          <p style={{ fontWeight: 400, fontSize: "16px", color: "white" }}>{timer == true ? time_convert(seconds) : timer == false ? "Calling..." : "Disconnected"}</p>
          <Button
            onClick={() => handleMic(audio)}
            className="btn mx-1" style={{ background: "#6C757D" }}>
            {audio ?
              <MicIcon style={{ color: "#fff" }} />
              : <MicOffIcon style={{ color: "#fff" }} />
            }
          </Button>
          <Button
            onClick={() => leaveCall()}
            className="btn mx-1" style={{ background: "#DC3444" }}>
            <CallEndIcon style={{ color: "#fff" }} />
          </Button>
        </div>
      </div>
    </>
  );
};
export default Call;