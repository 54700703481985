// Image Icons
import homeImg from "../../images/icon/new_icon/home.png";
import inboxImg from "../../images/icon/new_icon/inbox.png";
import likeImg from "../../images/icon/new_icon/leaf.png";
import dconImg from "../../images/icon/new_icon/dating.png";
import preqImg from "../../images/icon/new_icon/pending.png";
import cconnImg from "../../images/icon/new_icon/c-conn.png";
import placeImg from "../../images/icon/new_icon/location-pin.png";
import communityImg from "../../images/icon/new_icon/c-conn.png";
import datingImg from "../../images/icon/new_icon/dating.png";
import recipesImg from "../../images/icon/new_icon/recipe.png";
import aboutImg from "../../images/icon/new_icon/about.png";
import feedImg from "../../images/icon/new_icon/feedback.png";
import tcImg from "../../images/icon/new_icon/terms.png";
import helpImg from "../../images/icon/new_icon/help.png";
import accountImg from "../../images/icon/new_icon/settings-line.png";
import delAccountImg from "../../images/icon/deleteAccount.png";

//  ColorIcon
import homeImgColor from "../../images/colorIcon/new_icons/home.png";
import inboxImgColor from "../../images/colorIcon/new_icons/inbox.png";
import likeImgColor from "../../images/colorIcon/new_icons/liked.png";
import dconImgColor from "../../images/colorIcon/new_icons/dating.png";
import preqImgColor from "../../images/colorIcon/new_icons/pending.png";
import cconnImgColor from "../../images/colorIcon//new_icons/c-comm.png";
import placeImgColor from "../../images/colorIcon/new_icons/location.png";
import communityImgColor from "../../images/colorIcon/new_icons/c-comm.png";
import datingImgColor from "../../images/colorIcon/new_icons/dating.png";
import recipesImgColor from "../../images/colorIcon/new_icons/recipe.png";
import aboutImgColor from "../../images/colorIcon/new_icons/about.png";
import feedImgColor from "../../images/colorIcon/new_icons/feedback.png";
import tcImgColor from "../../images/colorIcon/new_icons/terms.png";
import helpImgColor from "../../images/colorIcon/new_icons/help.png";
import accountImgColor from "../../images/colorIcon/new_icons/settings-line.png";
import delAccountImgColor from "../../images/colorIcon/deleteAccountCol.png";

const NavItems = [
  {
    id: 1,
    icon: homeImg,
    colorIcon: homeImgColor,
    title: "Home",
    link: "/dashome",
    badge: 0
  },
  {
    id: 2,
    icon: inboxImg,
    colorIcon: inboxImgColor,
    title: "Inbox",
    link: "/inbox",
    badge: 1
  },
  {
    id: 3,
    icon: likeImg,
    colorIcon: likeImgColor,
    title: "Liked By",
    link: "/liked",
    badge: 1
  },
  {
    id: 4,
    icon: dconImg,
    colorIcon: dconImgColor,
    title: "Dating Connection",
    link: "/DDatingCon",
    badge: 0

  },
  {
    id: 5,
    icon: cconnImg,
    colorIcon: cconnImgColor,
    title: "Community Connection",
    link: "/CConnection",
    badge: 0
  },
  {
    id: 6,
    icon: preqImg,
    colorIcon: preqImgColor,
    title: "Pending Request",
    link: "/PendingReq",
    badge: 1
  },
  {
    id: 7,
    icon: placeImg,
    colorIcon: placeImgColor,
    title: "Places",
    link: "/DPlace",
    badge: 0
  },
  {
    id: 8,
    icon: communityImg,
    colorIcon: communityImgColor,
    title: "Community",
    link: "/DCommunity",
    badge: 0
  },
  {
    id: 9,
    icon: datingImg,
    colorIcon: datingImgColor,
    title: "Dating",
    link: "/DDating",
    badge: 0
  },
  {
    id: 10,
    icon: recipesImg,
    colorIcon: recipesImgColor,
    title: "Recipes",
    link: "/DRacipes",
    badge: 0
  },
  {
    id: 11,
    icon: aboutImg,
    colorIcon: aboutImgColor,
    title: "About",
    link: "/DAbout",
    badge: 0
  },
  {
    id: 12,
    icon: feedImg,
    colorIcon: feedImgColor,
    title: "Send Feedback",
    link: "/Feedback",
    badge: 0
  },
  {
    id: 13,
    icon: tcImg,
    colorIcon: tcImgColor,
    title: "Terms & Conditions",
    link: "/Terms",
    badge: 0
  },
  {
    id: 14,
    icon: helpImg,
    colorIcon: helpImgColor,
    title: "Help",
    link: "/Help",
    badge: 0
  },
  {
    id: 15,
    icon: accountImg,
    colorIcon: accountImgColor,
    title: "Account settings",
    link: "/Account",
    badge: 0
  },
  {
    id: 16,
    icon: delAccountImg,
    colorIcon: delAccountImgColor,
    title: "Delete Account",
    link: "/delete_Account",
    badge: 0
  }
]

export default NavItems;