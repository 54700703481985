import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import PRCommunity from "./PRCommunity/PRCommunity";
import PRDating from "./PRDating/PRDating";
import "./pr.css";
import { Button } from "antd";

const PendingReq = () => {
  const [active, setActive] = useState("C");
  const [activeIndex, setActiveIndex] = useState("1");

  const toggleButton = (data) => {
    setActiveIndex(data.id);
    setActive(data.fun);
  }

  const placeData = [
    {
      id: "1",
      text: "Community",
      fun: "C",
    },
    {
      id: "2",
      text: "Dating",
      fun: "D",
    },
  ];
  return (
    <Box>
      <Typography className="common_medium_font">Pending Request</Typography>
      <div className="d-flex width_manage">
        {placeData.map((data, key) => {
          return (
            <Button
              className="common_btn my-3 me-lg-3 w-100"
              onClick={() => { toggleButton(data) }}
              style={{
                backgroundColor: activeIndex === data.id ? "#F8931F" : "#fff",
              }}
              key={key}
            >
              <Typography
                className=""
                style={{
                  color: activeIndex === data.id ? "#fff" : "#F8931F",
                  textTransform: "capitalize",
                  fontSize: "13px",
                  fontFamily: "Montserrat",
                }}
              >
                {data.text}
              </Typography>
            </Button>
          );
        })}
      </div>
      {active === "C" && <PRCommunity />}
      {active === "D" && <PRDating />}
    </Box>
  );
};

export default PendingReq;
