import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOtherUserDetail, getOtherUserDetails } from "../../../redux/action/recipe";
import dummyPic from "../../../images/CW/profile.jpeg";
import { ImgUrl } from "../../../constants/const";
import OtherUserCommunity from "./OtherUserCommunity";
import OtherUserRecipe from "./OtherUserRecipe";
import OtherUserGallery from "./OtherUserGallery";
import Spinner from "../../../constants/Spinner";
import { Button, Image } from "antd";

const NavigationButton = [
  {
    id: "1",
    title: "Uploads",
    fun: "U",
  },
  {
    id: "2",
    title: "Recipes",
    fun: "R",
  },
  {
    id: "3",
    title: "Community",
    fun: "C",
  },
];

const CCProfile = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState("U");
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState("1");
  const [otherUserData, setOtherUserData] = useState("");

  let { id } = useParams();
  // const otherUserData = useSelector((state) => state.recipe?.other_user_detail?.data);
  const htmlString = otherUserData?.bio;

  const getUserData = () => {
    setLoading(true)
    const success = (res) => {
      setLoading(false)
      setOtherUserData(res?.data)
    }
    const failure = (res) => {
      setLoading(false)
    }
    let data = {
      other_user_id: id
    }
    dispatch(getOtherUserDetails(data, success, failure))
  }

  useEffect(() => {
    getUserData();
  }, [])

  return (
    <>
      {loading ? (<Spinner />) : (
        <Box>
          <Box style={{ marginBottom: "20px" }}>
            <Box className="profile__text__btn" style={{ margin: "0 0 20px 0" }} >
              <Typography className="common_medium_font">Profile</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={4} lg={3}>
                <Box style={{ display: "flex", flexDirection: "row", boxSizing: "border-box", }} className="profile_image_ansh" >
                  <Image className="imgSection--profile" src={otherUserData?.profile_pic !== "" ? `${ImgUrl}${otherUserData?.profile_pic}` : dummyPic} fallback={dummyPic} alt="profile_img" style={{ borderRadius: "50%" }} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={9} md={8} lg={9} style={{ display: "grid", alignItems: "center" }} >
                <Box>
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "10px" }} >
                    <Typography> <strong style={{ fontWeight: "600" }}>Name</strong> </Typography>
                    <Typography> <strong style={{ fontWeight: "600" }}>
                      {otherUserData?.full_name ? otherUserData?.full_name : "---"} </strong> </Typography>
                  </Box>
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "10px" }} >
                    <Typography> <strong style={{ fontWeight: "600" }}>Username</strong> </Typography>
                    <Typography> <strong style={{ fontWeight: "600" }}> {otherUserData?.username ? otherUserData?.username : "---"} </strong> </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Typography className="my-3"> <strong style={{ fontWeight: "600", fontSize: "22px" }}> About Me </strong> </Typography>
              {otherUserData?.bio?.length > 0 ?
                <div style={{ maxHeight: '8rem', overflowY: 'auto' }} dangerouslySetInnerHTML={{ __html: htmlString }} />
                : "---"}
              {/* <Typography> {otherUserData?.bio ? otherUserData?.bio : "---"} </Typography> */}
            </Box>
            <div className="row mb-4 g-0 g-lg-2">
              {NavigationButton.map((data, key) => {
                return (
                  <div className="col-4 col-lg-3">
                    <Button className="common_btn w-100" onClick={() => { setActiveIndex(data.id); setActive(data.fun); }} style={{ backgroundColor: activeIndex === data.id ? "#F8931F" : "#fff", }} key={key} >
                      <b className="small_font" style={{ color: activeIndex === data.id ? "#fff" : "#F8931F", }} > {data.title} </b>
                    </Button>
                  </div>
                );
              })}
            </div>
            <Box style={{ maxHeight: "30vh", overflowY: "scroll" }} >
              {active === "U" && <OtherUserGallery />}
              {active === "R" && <OtherUserRecipe />}
              {active === "C" && <OtherUserCommunity />}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
export default CCProfile;