import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import "./Matching.css";
import Matchlogo from "../../../images/datingasset/matchlogo.png";
import Spinner from "../../../constants/Spinner";
import moment from "moment"
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { db } from "../../../firebase";
import { ImgUrl } from "../../../constants/const";
import dummyProfile from "../../../images/CW/profile.jpeg";

const Matching = (props) => {
  let { itsMatch } = props
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const manageChat = async (data) => {
    let senderSideKey = data?.sender_data?._id + "_" + data?.receiver_data?._id
    db.collection("Users").doc(data?.sender_data?._id?.toString()).collection("Chats").doc(senderSideKey?.toString()).get().then((senderKeyRef) => {
      if (!senderKeyRef.exists) {
        db.collection("Users").doc(data?.sender_data?._id?.toString()).collection("Chats").doc(senderSideKey?.toString()).set({
          date: moment().format("MM/DD/YYYY"),
          key: senderSideKey?.toString(),
          lastMsg: "",
          lastMsgTime: new Date().getTime(),
          to: data?.receiver_data?.full_name,
          toProfileImg: data?.receiver_data?.profile_pic,
          toUid: data?.receiver_data?._id,
          type: "TEXT",
          unreadCount: 0
        })
      }
    })
    db.collection("Users").doc(data?.receiver_data?._id?.toString()).collection("Chats").doc(senderSideKey?.toString()).get().then((receiverKeyRef) => {
      if (!receiverKeyRef.exists) {
        db.collection("Users").doc(data?.receiver_data?._id?.toString()).collection("Chats").doc(senderSideKey?.toString()).set({
          date: moment().format("MM/DD/YYYY"),
          key: senderSideKey?.toString(),
          lastMsg: "",
          lastMsgTime: new Date().getTime(),
          to: data?.sender_data?.full_name,
          toProfileImg: data?.sender_data?.profile_pic,
          toUid: data?.sender_data?._id,
          type: "TEXT",
          unreadCount: 0
        })
      }
    })
    db.collection("Chats").doc(senderSideKey?.toString()).get().then((ref) => {
      if (!ref.exists) {
        db.collection("Chats").doc(senderSideKey?.toString()).set({
          from: data?.sender_data?.full_name,
          fromUid: data?.sender_data?._id,
          key: senderSideKey?.toString(),
          msg: "Hi",
          time: moment().format("h:mma"),
          timestamp: moment().unix(),
          to: data?.receiver_data?.full_name,
          toProfileImg: data?.receiver_data?.profile_pic,
          toUid: data?.receiver_data?._id,
          type: "TEXT"
        })
      }
    })
    setLoading(true)
    setTimeout(() => {
      sessionStorage.setItem("activeChat", data?.receiver_data?._id)
      setLoading(false)
      navigate("/inbox")
    }, 2000)
  }

  return (
    <>
      {loading ? <Spinner /> :
        <Box >
          <Typography variant="h4" className="match_top_text_style"> Match </Typography>
          <Box className="match_images_style">
            <Row gutter={[8, 16]} >
              <img src={Matchlogo} alt="Matchlogo" style={{ maxWidth: "20%", }} className="match_logo_style" />
              <Col span={12}>
                <img src={itsMatch?.sender_data?.profile_pic != "" ? ImgUrl + itsMatch?.sender_data?.profile_pic : dummyProfile} alt="matchpic" className="match_male_female_style" />
              </Col>
              <Col span={12}></Col>
              <Col span={12}></Col>
              <Col span={12}>
                <img src={itsMatch?.receiver_data?.profile_pic != "" ? ImgUrl + itsMatch?.receiver_data?.profile_pic : dummyProfile} alt="matchpic" className="match_male_female_style" />
              </Col>
            </Row>
          </Box>
          <Box style={{ textAlign: "center", display: "block", margin: "20px " }}>
            <Typography variant="h4" gutterBottom className="match_lower_text"> It's a match! </Typography>
            <Button onClick={() => manageChat(itsMatch)} variant="contained" className="match_button"> Send message </Button>
          </Box>
        </Box>
      }
    </>
  );
};
export default Matching;