import React, { useEffect, useState } from "react";
import { Button, Card, CardMedia, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CardContent from "@mui/material/CardContent";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOtherUserDetail, getRecipePostDetail } from "../../../redux/action/recipe";
import { ImgUrl } from "../../../constants/const";
import dummyProfile from "../../../images/CW/profile.jpeg";
import dummyRestro from "../../../images/CW/dummyRestro.png";
import { Modal } from "react-bootstrap";
import { Input, Spin } from "antd";
import Divider from "@mui/material/Divider";
import shareImg from "../../../images/CW/Icon ionic-ios-share-alt@2x.png";
import { FacebookShareButton } from "react-share";

const { TextArea } = Input;
const OtherUserRecipe = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState(null);
  const [description, setDesc] = useState(null);
  const [imageset, setImageset] = useState(null);
  const [type, setType] = useState('image');
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  // For Description
  const handleCloseDesc = () => {
    setShow(false);
    setTitle(null)
    setDesc(null)
    setImageset(null)
    setType(null)
    setLoading(false)
  };
  const handleClickOpenDesc = (data) => {
    // setLoading(true)

    setTitle(data?.title)
    setDesc(data?.description)
    setImageset(data?.media)
    setType(data?.media_type)
    setShow(true);
    // setLoading(false)


    // let data = {
    //   recipe_post_id: id,
    // };
    // dispatch(getRecipePostDetail(data, successCb));
  };
  // Description Ends here

  const recipe_data = useSelector((state) => state.recipe?.other_user_detail?.data?.recipe_data);
  useEffect(() => {
    let token = localStorage.getItem("token");
    let other_user_id = id;
    dispatch(getOtherUserDetail({ other_user_id }, token));
  }, []);

  return (
    <Box>
      <Box style={{ display: "flex", flexDirection: "row-reverse", color: "#000" }} > <Button className="see_more_card_btn" onClick={handleClickOpen}> See All </Button> </Box>
      <Grid container spacing={2}>
        {recipe_data?.length > 0 ? recipe_data?.map((data, id) => {
          console.log("data", data)
          return (
            <Grid item xs={12} sm={3} md={2} lg={2} key={id}>
              <Card onClick={() => handleClickOpenDesc(data)} sx={{ maxWidth: 320 }} style={{ margin: "0", padding: "0", boxSizing: "border-box", position: "relative", boxShadow: "9px 9px 31px -10px rgba(0,0,0,0.75)", borderRadius: "12px", border: "2px solid orange", objectFit: "cover", cursor: 'pointer' }} >
                {data?.media_type !== "video" ? (
                  <CardMedia component="img" height="194" image={data?.media !== "" ? `${ImgUrl}${data?.media}` : dummyRestro} alt="dish_image" style={{ borderRadius: "10px 10px 0 0px" }} />
                ) : (
                  <CardMedia component='video' height="200" width="100%" image={`${ImgUrl}${data?.media}`} alt="vegan_video" controls />
                  // <video width="100%" height="200" controls > <source src={data?.media !== "" ? `${ImgUrl}${data?.media}` : ''} type="video/mp4" /> </video>
                )}
              </Card>
            </Grid>
          );
        }) : <p className="d-flex text-center mx-4" >No data added yet!</p>}
      </Grid>
      <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title" >
        <DialogContent>
          <Box style={{ display: "flex", alignItems: "center", }} > <KeyboardBackspaceIcon onClick={handleClose} fontSize="large" style={{ flex: "start", cursor: "pointer" }} /> </Box>
          <DialogContentText style={{ color: "#000", fontFamily: "Montserrat", fontWeight: "normal", display: "flex", textAlign: "center", }} >
            <Box style={{ margin: "0", padding: "0" }}>
              <CardContent>
                <Box>
                  <Grid container spacing={2}>
                    {recipe_data?.length > 0 ? recipe_data?.map((data, id) => {
                      return (
                        <Grid item xs={12} sm={4} md={4} lg={4} key={id}>
                          <img src={data?.media != "" ? `${ImgUrl}${data?.media}` : `${dummyProfile}`} height={120} width="100%" style={{ border: "2px solid #F8931F", borderRadius: "12px", width: "auto", }} alt="img" />
                        </Grid>
                      );
                    }) : <p className="d-flex text-center mx-4" >No data added yet!</p>}
                  </Grid>
                </Box>
              </CardContent>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* For description */}
      <Modal show={show} onHide={handleCloseDesc}>
        <Modal.Header closeButton />
        <Modal.Body>
          <Spin spinning={loading}>
            <Box>
              <Box>
                <Box mt={2} textAlign="center">
                  {type == "image" ?
                    <img src={`${ImgUrl}${imageset}`} alt={title} style={{ width: "80%" }} />
                    : <CardMedia component='video' height="440" width="100%" image={`${ImgUrl}${imageset}`} alt="vegan_video" controls />
                  }
                </Box>
              </Box>
              <Box>
                <p style={{ fontWeight: "bold", margin: "10px 10px 0" }}>{title}</p>
                <TextArea readOnly rows={4} value={description} style={{ margin: "10px 0", border: 'none', outline: 'none' }} />
              </Box>
              <Divider style={{ background: "grey", marginBottom: 10 }} />
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <FacebookShareButton url={`${ImgUrl}${imageset}`} quote={`${title}`} hashtag={`#${title}`} description={description} className="Demo__some-network__share-button" >
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer", }} >
                    <label style={{ cursor: "pointer", fontWeight: "bold" }}>
                      <img src={shareImg} height={15} alt="s_icon" /> Share
                    </label>
                  </Box>
                </FacebookShareButton>
              </Box>
            </Box>
          </Spin>
        </Modal.Body>
      </Modal>
    </Box>
  );
};
export default OtherUserRecipe;