import React, { useEffect, useState } from "react";
import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CallIcon from "@mui/icons-material/Call";
import VideocamIcon from "@mui/icons-material/Videocam";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import CallMissedIcon from '@mui/icons-material/CallMissed';
import { useSelector, useDispatch } from "react-redux";
import { db } from "../../../firebase";
import dummyPic from "../../../images/CW/profile.jpeg";
import { ImgUrl } from "../../../constants/const";
import Spinner from "../../../constants/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { sendChatNotification, generateAgoraToken } from "../../../redux/action/auth";

const Calls = () => {
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(true);

  const [TOKEN, SETTOKEN] = useState(null);

  const user_data = useSelector((state) => state.dashboard?.user_data);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const appID = "ab0e65d266354bf684b91530ee7e481a";

  useEffect(() => {
    setLoading(true)
    const callsCollection = db
      .collection("call")
      .doc(user_data?._id)
      .collection("list");
    callsCollection.onSnapshot(
      (snapshot) => {
        if (snapshot?.docs?.length !== 0) {
          let c = snapshot.docs.map((x) => x.data());
          c.sort((a, b) => {
            const fireBaseTimea = new Date(
              a?.created_at?.seconds * 1000 +
              a?.created_at?.nanoseconds / 1000000
            );
            const fireBaseTimeb = new Date(
              b?.created_at?.seconds * 1000 +
              b?.created_at?.nanoseconds / 1000000
            );
            return fireBaseTimeb - fireBaseTimea;
          });
          setCalls(c);
          setLoading(false);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    if (calls.length == 0) {
      setLoading(false)
    }
  }, [calls])

  const callTime = (data) => {
    const userTime = new Date(
      data?.seconds * 1000 + data?.nanoseconds / 1000000
    );
    return userTime;
  };

  const startCall = (value, call_type) => {
    let otherUserData = {
      toUid: value?.user?._id,
      to: value?.user?.full_name,
      toProfileImg: value?.user?.profile_pic,
    }
    const retrieveToken = (response) => {
      SETTOKEN(response?.Status ? response?.data?.token : null);
      if (response?.Status ? response?.data?.token : null) {
        buildPayloadAndFire(response?.data?.token, otherUserData, call_type);
      }
    };
    dispatch(
      generateAgoraToken({
        channel_name: user_data?._id,
        appID
      }, retrieveToken)
    );
  }

  const buildPayloadAndFire = (token, otherUserData, call_type) => {
    console.log("debugger", "function called")
    const payload = {
      title: user_data?.full_name,
      body: "Incoming " + (call_type == "video" ? "Video" : "Audio") + " Call",
      sound: "incallmanager_ringtone.mp3",
      channel_id: "call",
      android_channel_id: "call",
      data: {
        type: call_type + "Call",
        name: user_data?.full_name,
        user_id: user_data?._id,
        image: user_data?.profile_pic,
        channelName: user_data?._id,
        token,
        call: false,
        sender_id: user_data?._id,
        receiver_id: otherUserData?.toUid
      },
    };
    const data = {
      user_id: otherUserData?.toUid,
      payload,
    };
    console.log("data", data)
    db.collection('channel').doc(user_data?._id?.toString()).set({
      join: true,
      user1: user_data?._id,
      user2: otherUserData?.toUid,
      join1: true,
      join2: false,
      mphone2: true,
      mphone1: true,
      camera1: true,
      camera2: true,
    })
    const navigateToCallScreen = () => {
      let callPayload = {
        appID,
        channel: user_data?._id,
        token,
        sender_id: user_data?._id,
        receiver_id: otherUserData?.toUid,
        receiver_name: otherUserData?.to,
        receiver_profile: otherUserData?.toProfileImg,
        type: call_type == "video" ? "Video" : "Voice",
        call_type: "Outgoing"
      };
      sessionStorage.setItem("callPayload", JSON.stringify(callPayload));
      // store call information to firestore
      let outgoingCallObj = {
        call_type,
        created_at: new Date(),
        type: "outgoing",
        user: {
          _id: otherUserData?.toUid,
          full_name: otherUserData?.to,
          profile_pic: otherUserData?.toProfileImg
        }
      }
      db.collection("call").doc(user_data?._id?.toString()).collection("list").doc().set(outgoingCallObj)
      if (call_type == "video") {
        navigate("/video_call");
      } else {
        navigate("/call");
      }
    };
    dispatch(sendChatNotification(data, navigateToCallScreen));
  };

  return loading ? (
    <Spinner />
  ) : (
    <Box style={{ maxHeight: "90vh", overflowY: "scroll", minHeight: '50vh' }}>
      {calls.length > 0 ? calls?.map((value, id) => {
        return (
          <Box className="liked_main_style" key={id}>
            <List
              sx={{
                width: "100%",
                maxWidth: "97%",
                bgcolor: "background.paper",
              }}>
              <ListItem
                secondaryAction={
                  <IconButton>
                    {value?.call_type === "audio" ? (
                      <CallIcon onClick={() => startCall(value, "audio")} fontSize="large" style={{ color: "orange" }} />
                    ) : (
                      <VideocamIcon
                        onClick={() => startCall(value, "video")}
                        fontSize="large"
                        style={{ color: "orange" }}
                      />
                    )}
                  </IconButton>
                }
                className="liked_margin_padding_mui">
                <Link to={`/cc_profile/${value?.user?._id}`} style={{ color: "orange" }}>
                  <Box className="liked_text_style">
                    <ListItemAvatar>
                      <Avatar className="liked_avatar_style" src={value?.user?.profile_pic !== "" ? `${ImgUrl}${value?.user?.profile_pic}` : dummyPic} />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography style={{ color: "#444444", fontWeight: 'bold' }} sx={{ fontWeight: 'light' }}>{value?.user?.full_name}</Typography>
                      <Box style={{ display: "flex" }}>
                        <Typography sx={{ display: "inline" }} component="span" variant="body2" style={{ color: "#444444" }}>
                          {value?.type === "outgoing" ? (
                            <CallMadeIcon style={{ color: "green" }} />
                          ) : value?.type === "missed" ? (
                            <CallMissedIcon style={{ color: "red" }} />
                          ) : (
                            <CallReceivedIcon style={{ color: "green" }} />
                          )}
                        </Typography>
                        <Typography style={{ color: "#444444" }}>
                          {callTime(value?.created_at).toLocaleDateString()}{" "}
                          {callTime(value?.created_at).toLocaleTimeString()}
                        </Typography>
                      </Box>
                    </ListItemText>
                  </Box>
                </Link>
              </ListItem>
            </List>
          </Box>
        );
      }) : <p className="text-center text-warning"> OOps...! <br /> No Chat record found...!</p>}
    </Box>
  );
};

export default Calls;
