import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import DirectionImg from "../../../images/direction.png";
import PathImg from "../../../images/path.png";
import { useParams } from "react-router-dom";
import { GOOGLE_MAP_APIKEY } from "../../../constants/const";
import NoImage from "../../../images/CW/no_images.png";
import { useDispatch, useSelector } from "react-redux";
import { restroDetailPage } from "../../../redux/action/dashboard";
import Spinner from "../../../constants/Spinner";

const Restaudetails = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [loading, setLoading] = useState(false);
  const getRestroData = useSelector((state) => state?.dashboard?.restro_detail?.result);

  useEffect(() => {
    setLoading(true)
    const successCb = (resp) => {
      setLoading(false)
    }
    const failureCb = (resp) => {
      setLoading(false)
    }
    const data = { place_id: id }
    dispatch(restroDetailPage(data, successCb, failureCb))
  }, [lat, lng]);

  navigator.geolocation.getCurrentPosition((position) => {
    setLat(position.coords.latitude);
    setLng(position.coords.longitude);
  });
  let lat2 = getRestroData?.geometry?.location?.lat;
  let lng2 = getRestroData?.geometry?.location?.lng;

  function distance(lat, lat2, lng, lng2) {
    lng = (lng * Math.PI) / 180;
    lng2 = (lng2 * Math.PI) / 180;
    lat = (lat * Math.PI) / 180;
    lat2 = (lat2 * Math.PI) / 180;

    let dlon = lng2 - lng;
    let dlat = lat2 - lat;
    let a =
      Math.pow(Math.sin(dlat / 2), 2) +
      Math.cos(lat) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

    let c = 2 * Math.asin(Math.sqrt(a));
    let r = 6371;
    return c * r * 0.621371;
  }
  const dist = Math.floor(distance(lat, lat2, lng, lng2));

  return loading ? (<Spinner />) : (
    <div className="row pt-5 px-5" >
      <div className="col-12 col-md-5">
        <img className="img-thumbnail" style={{ maxHeight: 600 }} src={getRestroData?.photos?.length > 0 ?
          "https://maps.googleapis.com/maps/api/place/photo?maxwidth=700&photoreference=" + getRestroData?.photos[0]?.photo_reference + "&key=" + GOOGLE_MAP_APIKEY
          : NoImage} alt=""
        />
      </div>
      <div className="col-12 col-md-7">
        <Box style={{ display: "flex", justifyContent: "space-between" }} >
          <Typography className="card_footer_text_style" style={{ fontSize: "18px", fontWeight: "500", }} > {getRestroData?.name} </Typography>
          <Typography className="card_footer_text_style" style={{ fontSize: "18px", fontWeight: "500", }} > {dist} {dist > 1 ? 'Miles' : 'Mile'} </Typography>
        </Box>
        <Box style={{ margin: "20px 0", display: "flex", justifyContent: "space-between", alignItems: "center", }} >
          <Box>
            <Typography variant="h6" style={{ font: "normal normal bold 22px Montserrat" }} > Address </Typography>
            <Typography style={{ font: "normal normal medium 20px Montserrat" }} > {getRestroData?.formatted_address} </Typography>
          </Box>
          <a target="_blank" href={getRestroData?.url}> <img src={DirectionImg} alt="DirectionImg" /> </a>
        </Box>
        <Box style={{ margin: "20px 0", display: "flex", justifyContent: "space-between", alignItems: "center", }} >
          <Box>
            <Typography variant="h6" style={{ font: "normal normal bold 22px Montserrat" }} > Website </Typography>
            <Typography style={{ font: "normal normal medium 20px Montserrat" }} > {getRestroData?.website}</Typography>
          </Box>
          <a href={getRestroData?.website} target="_blank"> <img src={PathImg} alt="DirectionImg" /> </a>
        </Box>
      </div>
    </div>

  );
};
export default Restaudetails;