import moment from "moment";

// Video funct.
import { createClient, createMicrophoneAndCameraTracks } from "agora-rtc-react";

export const authToken = localStorage.getItem("token") ? localStorage.getItem("token") : null;
// export const ImgUrl = "https://d3uee3mqxu4byh.cloudfront.net/";
export const ImgUrl = "https://d2bg6vf8jnp3a8.cloudfront.net/";
// export const BASE_URL = `http://192.168.0.123:5066/api/v1/`;
// export const ImgUrl = "https://api.vegansmeetdaily.com/files/";
export const BASE_URL = `https://api.vegansmeetdaily.com/api/v1/`;
export const GOOGLE_MAP_APIKEY = "AIzaSyB2g1xL-MBPR2R5ihupvBqVSiTVtTGF0Ps";
export const VAPID_KEY = `BI3LjAF_N-LEVdpD8vh2f8T3HM6lWfho8-NE7216TSYq1YRN1PKSFqX1HWKg8vVUYnHkfF1imYHyp1wCuvRvP7Q`;

export const getDiff = (time) => {
  let resolutionTime = moment().unix() - time;
  if (resolutionTime < 60) {
    return "just now";
  } else if (resolutionTime / 60 < 60) {
    return parseInt(resolutionTime / 60) + "m ago";
  } else if (resolutionTime / 60 / 60 < 24) {
    return parseInt(resolutionTime / 60 / 60) + "h ago";
  } else if (resolutionTime / 60 / 60 / 24 < 30) {
    return parseInt(resolutionTime / 60 / 60 / 24) + "d ago";
  } else if (resolutionTime / 60 / 60 / 24 / 30 < 12) {
    return Math.round(resolutionTime / 60 / 60 / 24 / 30) + "m ago";
  } else {
    return Math.round(resolutionTime / 60 / 60 / 24 / 30 / 12) + "y ago";
  }
};

const APP_ID = `ab0e65d266354bf684b91530ee7e481a`;
const TOKEN = `007eJxTYLic+vzcvr8+85yL5zHcn7mQ++FnPv3fjHJPhf+2Hzz0epGXAoNJioGpYZKxgaVJYpKJpYGFRXJacmISEKQaGlikpRoduKaUbK+hkpzD8p6BEQpBfBaGktTiEgYGADA1Iqo=`;
const CHANNEL = `test`;

export const config = {
  mode: "rtc",
  codec: "vp8",
  appId: APP_ID,
  token: TOKEN,
};

export const useClient = createClient(config);
export const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();
export const channelName = CHANNEL;