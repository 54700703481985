import React, { useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import "./Card.css";
import { Box } from "@mui/system";
import { Divider, Grid } from "@mui/material";
import shareImg from "../../../images/CW/Icon ionic-ios-share-alt@2x.png";
import { Link } from "react-router-dom";
import dummyProfile from "../../../images/CW/profile.jpeg";
import { FacebookShareButton } from "react-share";

// API
import { getDiff, ImgUrl } from "../../../constants/const";
import { useDispatch, useSelector } from "react-redux";
import { getRecipePost } from "../../../redux/action/recipe";
import dummyRestro from "../../../images/CW/dummyRestro.png";

import Spinner from "../../../constants/Spinner";

export default function LunchCrad() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const recipeData = useSelector((state) => state?.recipe?.recipe_post?.data);

  // For video player function
  const [playingVideo, setPlayingVideo] = useState(null); // Track the currently playing video
  const videoRefs = useRef([]);

  const playVideo = (index) => {
    if (playingVideo !== null && playingVideo !== index) {
      // Pause the previously playing video
      videoRefs.current[playingVideo].pause();
    }
    setPlayingVideo(index);
  };

  const stopVideo = (index) => {
    if (playingVideo === index) {
      setPlayingVideo(null);
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    const menu_id = `628ddb3bd3acde9900c18e1d`;
    const page = "1";
    dispatch(getRecipePost({ menu_id, page }, token));
  }, []);

  useEffect(() => {
    if (recipeData && loading) {
      setLoading(false);
    }
  }, [recipeData]);


  return (
    <>
      {loading ? (<Spinner />) : (
        <Box className="recipes_card_main_box">
          <Grid container spacing={2} elevation={2}>
            {recipeData?.length > 0 ? recipeData?.map((data, id) => {
              return (
                <Grid item xs={12} sm={12} md={12} lg={12} key={id}>
                  <Card sx={{ width: "1" }} style={{ borderRadius: "12px", width: "100%" }} className="mx-auto recipes_foot_card_style" >
                    <Link to={`/cc_profile/${data?.user_id?._id}`} style={{ color: "orange" }} >
                      <CardHeader
                        avatar={<Avatar alt="per_img" src={data?.user_id?.profile_pic !== "" ? `${ImgUrl}${data?.user_id?.profile_pic}` : `${dummyProfile}`} sx={{ width: 56, height: 56, border: "2px solid orange", }} />}
                        title={data?.user_id?.full_name}
                        subheader={getDiff(data?.created_at)}
                      />
                    </Link>
                    {data?.media_type == "image" ? (
                      <CardMedia className="community_card" component="img" image={data?.media !== "" ? `${ImgUrl}${data?.media}` : dummyRestro} alt="dish_image" />
                    ) : (
                      <div className="row">
                        <video
                          className="community_card"
                          controls
                          ref={(video) => (videoRefs.current[id] = video)} // Store the video element in a ref array
                          onPlay={() => playVideo(id)}
                          onEnded={() => stopVideo(id)}
                        >
                          <source src={data?.media !== "" ? `${ImgUrl}${data?.media}` : ''} type="video/mp4" />
                        </video>
                      </div>
                    )}
                    <CardContent>
                      <Typography variant="body1" color="text"> {data?.title} </Typography>
                      <Typography variant="body2" color="text.secondary"> {data?.description} </Typography>
                    </CardContent>
                    <Divider style={{ background: "grey" }} />
                    <CardActions disableSpacing style={{ margin: "0 20px", display: "flex", flexDirection: "row", justifyContent: "center", }} >
                      {/* Share Icon */}
                      <FacebookShareButton url={`${ImgUrl}${data?.media}`} quote={`"${data?.title}"`} hashtag={`#${data?.title}`} description={data?.description} className="Demo__some-network__share-button" >
                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer", }} >
                          <IconButton aria-label="share"> <img src={shareImg} height={15} alt="s_icon" /> </IconButton>&nbsp; <Typography>Share</Typography>
                        </Box>
                      </FacebookShareButton>
                    </CardActions>
                  </Card>
                </Grid>
              );
            }) : <p style={{ color: 'orange', margin: '2em', display: 'flex', justifyContent: 'center', flex: '1' }}>No data found!</p>}
          </Grid>
        </Box>
      )}
    </>
  );
}