import {
    GET_MENUS,
    GET_RECIPE_POST,
    EDIT_RECIPE,
    GET_MY_RECIPE,
    DELETE_RECIPE_POST,
    ADD_RECIPE_COMMENT,
    GET_RECIPE_COMMENT,
    LIKE_RECIPE,
    GET_OTHER_USER_DETAIL,
    RECIPE_POST_DETAIL,
    SEARCH_RECIPE,
    ADD_RECIPE,
    GET_RECIPE_POST_DETAILS,
  } from "../constants";
  const INIT_STATE = {
    token: "",
    user_data: null,
    call_response: null,
    other_user_detail: null,
    recipe_post: null,
    search_recipe: null,
    add_recipe:null,
    edit_recipe:null,
    get_recipe_post_details:null,
    get_my_recipe:null,
  };
  
  export default (state = INIT_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_MENUS:
        return {
          ...state,
          call_response: payload,
        };
      case GET_RECIPE_POST:
        return {
          ...state,
          recipe_post: payload,
        };
      case GET_RECIPE_POST_DETAILS:
        return {
          ...state,
          get_recipe_post_details: payload,
        };
      case EDIT_RECIPE:
        return {
          ...state,
          edit_recipe: payload,
        };
      case GET_MY_RECIPE:
        return {
          ...state,
          get_my_recipe: payload,
        };
      case DELETE_RECIPE_POST:
        return {
          ...state,
          call_response: payload,
        };
      case ADD_RECIPE_COMMENT:
        return {
          ...state,
          call_response: payload,
        };
      case GET_RECIPE_COMMENT:
        return {
          ...state,
          call_response: payload,
        };
      case LIKE_RECIPE:
        return {
          ...state,
          call_response: payload,
        };
      case GET_OTHER_USER_DETAIL:
        return {
          ...state,
          other_user_detail: payload,
        };
      case RECIPE_POST_DETAIL:
        return {
          ...state,
          call_response: payload,
        };
      case SEARCH_RECIPE:
        return {
          ...state,
          search_recipe: payload,
        };
      case ADD_RECIPE:
        return {
          ...state,
          add_recipe: payload,
        };
      default:
        return state;
    }
  };
  