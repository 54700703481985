import React, { useRef, useState } from "react";
import { Avatar, Badge, Button, Card, IconButton, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CallImg from "../../../images/CW/Group 17303@3x.png";
import VideoImg from "../../../images/CW/Group 17304@2x.png";
import { Input, Modal } from "antd";
import SentimentSatisfiedSharpIcon from "@mui/icons-material/SentimentSatisfiedSharp";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import CircleIcon from "@mui/icons-material/Circle";
import EmojiPicker from "emoji-picker-react";
import storage, { db } from "../../../firebase";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import dummyProfile from "../../../images/CW/profile.jpeg";
import { getDiff, ImgUrl } from "../../../constants/const";
import "./Inbox.css";
import moment from "moment";
import welcomeImg from "../../../images/Group 33033.png";
import CloseIcon from "@mui/icons-material/Close";
import { sendChatNotification, generateAgoraToken, setSelectedChat } from "../../../redux/action/auth";
import { getOtherUserDetailwithCB } from "../../../redux/action/recipe";
import { getMyConnection } from "../../../redux/action/community";
import firebase from "firebase";
import { Spin } from "antd";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Chat = () => {
  const [id, setId] = useState(null);
  const uploadInputRef = useRef(null);
  const [input, setInput] = useState("");
  const [rooms, setRooms] = useState([]);
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOnline, setIsOnline] = useState("");
  const [userDetail, setUserDetail] = useState([]);
  const [image, setImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [showPicker, setShowPicker] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [TOKEN, SETTOKEN] = useState(null);
  const [isDatingConnection, setIsDatingConnection] = useState(false)
  const [chatImageUploadingLoader, setChatImageUploadingLoader] = useState(false)

  const user_data = useSelector((state) => state.dashboard.user_data);
  const selectedChat = useSelector((state) => state.auth.selectedChat);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appID = "ab0e65d266354bf684b91530ee7e481a";

  useEffect(() => {
    if (user_data?._id) {
      getChatList()
    }
  }, [user_data])

  const getChatList = () => {
    setLoading(true);
    let rk = null
    let data = null
    let activeChat = sessionStorage.getItem("activeChat") ? sessionStorage.getItem("activeChat") : null
    if (activeChat) {
      rk = getRoomName({ sender_id: user_data?._id, receiver_id: activeChat })
    }
    db.collection("Users").doc(user_data?._id).collection("Chats").orderBy("lastMsgTime", "desc").onSnapshot((querySnapshot) => {
      let getUserData = [];
      querySnapshot.forEach((doc) => {
        let dataa = doc.data()
        let pp = null;
        const callB = (resp) => {
          pp = resp?.profile_pic
          getUserData.push({ ...dataa, key: doc.id, is_online: false, toProfileImg: pp ?? dataa.toProfileImg });
          if (rk && rk == doc.id) {
            data = dataa
          }
        }
        dispatch(getOtherUserDetailwithCB({ other_user_id: dataa?.toUid }, callB))
      })
      setTimeout(() => {
        setRooms(getUserData)
        let activeChat = sessionStorage.getItem("activeChat") ? sessionStorage.getItem("activeChat") : null
        if (activeChat) {
          // make selected chat active
          renderUserData(data)
        } else {
          setLoading(false)
        }
      }, 2000)
    });
  }

  const onEmojiClick = (event, emojiObject) => {
    // console.log(emojiObject,"emojiObject>>>>>>>>..")
    // console.log(event,"event>>>>>>>>..")
    setInput((prevInput) => prevInput + event.emoji);
    document.getElementById("input-text").focus()
    // setShowPicker(false);
  };

  const uploadImage = (fileObj) => {
    setChatImageUploadingLoader(true)
    const uploadTask = firebase
      .storage()
      .ref(`images/${fileObj?.name}`)
      .put(fileObj);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case db?.storage?.TaskState?.PAUSED:
            break;
          case db?.storage?.TaskState?.RUNNING:
            break;
        }
      }, (error) => {
        setChatImageUploadingLoader(false)
        console.log("error ===>>>>>>>>>> ", error);
      }, () => {
        storage
          .ref("images")
          .child(fileObj?.name)
          .getDownloadURL()
          .then((downloadURL) => {
            setImageUrl(downloadURL);
            setChatImageUploadingLoader(false)
          });
      }
    );
  }

  const getRoomName = (data) => {
    let { sender_id, receiver_id } = data
    let temp = [sender_id, receiver_id].sort();
    let roomname = temp.join('_');
    return roomname;
  };

  // send message
  const submitResponse = () => {
    setShowPicker(false)
    try {
      let msgTxt = input;
      if (msgTxt == "" && imageUrl == null) {
        return false;
      }
      let rk = getRoomName({ sender_id: user_data?._id, receiver_id: id })
      let senderKey = rk
      db.collection("Chats")
        .doc(senderKey)
        .collection("messages")
        .add({
          from: user_data?.full_name,
          fromUid: user_data?._id,
          key: senderKey,
          msg: imageUrl ? imageUrl : msgTxt,
          time: moment().format("h:mma"),
          timestamp: moment().unix(),
          to: userDetail?.to,
          toProfileImg: userDetail?.toProfileImg,
          toUid: id,
          type: imageUrl ? "IMAGE" : "TEXT",
        })
        .then((docRef) => {
          db.collection("Chats").doc(senderKey).collection("messages").doc(docRef.id).update({
            msgId: docRef.id,
            timestamp: moment().unix(),
          });
          // sender last message update
          db.collection("Users").doc(user_data?._id).collection("Chats").doc(senderKey).set({
            date: moment().format("MM/DD/yyyy"),
            key: senderKey,
            lastMsg: imageUrl ? "file" : msgTxt,
            lastMsgTime: moment().unix(),
            onChatPage: true,
            to: userDetail?.to,
            toProfileImg: userDetail.toProfileImg,
            toUid: id,
            type: imageUrl ? "IMAGE" : "TEXT",
            unreadCount: 0
          });
          let recData = {
            date: moment().format("MM/DD/yyyy"),
            key: senderKey,
            lastMsg: imageUrl ? "file" : msgTxt,
            lastMsgTime: moment().unix(),
            to: user_data?.full_name,
            toProfileImg: user_data?.profile_pic,
            toUid: user_data?._id,
            type: imageUrl ? "IMAGE" : "TEXT",
            unreadCount: 1
          }
          db.collection("Users").doc(id).collection("Chats").doc(senderKey).get().then((ref) => {
            if (ref.exists) {
              db.collection("Users").doc(id).collection("Chats").doc(senderKey).update({
                ...recData,
                unreadCount: firebase.firestore.FieldValue.increment(1)
              })
            } else {
              db.collection("Users").doc(id).collection("Chats").doc(senderKey).set(recData)
            }
          });
          // fire notification to reciever
          const payload = {
            title: user_data?.full_name,
            body: imageUrl ? "File" : msgTxt,
            data: {
              type: "TEXT",
              name: user_data?.full_name,
              user_id: user_data?._id,
              image: user_data?.profile_pic,
              roomn_key: senderKey
            },
          };
          const data = {
            user_id: id,
            payload,
          };
          const cb = (resp) => {
            console.log("noti sent", resp)
          }
          dispatch(sendChatNotification(data, cb))
        });
      clearImage()
    } catch (err) {
      console.log(err);
    }
  };

  // Clear the Image box
  const clearImage = () => {
    setChatImageUploadingLoader(false)
    setInput("");
    setImage(false);
    setImageUrl(null);
  };

  // Data for main chat box
  const renderUserData = (data) => {
    clearImage()
    let pp = null
    const callB = (resp) => {
      pp = resp?.profile_pic
      setIsDatingConnection(false)
      let connections = []
      dispatch(getMyConnection((respo) => {
        connections = respo?.status ? respo?.data : []
        let dc = connections?.filter((c) => (c?.requested_by?._id == data?.toUid || c?.requested_to?._id == data?.toUid) && c?.status == "connected")
        if (dc?.length > 0) {
          setIsDatingConnection(true)
        }
      }))
      setId(data?.toUid);
      setUserDetail({ ...data, toProfileImg: pp ?? data?.toProfileImg });
      let rk = getRoomName({ sender_id: user_data?._id, receiver_id: data?.toUid })
      dispatch(setSelectedChat(rk))
      setLoading(false);
      setTimeout(() => {
        sessionStorage.removeItem("activeChat")
      }, 1000)
    }
    dispatch(getOtherUserDetailwithCB({ other_user_id: data?.toUid }, callB))
  };

  useEffect(() => {
    if (selectedChat) {
      showBadge()
      let unsubscribe = db.collection("Chats").doc(selectedChat).collection("messages").orderBy("timestamp", "asc").onSnapshot((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          setChats(
            querySnapshot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }))
          );
        } else {
          setChats([])
        }
      });
      return () => {
        unsubscribe()
      }
    }
  }, [selectedChat])

  useEffect(() => {
    if (id) {
      let unsub = db.collection("Users").doc(id).onSnapshot((snapshot) => {
        setIsOnline(snapshot?.data()?.online);
      });
      return () => {
        unsub()
      }
    }
  }, [id])

  const showBadge = () => {
    if (selectedChat) {
      db.collection("Users").doc(user_data?._id).collection("Chats").doc(selectedChat).update({
        unreadCount: 0
      });
    }
  }
  // Hit enter to send data...
  const checkEnterClicked = (inputObj) => {
    if (inputObj.keyCode === 13) {
      submitResponse();
    }
  };

  const startVideoCall = (otherUserData, call_type) => {
    // generate agora token
    const retrieveToken = (response) => {
      SETTOKEN(response?.status ? response?.data : null);
      if (response?.status ? response?.data : null) {
        buildPayloadAndFire(response?.data, otherUserData, call_type);
      }
    };
    dispatch(
      generateAgoraToken({ channel_name: user_data?._id, appID, }, retrieveToken)
    );
  };

  const buildPayloadAndFire = (token, otherUserData, call_type) => {
    const payload = {
      title: user_data?.full_name,
      body: "Incoming " + (call_type == "video" ? "Video" : "Audio") + " Call",
      sound: "incallmanager_ringtone.mp3",
      channel_id: "call",
      android_channel_id: "call",
      data: {
        type: call_type + "Call",
        name: user_data?.full_name,
        user_id: user_data?._id,
        image: user_data?.profile_pic,
        channelName: user_data?._id,
        token,
        call: false,
        sender_id: user_data?._id,
        receiver_id: otherUserData?.toUid
      },
    };
    const data = {
      user_id: otherUserData?.toUid,
      payload,
    };
    const navigateToCallScreen = () => {
      let callPayload = {
        appID,
        channel: user_data?._id,
        token,
        sender_id: user_data?._id,
        receiver_id: otherUserData?.toUid,
        receiver_name: otherUserData?.to,
        receiver_profile: otherUserData?.toProfileImg,
        type: call_type == "video" ? "Video" : "Voice",
        call_type: "Outgoing"
      };
      sessionStorage.setItem("callPayload", JSON.stringify(callPayload));
      // store call information to firestore
      let outgoingCallObj = {
        call_type,
        created_at: new Date(),
        type: "outgoing",
        user: {
          _id: otherUserData?.toUid,
          full_name: otherUserData?.to,
          profile_pic: otherUserData?.toProfileImg
        }
      }
      db.collection("call").doc(user_data?._id?.toString()).collection("list").doc().set(outgoingCallObj)
      db.collection('channel').doc(user_data?._id?.toString()).set({
        join: true,
        user1: user_data?._id,
        user2: otherUserData?.toUid,
        join1: true,
        join2: false,
        mphone2: true,
        mphone1: true,
        camera1: true,
        camera2: true,
      })
      if (call_type == "video") {
        navigate("/video_call");
      } else {
        navigate("/call");
      }
    };
    dispatch(sendChatNotification(data, navigateToCallScreen));
  };

  const innerWidth = window.innerWidth;
  const toggleChat = () => {
    if (innerWidth <= 768) {
      document.getElementById("hide_side_bar_click").style = 'display:none'
      document.getElementById("show_side_bar_click").style = 'display:block'
    }
  }

  const toggleBackChat = () => {
    if (innerWidth <= 768) {
      document.getElementById("show_side_bar_click").style = 'display:none'
      document.getElementById("hide_side_bar_click").style = 'display:block'
    }
  }

  useEffect(() => {
    return () => {
      dispatch(setSelectedChat(null))
    };
  }, []);

  return (
    <Card className="main_box_style">
      <Box style={{ display: "flex", flexDirection: "row", margin: "auto" }}>
        {/* Side Bar */}
        {loading ? (<div className="chat_left_section" style={{ display: "flex", justifyContent: "center", alignItems: "center", }}> <Spin tip="Loading..." style={{ color: 'orange' }} /> </div>
        ) : (
          <Stack
            className="chat_left_section" onClick={() => toggleChat()} id="hide_side_bar_click">
            {rooms.length > 0 ? (
              rooms?.map((data, id) => {
                return (
                  <List
                    className="sidebarChat"
                    onClick={() => { renderUserData(data, true) }}
                    key={id}>
                    <ListItem secondaryAction={getDiff(data?.lastMsgTime)}>
                      <Box className="liked_text_style">
                        <ListItemAvatar className="list_avatar">
                          <Badge
                            badgeContent={data?.unreadCount}
                            color="primary">
                            <Avatar
                              src={
                                data?.toProfileImg !== ""
                                  ? `${ImgUrl}${data?.toProfileImg}`
                                  : `${dummyProfile}`
                              }
                              style={{
                                height: "50px",
                                width: "50px",
                                border: "1px solid orange",
                              }}
                            />
                          </Badge>
                        </ListItemAvatar>
                        <ListItemText style={{ marginLeft: 10 }}>
                          <Typography
                            sx={{ fontWeight: "light" }}
                            className="chat_username">
                            {data?.to !== "" ? data?.to : "---"} &nbsp;
                          </Typography>
                          <Box
                            style={{ display: "flex" }}
                            className="chat_lastMsg">
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              className="chat_lastMsg">
                              {data?.lastMsg?.length > 15 ? <>
                                {data?.lastMsg?.slice(0, 15)}...
                              </>
                                : <>{data?.lastMsg}</>
                              }
                            </Typography>
                          </Box>
                        </ListItemText>
                      </Box>
                    </ListItem>
                  </List>
                );
              })
            ) : (
              <>No Chat to show...!</>
            )}
          </Stack>
        )}
        {/* Right Section */}
        <Box className="chat_right_section" xs={{ width: "100%" }} id="show_side_bar_click">
          {/* Chat Box */}
          <Box style={{ width: "100%" }}>
            <Box className="back_arrow_chat" onClick={() => toggleBackChat()}> <ArrowBackIcon fontSize="large" /> </Box>
            <Box className="semiMedium_style">
              {selectedChat ? (
                <Box className="" xs={{ width: "100%" }} >
                  <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 10px", boxShadow: " rgba(0, 0, 0, 0.16) 0px 3px 6px -6px, rgba(0, 0, 0, 0.23) 0px 3px 6px -6px", backgroundColor: "orange", margin: 0 }}>
                    <Avatar sx={{ width: 40, height: 40 }} src={userDetail?.toProfileImg === "" ? `${dummyProfile}` : `${ImgUrl}${userDetail?.toProfileImg}`} />
                    <ListItemText className="avatar_text_style_chat_header">
                      <p style={{ margin: "0", color: "#fff" }}>
                        {userDetail?.to} &nbsp; <CircleIcon style={{
                          color: isOnline === true ? "green" : "gray",
                          fontSize: "12px",
                        }} />
                      </p>
                      <p style={{ margin: "0", color: "#fff", fontSize: "10px", float: "left" }}> {isOnline === true ? "Online" : "Offline"} </p>
                    </ListItemText>
                    {isDatingConnection &&
                      <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <IconButton onClick={() => startVideoCall(userDetail, "audio")}> <img src={CallImg} className="audio_video_chat_style" alt="img" /> </IconButton>
                        <IconButton onClick={() => startVideoCall(userDetail, "video")}> <img src={VideoImg} className="audio_video_chat_style" alt="img" /> </IconButton>
                      </Box>
                    }
                  </Box>
                  {/* Chat Box  */}
                  <Box className="chatBox_inner_style" id="chatList">
                    <Box
                      style={{ margin: "20px 0 0 0", position: "relative" }}>
                      {chats?.map((message) => {
                        return (
                          <Box style={{ padding: "2px 5px", margin: "15px 5px", }} key={message?.id}>
                            <p className={`chat_message ${message.data.fromUid !== userDetail?.toUid && "chat_reciver"} `}>
                              {message?.data?.type === "TEXT" ? (<> {message?.data?.msg} </>
                              ) : (
                                <img src={message?.data?.msg} style={{ maxHeight: "175px", cursor: "pointer", }} sx={{ height: '50px' }} sm={{ height: '50px' }} alt='img' onClick={() => setModal2Open(message?.data?.msg)} />
                              )}
                              <span className="chat_timestamp">
                                {message?.data?.time}
                              </span>
                            </p>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                  {/* Message Input Box */}
                  {chatImageUploadingLoader &&
                    <Box
                      mt={2}
                      textAlign="center"
                      style={{
                        position: "absolute",
                        bottom: "125px",
                        marginLeft: "20px",
                        backgroung: "#fff"
                      }}>
                      <Spin /><br />
                      Uploading...
                    </Box>
                  }
                  {imageUrl ? (
                    <Box
                      mt={2}
                      textAlign="center"
                      style={{
                        position: "absolute",
                        bottom: "125px",
                        marginLeft: "20px",
                        backgroung: "#fff"
                      }}>
                      <Box
                        onClick={() => clearImage()}
                        style={{
                          cursor: "pointer",
                          float: "right",
                          fontSize: "large",
                          marginTop: "-18px"
                        }}>
                        <CloseIcon />
                      </Box>
                      <img
                        src={imageUrl}
                        alt={imageUrl}
                        style={{ height: "125px" }}
                      />
                    </Box>
                  ) : null}

                  <div className="send_msg_input">
                    <Input className="send_input"
                      id="input-text"
                      value={input}
                      onKeyDown={(e) => checkEnterClicked(e)}
                      onChange={(e) => setInput(e.target.value)}
                      placeholder="Type message..."
                      autoFocus
                      allowClear
                    />


                    <div className="emoji_hide_mobile">
                      <SentimentSatisfiedSharpIcon
                        style={{ color: "gray", cursor: "pointer" }}
                        onClick={() => setShowPicker((val) => !val)}
                      />
                      {showPicker && (<EmojiPicker onEmojiClick={onEmojiClick} />)}
                    </div>
                    {/* Attach File */}
                    <Button
                      onClick={() =>
                        uploadInputRef.current &&
                        uploadInputRef.current.click()
                      }
                      style={{
                        color: "gray",
                        margin: "0",
                        padding: "0",
                        width: "10px",
                      }}>
                      <>
                        <input
                          ref={uploadInputRef}
                          type="file"
                          accept="image/*"
                          multiple
                          style={{ display: "none" }}
                          files={image}
                          onChange={(e) => {
                            uploadImage(e.target.files[0]);
                          }}
                        />
                        <Box style={{ width: "fit-content" }}>
                          <AttachFileIcon fontSize="medium" />
                        </Box>
                      </>
                    </Button>
                    <button
                      type="submit"
                      onClick={submitResponse}
                      className="send_message_btn">
                      <SendIcon />
                    </button>


                  </div>
                </Box>
              ) : (
                <Box className="mt">
                  <img src={welcomeImg} alt="vmd_logo" className="mt-5" width="10%" />
                  <Typography variant="h5">Welcome to vegans chat</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {/* To preview image */}
        <Modal
          centered
          visible={modal2Open}
          onOk={() => setModal2Open(false)}
          onCancel={() => setModal2Open(false)}
          footer={null}>
          <img
            src={modal2Open}
            style={{
              padding: "10px",
              width: "100%",
              height: "auto",
              minHeight: "25vh",
            }}
            alt={modal2Open}
          />
        </Modal>
      </Box>
    </Card>
  );
};
export default Chat;