import React from "react";
import { Layout } from "antd";
import FLogo from "../../images/solidappmaker.png";
import "./DashFooter.css";

const { Footer } = Layout;
const DashFooter = () => {
  const currentYear = new Date();
  let year = currentYear.getFullYear();
  return (
    <>
      <Footer className='frame-footer-style' >
        &copy; {year} - All Rights Reserved - Vegansmeet Daily | Formally Vegansmeet.com | Designed &amp; Developed by <a href="https://solidappmaker.com/" target="_blank"><img className='dashfooter-style' src={FLogo} alt="APM__logo" /></a>
      </Footer>
    </>
  );
};
export default DashFooter;