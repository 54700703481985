import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardMedia, Grid, IconButton, InputBase, Typography } from "@mui/material";
import ImgLogo from "../../../images/Group 33033.png";
import "./CConnection.css";
import remove from "../../../images/CW/removeIcon.png";
import info from "../../../images/CW/Path 22517.png";
import chat from "../../../images/CW/chatbox-ellipses.png";
import { Link } from "react-router-dom";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import dummyProfile from "../../../images/CW/profile.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteFriend, getFriendList } from "../../../redux/action/community";
import { ImgUrl } from "../../../constants/const";
import Spinner from "../../../constants/Spinner";
import { db } from "../../../firebase";
import moment from "moment/moment";

const CConnection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [data1, setData1] = useState();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const getCommunityConnectionData = useSelector((state) => state.community?.get_friend_data?.data);
  const userData = useSelector((state) => state.dashboard?.user_data);

  const handleCloseAccept = () => {
    setOpen(false);
    setLoading(true);
    let token = localStorage.getItem("token");
    const successCb = (resp) => {
      if (resp.status === true) {
        dispatch(getFriendList(successCb));
        setLoading(false);
      }
    };
    let _id = data1;
    dispatch(deleteFriend({ _id }, token, successCb));
  };

  useEffect(() => {
    const successCb = (resp) => {
      // toast.success(resp?.message);
    };
    dispatch(getFriendList(successCb));
  }, []);

  useEffect(() => {
    if (getCommunityConnectionData && loading) {
      setLoading(false);
    }
  }, [getCommunityConnectionData]);

  const handleClickOpen = (_id) => {
    setOpen(true);
    setData1(_id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getRoomName = (data) => {
    let { sender_id, receiver_id } = data
    let temp = [sender_id, receiver_id].sort();
    let roomname = temp.join('_');
    return roomname;
  };

  const manageChat = async (data) => {
    // let senderSideKey = data?.sender_data?._id+"_"+data?.receiver_data?._id
    let rk = getRoomName({ sender_id: data?.sender_data?._id?.toString(), receiver_id: data?.receiver_data?._id?.toString() })
    let senderSideKey = rk
    db.collection("Users").doc(data?.sender_data?._id?.toString()).collection("Chats").doc(senderSideKey?.toString()).get().then((senderKeyRef) => {
      if (!senderKeyRef.exists) {
        db.collection("Users").doc(data?.sender_data?._id?.toString()).collection("Chats").doc(senderSideKey?.toString()).set({
          date: moment().format("MM/DD/YYYY"),
          key: senderSideKey?.toString(),
          lastMsg: "",
          lastMsgTime: new Date().getTime(),
          to: data?.receiver_data?.full_name,
          toProfileImg: data?.receiver_data?.profile_pic,
          toUid: data?.receiver_data?._id,
          type: "TEXT",
          unreadCount: 0
        })
      }
    })
    db.collection("Users").doc(data?.receiver_data?._id?.toString()).collection("Chats").doc(senderSideKey?.toString()).get().then((receiverKeyRef) => {
      if (!receiverKeyRef.exists) {
        db.collection("Users").doc(data?.receiver_data?._id?.toString()).collection("Chats").doc(senderSideKey?.toString()).set({
          date: moment().format("MM/DD/YYYY"),
          key: senderSideKey?.toString(),
          lastMsg: "",
          lastMsgTime: new Date().getTime(),
          to: data?.sender_data?.full_name,
          toProfileImg: data?.sender_data?.profile_pic,
          toUid: data?.sender_data?._id,
          type: "TEXT",
          unreadCount: 0
        })
      }
    })
    db.collection("Chats").doc(senderSideKey?.toString()).get().then((ref) => {
      if (!ref.exists) {
        db.collection("Chats").doc(senderSideKey?.toString()).set({
          from: data?.sender_data?.full_name,
          fromUid: data?.sender_data?._id,
          key: senderSideKey?.toString(),
          msg: "Hi",
          time: moment().format("h:mma"),
          timestamp: moment().unix(),
          to: data?.receiver_data?.full_name,
          toProfileImg: data?.receiver_data?.profile_pic,
          toUid: data?.receiver_data?._id,
          type: "TEXT"
        })
      }
    })
    setLoading(true)
    setTimeout(() => {
      sessionStorage.setItem("activeChat", data?.receiver_data?._id)
      setLoading(false)
      navigate("/inbox")
    }, 2000)
  }

  return (
    <>
      {loading ? (<Spinner />
      ) : (
        <>
          <div>
            <Typography className="common_medium_font mb-3">Community Connection</Typography>
            {getCommunityConnectionData.length > 0 ?
            <Box style={{ borderRadius: "36px" }} sx={{ p: "2px 4px", m: "10px 0", display: "flex", alignItems: "center", width: "1", background: "#ececec", }} >
              <IconButton sx={{ p: "10px" }} aria-label="search" >
                <PersonSearchIcon  />
              </IconButton>
              <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search Community Connection" inputProps={{ "aria-label": "Search" }} onChange={(e) => setSearch(e.target.value)} />
            </Box>
            :""}
            <Box style={{ maxHeight: "90vh", overflowY: "auto" }}>
              <Grid container spacing={2}>
                {getCommunityConnectionData.length > 0 ? (
                  getCommunityConnectionData.filter((val) => {
                    if (search === "") {
                      return val
                    } else if (val?.follow_to?.full_name?.toLowerCase()?.includes(search?.toLowerCase()) || val?.follow_by?.full_name?.toLowerCase()?.includes(search?.toLowerCase())) {
                      return val
                    }
                  })
                    ?.map((data, id) => {
                      return (
                        <>
                          {data?.follow_by?._id === userData?._id ? (
                            <>
                              <Grid item xs={12} sm={6} md={4} lg={2} key={id}>
                                <Card sx={{ width: 1 }} style={{ margin: "0", padding: "0", boxSizing: "border-box", position: "relative", boxShadow: "9px 9px 31px -10px rgba(0,0,0,0.75)", borderRadius: "12px", border: "2px solid #F8931F",  background:'#F8931F', height:200}}>
                                  <CardMedia component="img" alt="vegan_meet_daily" image={data?.follow_to.profile_pic != "" ? `${ImgUrl}${data?.follow_to?.profile_pic}` : `${dummyProfile}`} style={{ margin: "0", padding: "0", height:'85%' }} />
                                  <Typography className="pr-dating-logo-text-icon-style">
                                    <img src={ImgLogo} style={{ height: "35px", cursor: "pointer", }} alt="logo-img" />
                                  </Typography>
                                  <Typography className="pr-dating-text-icon-style">
                                    <Link style={{ color: "#fff", fontWeight:'600' }} to={`/cc_profile/${data?.follow_to?._id}`}>
                                      {data?.follow_to?.full_name}&nbsp;<img src={info} style={{ height: "13px", cursor: "pointer", }} alt="logo-img" />
                                    </Link>
                                  </Typography>
                                  <Typography className="pr-dating-remove-icon-style">
                                    <img src={remove} style={{ height: "25px", cursor: "pointer", }} alt="logo-img" onClick={() => handleClickOpen(data?._id)} />
                                  </Typography>
                                  <Typography className="pr-dating-chat-icon-style">
                                    <img onClick={() => manageChat({ sender_data: userData, receiver_data: data?.follow_to })} src={chat} style={{ height: "22px", cursor: "pointer", }} alt="logo-img" />
                                  </Typography>
                                </Card>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={12} sm={6} md={4} lg={2} key={id}>
                                <Card sx={{ width: 1 }} style={{ height:200, boxSizing: "border-box", position: "relative", boxShadow: "9px 9px 31px -10px rgba(0,0,0,0.75)", borderRadius: "7px", border: "2px solid #F8931F",  background:'#F8931F', height:200}}>
                                  <CardMedia component="img" alt="vegan_meet_daily" style={{height:'85%'}} image={data?.follow_by?.profile_pic != "" ? `${ImgUrl}${data?.follow_by?.profile_pic}` : dummyProfile} />
                                  <Typography className="pr-dating-logo-text-icon-style">
                                    <img src={ImgLogo} style={{ height: "35px", cursor: "pointer", }} alt="logo-img" />
                                  </Typography>
                                  <Typography className="pr-dating-text-icon-style">
                                    <Link style={{ color: "#fff", fontWeight:'600' }} to={`/cc_profile/${data?.follow_by?._id}`}>
                                      {data?.follow_by?.full_name}&nbsp;<img src={info} style={{ height: "13px", cursor: "pointer", }} alt="logo-img" />
                                    </Link>
                                  </Typography>
                                  <Typography className="pr-dating-remove-icon-style">
                                    <img src={remove} style={{ height: "25px", cursor: "pointer", }} alt="logo-img" onClick={() => handleClickOpen(data?._id)} />
                                  </Typography>
                                  <Typography className="pr-dating-chat-icon-style">
                                    <img onClick={() => manageChat({ sender_data: userData, receiver_data: data?.follow_by })} src={chat} style={{ height: "22px", cursor: "pointer", }} alt="logo-img" />
                                  </Typography>
                                </Card>
                              </Grid>
                            </>
                          )}
                        </>
                      );
                    })
                ) : (
                  <Box style={{ display: "flex", color: "orange", margin: "20px 0 0 30px", }} > No Friends Found Yet </Box>
                )}
              </Grid>
            </Box>

            <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title" >
              <DialogContent>
                <DialogContentText style={{ color: "#000", fontFamily: "Montserrat", fontWeight: "normal", marginTop: "15px 0", }} >
                  Are you sure you want to unfriend this connection?
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ display: "flex", justifyContent: "space-between" }} >
                <Button onClick={handleClose} style={{ color: "#000" }}> <strong>No</strong> </Button>
                <Button onClick={handleCloseAccept} style={{ color: "#F8931F" }} > <strong>Yes</strong> </Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
      )}
    </>
  );
};
export default CConnection;