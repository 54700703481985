import React, { lazy, Suspense, useEffect, useState } from "react";
import "./App.css";
import { Provider } from "react-redux";
import store from "./store";
import { getToken } from "./firebase"
import Frame2 from "./components/Frame2/Frame2"
import { Helmet } from "react-helmet";
import Spinner from "./constants/Spinner";
const AppRouter = lazy(() => import("./screen/Formroutes"));

const App = () => {

  const [isTokenFound, setTokenFound] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const temp = async () => {
      localStorage.setItem("fcm", await getToken(setTokenFound))
    }
    temp();
  }, [])

  return (
    <>
      <Provider store={store}>
        <Suspense
          fallback={<Spinner />}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Vegans Meet Daily</title>
            <meta property="og:image" content="https://vegansmeetdaily.com/static/media/signuplogo.88a7acf393069551f008.png" />
          </Helmet>
          {token ? <Frame2 /> :
            <AppRouter />}
        </Suspense>
      </Provider>
    </>
  );
};

export default App;
