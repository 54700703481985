import firebase from "firebase";
import 'firebase/messaging';
import 'firebase/storage'
import Toast from "./constants/toast"

const firebaseConfig = {
  apiKey: "AIzaSyDlLY2G6Ge4gUjqoH15AtVW6JhOT5ICr0w",
  authDomain: "vegans-435ba.firebaseapp.com",
  projectId: "vegans-435ba",
  storageBucket: "vegans-435ba.appspot.com",
  messagingSenderId: "75431939694",
  appId: "1:75431939694:web:1c338be62950e6071ea07f",
  measurementId: "G-RDLV8X7DPT"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const db = app.firestore();
const storage = app.storage();
export default storage
let messaging = null
if (navigator.vendor !== 'Apple Computer, Inc.') {
  // console.log("Running on Other Browser")
  messaging = app.messaging();
} else {
  console.log("Running on Safari")
}

const REACT_APP_VAPID_KEY = "BI3LjAF_N-LEVdpD8vh2f8T3HM6lWfho8-NE7216TSYq1YRN1PKSFqX1HWKg8vVUYnHkfF1imYHyp1wCuvRvP7Q"

export const getToken = async (setTokenFound) => {
  let currentToken = '';
  try {
    currentToken = await messaging?.getToken({ vapidKey: REACT_APP_VAPID_KEY });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log('An error occurred while retrieving token.', error);
  }
  return currentToken;
};

export const onMessageListener = (navRec) =>
  new Promise((resolve, reject) => {
    try {
      messaging?.onMessage((payload) => {
        if (payload?.data?.type == "TEXT") {
          Toast.success(payload?.notification?.body, "New Message from " + payload?.data?.name)
        }
        console.log("at main method", payload)
        if (navRec) {
          navRec(payload);
        }
      });
    } catch (err) {
      console.error(err)
    }
  });