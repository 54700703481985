import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import NavItems from "./NavItems";
import { Link, useLocation, useNavigate } from "react-router-dom";
import frameLogo from "../../images/navasset/framelogo.png";
import { Badge, Box } from "@mui/material";
import { MenuItem, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { sidebarBadgesdata } from "../../redux/action/dashboard";
import toast from '../../constants/toast';

const { Sider } = Layout;

const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [page, setPage] = useState("Home");

  const badgedata = useSelector((state) => state.dashboard?.badgesdata_sidebar);
  const likedBy = badgedata?.filter((data) => { return (data?.key === "Liked" ? data?.badge : "") })
  const prCommunity = badgedata?.filter((data) => { return (data?.key === 'Pending_Request Community' ? data?.badge : '') })
  const prDating = badgedata?.filter((data) => { return (data?.key === 'Pending_Request Dating' ? data?.badge : '') })

  // console.log("prDating_badge", prDating)
  useEffect(() => {
    dispatch(sidebarBadgesdata())
  }, [])

  const user_data = useSelector((state) => state?.dashboard?.user_data);

  useEffect(() => {
    activeNavigation(location.pathname);
  }, [page, { ...location }]);

  const activeNavigation = (path) => {
    switch (path) {
      case "/dashome":
        setPage("Home");
        break;
      case "/inbox":
        setPage("Inbox");
        break;
      case "/liked":
        setPage("Liked By");
        break;
      case "/DDatingCon":
        setPage("Dating Connection");
        break;
      case "/CConnection":
        setPage("Community Connection");
        break;
      case "/PendingReq":
        setPage("Pending Request");
        break;
      case "/DPlace":
        setPage("Places");
        break;
      case "/DCommunity":
        setPage("Community");
        break;
      case "/DDating":
        setPage("Dating");
        break;
      case "/DRacipes":
        setPage("Recipes");
        break;
      case "/DAbout":
        setPage("About");
        break;
      case "/Feedback":
        setPage("Send Feedback");
        break;
      case "/Terms":
        setPage("Terms & Conditions");
        break;
      case "/Help":
        setPage("Help");
        break;
      case "/Account":
        setPage("Account settings");
        break;
      case "/delete_Account":
        setPage("Delete Account");
        break;
      default:
        setPage("DashHome");
    }
  };

  return (
    <Menu selectedKeys={[page]} selectable={true} mode="inline" >
      <Box className="dtop-logo">
        <Link to="/dashome"> <img src={frameLogo} alt="framelogo" width="60%" /> </Link>
      </Box>
      <Box className="sideBar">
        <Menu className="sider-styling">
          {NavItems.map((data, i) => (
            <Link onClick={() => data?.title == "Dating" && user_data?.dating == 0 ? toast.error("Your dating feature is disabled") : null} to={data?.title == "Dating" && user_data?.dating == 0 ? "#" : data.link} key={i}>
              <MenuItem className="menu-item-styling" onClick={() => { setPage(data?.title); navigate(data?.link) }}>
                <Box className="active-style-text py-1" style={{ color: page === data?.title ? "#f8931f" : "#000" }}>
                  <img src={page === data.title ? data?.colorIcon : data?.icon} alt="icon" width={22} />
                  <Typography className="sider_font d-flex align-items-center">
                    {data?.title}
                    {likedBy?.length > 0 ?
                      <Badge className="ms-3" color="primary" max={99} badgeContent={data.title == "Liked By" ? likedBy?.map((data) => { return (data?.badge) }) : 0} />
                      : data.title == "Liked By"}

                    {prCommunity?.length > 0 || prDating?.length > 0 ?
                      // <Badge color="primary" max={99} badgeContent={data.title == "Pending Request" ? prCommunity?.filter((data) => { console.log("i'm1", data?.badge) }) + prDating && prDating?.filter((data) => { console.log("i'm2", data?.badge) }) : 0} />
                      <Badge color="primary" max={99} badgeContent={data.title == "Pending Request" ? prCommunity?.filter((data) => { return (data?.badge) })?.length + prDating?.filter((data) => { return (data?.badge) })?.length : 0} />
                      : data.title == "Pending Request"}
                  </Typography>
                </Box>
              </MenuItem>
            </Link>
          ))}
        </Menu>
      </Box>
    </Menu>
    // </Sider>
  );
};
export default SideBar;