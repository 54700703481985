import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { load, manageCam, manageMic } from "./simpleVCall"
import "./simpleVCall.css"

import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';

import CallEndIcon from '@mui/icons-material/CallEnd';

import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import VideocamIcon from '@mui/icons-material/Videocam';

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useNavigate } from "react-router-dom";

import { sendChatNotification } from "../../../redux/action/auth";
import { db } from "../../../firebase"
import { useDispatch, useSelector } from "react-redux";
import dummyProfile from "../../../images/CW/profile.jpeg";
import { ImgUrl } from "../../../constants/const";


const VideoCall = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  let payload = null
  const [video, setVideo] = useState(true)
  const [audio, setAudio] = useState(true)
  const [timer, setTimer] = useState(false)
  const [seconds, setSeconds] = useState(0)
  const [callPayload, setCallPayload] = useState(null)
  const timerStatus = useSelector((state) => state.common.timer_status)
  let callInterval = null

  const showTimer = () => {
    setTimer(true)
    let secs = seconds
    callInterval = setInterval(() => {
      secs++
      setSeconds(secs)
    }, 1000)
  }

  const time_convert = (num) => {
    var minutes = Math.floor(num / 60);
    if (minutes <= 9) {
      minutes = "0" + minutes
    }
    var seconds = num % 60;
    if (seconds <= 9) {
      seconds = "0" + seconds
    }
    return minutes + ":" + seconds;
  }

  const leaveCall = () => {
    sessionStorage.removeItem("start-timer")
    clearInterval(callInterval)
    setTimer(null)
    setSeconds(0)
    let notifPayload = {
      action: "end-call"
    }
    let data = {
      user_id: callPayload?.sender_id,
      payload: notifPayload
    }
    const cb = () => {
      db.collection('channel').doc(callPayload?.sender_id ?? callPayload?.channel).set({ join: false }, { merge: true })
      sessionStorage.removeItem("callPayload")
      navigate('/inbox')
      window.location.reload()
    }
    dispatch(sendChatNotification(data))
    dispatch(sendChatNotification({ user_id: callPayload?.receiver_id, payload: notifPayload }, cb))
  }

  const handleCam = (state) => {
    setVideo(!state)
    manageCam(state)
  }

  const handleMic = (state) => {
    setAudio(!state)
    manageMic(audio)
  }

  const renderName = () => {
    let p = sessionStorage.getItem("callPayload") ? JSON.parse(sessionStorage.getItem("callPayload")) : null
    return p?.call_type == "Outgoing" ? p?.receiver_name ?? "unknown" : p?.call_type == "Incoming" ? p?.sender_name ?? "unknown" : "unknown"
  }

  const renderProfile = () => {
    let p = sessionStorage.getItem("callPayload") ? JSON.parse(sessionStorage.getItem("callPayload")) : null
    let img = p?.call_type == "Outgoing" ? p?.receiver_profile ?? "" : p?.call_type == "Incoming" ? p?.sender_name ?? "" : ""
    if (img == "") {
      return dummyProfile
    }
    return ImgUrl + img
  }

  useEffect(() => {
    if (timerStatus) {
      showTimer()
    }
  }, [timerStatus])

  useEffect(() => {
    payload = JSON.parse(sessionStorage.getItem("callPayload"))
    setCallPayload(payload)
    load()
  }, [])

  return (
    <>
      <ArrowBackIcon className="m-4 mb-0" style={{ cursor: "pointer" }} onClick={() => leaveCall()} />
      <div class="row video-group m-4" style={{ border: "2px solid #ccc", borderRadius: "5px", overflow: "hidden", height: "90vh" }}>
        <div class="col-12 p-0">
          <div id="remote-playerlist" class="player" style={{ textAlign: "center" }}>
            <img className="img-fluid player" src={`${renderProfile()}`} />
          </div>
          <div className="col-12 text-center">
            <div style={{ background: "#7DB785" }} className={"pt-1 pb-1"}>
              <p style={{ fontWeight: 400, fontSize: "16px", color: "white" }} className={"p-0 mt-2"}>{renderName()}<br />
                {timer == true ? time_convert(seconds) : timer == false ? "Calling..." : "Disconnected"}</p>
              <Button
                onClick={() => handleMic(audio)}
                className="btn mx-1" style={{ background: "#6C757D" }}>
                {audio ?
                  <MicIcon style={{ color: "#fff" }} />
                  : <MicOffIcon style={{ color: "#fff" }} />
                }
              </Button>
              <Button
                onClick={() => handleCam(video)}
                className="btn mx-1" style={{ background: "#017BFE" }}>
                {video ?
                  <VideocamIcon style={{ color: "#fff" }} />
                  : <VideocamOffIcon style={{ color: "#fff" }} />
                }
              </Button>
              <Button
                onClick={() => leaveCall()}
                className="btn mx-1" style={{ background: "#DC3444" }}>
                <CallEndIcon style={{ color: "#fff" }} />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div id="local-player" className="local-player"></div>
      </div>
    </>
  );
};
export default VideoCall;