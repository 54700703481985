import React, { useState } from "react";
import {
  Layout,
  Avatar,
  Col,
  Row,
  Input,
  Tooltip,
  message,
  Button
} from "antd";
import {
  SendOutlined,
  PaperClipOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import "./Inbox.css";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import InboxProfile from "../../../images/CW/Group 33070@2x.png";
import Profile from "../../../images/CW/Group 33070@2x.png";
import Chat from "./Chat";
import Calls from "./Calls";

const { Header, Sider, Content, Footer } = Layout;

const Inbox = () => {
  // const [inboxmsg, setInboxMsg] = useState("")
  const [active, setActive] = useState("M");
  const [activeIndex, setActiveIndex] = useState("1");
  const inboxData = [
    {
      id: "1",
      text: "Messages",
      fun: "M",
    },
    {
      id: "2",
      text: "Calls",
      fun: "C",
    },
  ];

  return (
    <div className="common_dash_contnet">
      <Typography className="common_medium_font">
        Inbox
      </Typography>

      {/* Buttons */}
      <div className="d-flex"> 
        {inboxData.map((data, key) => {
          return (
            <Button
              className="common_btn my-3 me-lg-3"
              onClick={() => {
                setActiveIndex(data.id);
                setActive(data.fun);
              }}
              style={{
                backgroundColor: activeIndex === data.id ? "#F8931F" : "#fff",
                width:"30%",
                // padding:"0 4rem"
              }}
              key={key}
            >
              <Typography
                className="pr_btn_text_style"
                style={{
                  color: activeIndex === data.id ? "#fff" : "#F8931F",
                  textTransform: "capitalize",
                  fontSize: "13px",
                  fontFamily: "Montserrat",
                }}
              >
                {data.text}
              </Typography>
            </Button>
          );
        })}
      </div>
      {active === "M" && <Chat />}
      {active === "C" && <Calls />}
    </div>
  );
};

export default Inbox;
