import React, { useEffect, useState } from 'react'
import { Input, Spin, Button } from 'antd';
import { deleteUserAccount } from '../../../redux/action/dashboard';
import toast from '../../../constants/toast';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
const { TextArea } = Input;

const DeleteAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [delDesc, setDelDesc] = useState('')
  const [loading, setLoading] = useState(false)

  const delAccount = () => {
    setLoading(true)
    if (delDesc) {
      const successCb = (resp) => {
        localStorage.clear()
        setLoading(false)
        window.location.reload()
        navigate('/register')
      }
      const failureCallback = (resp) => {
        setLoading(false)
      }
      let data = { delete_reason: delDesc }
      dispatch(deleteUserAccount(data, successCb, failureCallback))
    } else {
      setLoading(false)
      toast.error('Delete reason is required!')
    }
  }

  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle
        adsbygoogle.push({})
      } catch (e) {
        console.error('error', e)
      }
    }

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd()
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval)
      }
    }, 300)

    return () => {
      clearInterval(interval)
    }
  }, [])
  return (
    <div className='text-center'>
      <TextArea autoSize={{ minRows: 10, maxRows: 12 }} className="feedback_input_box mb-xxl-5 mb-4" placeholder="why do you want to delete your account..." style={{ resize: 'none', borderRadius: 5, }} value={delDesc} onChange={(e) => setDelDesc(e.target.value)} maxLength={500} />
      <Button loading={loading} onClick={() => delAccount()} className='common_btn'>Delete Account</Button>


      {/* <GoogleAdSense
        html={
          '<ins class="adsbygoogle"\n' +
          'style="display:block"\n' +
          'data-ad-client="ca-pub-2720420356744936"\n' +
          'data-ad-slot="6140819245"\n' +
          'data-full-width-responsive="true"></ins>'
        }
      /> */}
    </div>
  )
}

export default DeleteAccount