import React from "react";
import spinner from "../images/CW/loader.gif";

const color = {
  color: "#f8931f",
}

const Spinner = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        height:"90vh",
        alignSelf:"center"
      }}
    >
      <img
        src={spinner}
        style={{height:"150px", width:"150px"}}
        alt="Loading..."
      />
    </div>
  );
};

export default Spinner;
