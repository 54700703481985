import React, { memo, useEffect, useState } from "react";
import { Card, Typography, CardMedia, CardContent, CardActionArea, Paper, InputBase } from "@mui/material";
import { useJsApiLoader, GoogleMap, MarkerF, InfoWindow } from "@react-google-maps/api";
import viewMap from "../../../images/CW/Group 17280@2x.png";
import viewMapAlternet from "../../../images/CW/Group 17336.png";
import { Box } from "@mui/system";
import Slider from "react-slick";
import NoImage from "../../../images/CW/no_images.png";
import { GOOGLE_MAP_APIKEY } from "../../../constants/const";
import { IconButton } from "@mui/material";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import Spinner from "../../../constants/Spinner";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { googlePlaceApi } from "../../../redux/action/dashboard";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "antd";

const placeDataButton = [
  {
    id: "1",
    text: "Restaurants",
    fun: "RS",
  },
  {
    id: "2",
    text: "Farmer Markets",
    fun: "FM",
  },
];

const containerStyle = {
  width: "100%",
  height: "400px",
};

const MapCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [active, setActive] = useState("RS");
  const [activeIndex, setActiveIndex] = useState(localStorage.getItem("ai") ?? "1");
  const [search, setSearch] = useState("");
  const [center, setCenter] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [sliderRef, setSliderRef] = useState(null);

  const getRestroData = useSelector((state) => state?.dashboard?.google_place?.results);

  const searchData = (e) => {
    setSearch(e.target.value);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        },
      },
    ],
  }

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_APIKEY,
  });

  const RestroButton = (id) => {
    setLoading(true);
    localStorage.setItem("ai", id)
    navigator.geolocation.getCurrentPosition((position) => {
      let lat = position.coords.latitude
      let lng = position.coords.longitude
      let center = {
        lat, lng
      }
      setCenter(center)
    }, function (error) {
      console.error("Error Code = " + error.code + " - " + error.message);
    });
  }

  const mapRandor = () => {
    let dataa = getRestroData?.filter((val) => {
      if (search === "") {
        return val
      } else if (val?.name?.toLowerCase()?.includes(search?.toLowerCase())) {
        return val
      }
    })

    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        position={center}
        zoom={11}
        options={{ zoomControl: false, streetViewControl: false, mapTypeControl: false, fullscreenControl: false }}
        mapContainerClassName="map-container">
        {dataa?.map((value, index) => {
          let lat = value?.geometry?.location?.lat;
          let lng = value?.geometry?.location?.lng;
          return (
            <MarkerF
              position={{ lat, lng }}
              onClick={() => {
                setSelectedMarker({ position: { lat, lng }, name: value?.name ?? "" });
                sliderRef.slickGoTo(index)
              }}
            />
          )
        })}
        {selectedMarker &&
          <InfoWindow
            onCloseClick={() => setSelectedMarker(null)}
            position={selectedMarker?.position}>
            <p>{selectedMarker?.name ?? ""}</p>
          </InfoWindow>}
      </GoogleMap>
    )
  }

  useEffect(() => {
    let ai = localStorage.getItem("ai") ?? "1"
    RestroButton(ai)
  }, [])

  useEffect(() => {
    const { lat, lng } = center
    let locationStr = lat + "," + lng;
    locationStr = locationStr.trim();
    locationStr = locationStr == "," ? "" : locationStr;
    if (locationStr != "") {
      const successCb = () => {
        setLoading(false);
      }
      const failureCallback = () => {
        setLoading(false);
      }
      let ai = localStorage.getItem("ai") ?? "1"
      const data = {
        coordinates: locationStr,
        type: ai == 1 ? 'vegan' : 'farmers_market',
      }
      dispatch(googlePlaceApi(data, successCb, failureCallback))
    }
  }, [center])

  if (!isLoaded) return <Spinner />;

  return (
    <>
      <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20, alignItems: 'center' }}>
        <div style={{ display: "flex" }} className="width_manage">
          {placeDataButton.map((data, key) => {
            return (
              <Button
                className="common_btn w-100 me-lg-3"
                onClick={() => {
                  setActiveIndex(data.id);
                  setActive(data.fun); RestroButton(data?.id)
                }}
                style={{ backgroundColor: activeIndex === data.id ? "#F8931F" : "#fff", }}
                key={key} >
                <Typography style={{ color: activeIndex === data.id ? "#fff" : "#F8931F", textTransform: "capitalize", fontSize: "13px", fontFamily: "Montserrat", fontWeight: "500", }} > {data.text} </Typography>
              </Button>
            );
          })}
        </div>
        <Typography onClick={() => navigate("/DPlace")} className="" style={{ cursor: "pointer" }} > <CardMedia component="img" alt="view_map" height="70" image={active == "M" ? viewMap : viewMapAlternet} /> </Typography>
      </Box>
      <Box className="dplace_search_width_style1">
        <Paper sx={{ p: "0 4px", display: "flex", alignItems: "center", width: "1", background: "#F89601", }} style={{ height: "50px", font: "normal 18px/20px Montserrat" }} >
          <InputBase sx={{ ml: 1, flex: 1, color: "#fff" }} placeholder="Search Places" inputProps={{ "aria-label": "Search" }} value={search} onChange={searchData} />
          <IconButton sx={{ p: "10px" }} aria-label="search" > <SearchIcon style={{ color: "#fff", fontSize: "32px", fontWeight: "900", }} /> </IconButton>
        </Paper>
      </Box>
      {loading ? <Spinner /> :
        <>
          <Card className="rander_map_style">{(center?.lat && getRestroData?.length > 0) ? mapRandor() : null} </Card>
          <Box style={{ marginTop: "-85px " }}>
            <Box style={{ width: '96%', margin: 'auto' }}>
              {/* <Slider ref={(slider) => setSliderRef(slider)} {...settings}>
                {getRestroData?.filter((val) => {
                  if (search === "") {
                    return val
                  } else if (val?.name?.toLowerCase()?.includes(search?.toLowerCase())) {
                    return val
                  }
                })
                  ?.map((value) => {
                    let Lat = value?.geometry?.location?.lat;
                    let Lng = value?.geometry?.location?.lng;
                    return (
                      <Box key={value?.name}>
                        <Card sx={{ border: "1px solid green", borderRadius: "18px", margin: '0 5px' }} >
                          <CardActionArea>
                            <CardMedia component="img" height="120" image={value?.photos?.length > 0 ? "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&maxheight=400&photoreference=" + value?.photos[0]?.photo_reference + "&key=" + GOOGLE_MAP_APIKEY : NoImage} alt="restro_img" />
                            <CardContent style={{ background: "#f7f7f7" }}>
                              <Typography variant="h5" style={{ fontSize: "16px", fontWeight: "bold", wordBreak: "keep-all", width: "100%", }} gutterBottom >
                                {value?.name.slice(0, 30)}
                              </Typography>
                              <Box style={{ display: "flex", justifyContent: 'space-between', textAlign: 'center', alignItems: 'center' }}>
                                <Typography variant="body2" style={{ fontSize: "12px", color: "#000", textAlign: "initial" }} > {value?.formatted_address} </Typography>
                                <a target="_blank" href={`https://www.google.com/maps/place/${Lat},${Lng}`} >
                                  <IconButton variant="body2" style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", }} >
                                    <AssistantDirectionIcon style={{ color: "#fff", float: "right", background: "#000", borderRadius: "50%", fontSize: "30px", }} />
                                  </IconButton>
                                </a>
                              </Box>
                              <Typography style={{ display: 'flex', justifyContent: 'center', color: 'orange' }} onClick={() => (window.location.href = `/#/restaurant/${value?.place_id}`)}>View Details</Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Box>
                    );
                  })}
              </Slider> */}
              <Slider ref={(slider) => setSliderRef(slider)} {...settings}>
                {getRestroData?.filter((val) => {
                  if (search === "") {
                    return val
                  } else if (val?.name?.toLowerCase()?.includes(search?.toLowerCase())) {
                    return val
                  }
                })
                  ?.map((value) => {
                    let Lat = value?.geometry?.location?.lat;
                    let Lng = value?.geometry?.location?.lng;
                    return (
                      <Box key={value?.name}>
                        <Card sx={{ border: "1px solid green", borderRadius: "18px", margin: '0 5px' }} >
                          <CardActionArea>
                            <CardMedia component="img" height="120" image={value?.photos?.length > 0 ? "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&maxheight=400&photoreference=" + value?.photos[0]?.photo_reference + "&key=" + GOOGLE_MAP_APIKEY : NoImage} alt="restro_img" />
                            <CardContent style={{ background: "#f7f7f7" }}>
                              <Typography variant="h5" style={{ fontSize: "16px", fontWeight: "bold", wordBreak: "keep-all", width: "100%", }} gutterBottom >
                                {value?.name.slice(0, 12)}...
                              </Typography>
                              <Box style={{ display: "flex", justifyContent: 'space-between', textAlign: 'center', alignItems: 'center' }}>
                                <Typography variant="body2" style={{ fontSize: "12px", color: "#000", textAlign: "initial" }} > {value?.formatted_address.slice(0, 30)}...
                                </Typography>
                                <a target="_blank" href={`https://www.google.com/maps/place/${Lat},${Lng}`} >
                                  <IconButton variant="body2" style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", }} >
                                    <AssistantDirectionIcon style={{ color: "#fff", float: "right", background: "#000", borderRadius: "50%", fontSize: "30px", }} />
                                  </IconButton>
                                </a>
                              </Box>
                              <Typography style={{ display: 'flex', justifyContent: 'center', color: 'orange' }} onClick={() => (window.location.href = `/#/restaurant/${value?.place_id}`)}>View Details</Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Box>
                    );
                  })}
              </Slider>
            </Box>
          </Box>
        </>
      }
    </>
  );
}
export default memo(MapCard);