import React, { useEffect, useState } from "react";
import { Avatar, Button, Card, CardActions, CardMedia, DialogTitle, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CardContent from "@mui/material/CardContent";
import { useDispatch, useSelector } from "react-redux";
import { getDiff, ImgUrl } from "../../../constants/const";
import dummyRestro from "../../../images/CW/dummyRestro.png";
import { Input } from "antd";
import { Modal } from "react-bootstrap";
import { addCommunityComments, getCommunityComments, getCommunityPost, likeCommunityPost, deleteCommunityComment, getCommunityPostLike, communityPostDetail } from "../../../redux/action/community";
import { FacebookShareButton } from "react-share";
import leafImg from "../../../images/CW/Icon awesome-leaf@2x.png";
import greenLeafImg from "../../../images/CW/Icon awesome-green-leaf@2x.png";
import commentImg from "../../../images/CW/Icon awesome-comment@2x.png";
import shareImg from "../../../images/CW/Icon ionic-ios-share-alt@2x.png";
import dummyProfile from "../../../images/CW/profile.jpeg";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import ReactHlsPlayer from "react-hls-player";

const { TextArea } = Input;

const ProfileCommunity = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [showRB, setShowRB] = useState(false);
  const [openCommentBox, setOpenCommentBox] = useState(false);
  const [message, setMessage] = useState("");
  const [commId, setCommId] = useState("");
  const [mainId, setMainId] = useState("");

  const community_data = useSelector((state) => state.dashboard?.user_data?.community_data);
  const getCommunityPostLikesss = useSelector((state) => state?.community?.get_community_post_likes);
  const getCommunityPostComment = useSelector((state) => state?.community?.get_community_comment?.data);
  const getCommunityPostDetail = useSelector((state) => state?.community?.community_post_detail?.data);
  const userData = useSelector((state) => state.dashboard?.user_data);

  // Like Modal Start
  const handleShowLike = (id) => {
    setCommId(id);
    setShowRB(true);
    const successCb = (res) => {
    };
    let data = {
      community_post_id: id,
      comment: message,
      page: 1,
    };
    dispatch(getCommunityPostLike(data, successCb));
  };
  const handleCloseLike = () => setShowRB(false);
  // Like Modal End

  const toggleImage = (dataId) => {
    const successCb = (resp) => {
      const successCb = () => { }
      let data = { community_post_id: mainId };
      dispatch(communityPostDetail(data, successCb));
    };
    let data = {
      community_post_id: dataId,
      page: 1,
    };
    dispatch(likeCommunityPost(data, successCb));
  };
  useEffect(() => {
    toggleImage()
  }, [userData])

  const handleOpenCommentBox = (id) => {
    setOpenCommentBox(true)
    setCommId(id);
    let data = {
      community_post_id: id,
      page: 1,
    };
    dispatch(getCommunityComments(data));
  }
  const handleCloseCommentBox = () => {
    setOpenCommentBox(false)
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenDesc = (totData) => {
    setMainId(totData?._id)
    setTimeout(() => {
      setShow(true)
    }, 300);
    const successCb = () => { }
    let data = { community_post_id: totData?._id, };
    dispatch(communityPostDetail(data, successCb));
  }
  const handleCloseDesc = () => {
    setShow(false);
  };

  const addComment = () => {
    const successCb = (res) => {
      setTimeout(() => {
        let data = {
          community_post_id: commId,
          page: 1,
        };
        dispatch(getCommunityComments(data));
        let token = localStorage.getItem("token");
        dispatch(getCommunityPost(token, { page: '1' }));
        setMessage("");
      }, 500);
      return;
    };
    let data = {
      community_post_id: commId,
      comment: message,
      page: 1,
    };
    dispatch(addCommunityComments(data, successCb));
  };

  const deleteComment = (dataId) => {
    const successCb = (res) => {
      let data = {
        community_post_id: commId,
        page: 1,
      };
      dispatch(getCommunityComments(data));
      let token = localStorage.getItem("token");
      dispatch(getCommunityPost(token, { page: '1' }));
      return;
    };
    let data = {
      community_post_id: dataId?._id,
      comment_id: dataId?._id,
    };
    dispatch(deleteCommunityComment(data, successCb));
  };

  return (
    <Box>
      <Box style={{ display: "flex", flexDirection: "row-reverse", color: "#000" }} >
        <Button className="see_more_card_btn" onClick={handleClickOpen}> See All </Button>
      </Box>
      <Grid container spacing={2} style={{ maxHeight: "30vh", overflowY: "scroll" }}>
        {community_data?.length > 0 ? community_data?.map((data, id) => {
          return (
            <Grid item xs={12} sm={3} md={2} lg={2} key={id}>
              <Card onClick={() => handleClickOpenDesc(data)} sx={{ maxWidth: 320 }} style={{ margin: "0", padding: "0", boxSizing: "border-box", position: "relative", boxShadow: "9px 9px 31px -10px rgba(0,0,0,0.75)", borderRadius: "10px", border: "2px solid orange", objectFit: "cover", }} >
                {data?.media_type === "image" ? (
                  <CardMedia component="img" height="200px" image={data?.media !== "" ? `${ImgUrl}${data?.media}` : dummyRestro} alt="media" />
                ) : (
                  data?.media?.includes('mp4') ?
                    <video width="100%" height="200" controls>
                      <source src={data?.media !== "" ? `${ImgUrl}${data?.media}` : ''} type="video/mp4" />
                    </video>
                    :
                    <ReactHlsPlayer
                      src={`${ImgUrl}${data?.media}`}
                      autoPlay={false}
                      width="100%"
                      height="200px"
                      style={{ objectFit: "cover" }}
                      controls
                    />
                )}
              </Card>
            </Grid>
          );
        }) : <p className="d-flex text-center mx-4" >No data added yet!</p>}
      </Grid>

      {/* To see All */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title" style={{ width: "-webkit-fill-available", minHeight: '25vh' }} >
        <DialogContent>
          <Box style={{ display: "flex", alignItems: "center", }} >
            <KeyboardBackspaceIcon onClick={handleClose} fontSize="large" style={{ flex: "start", cursor: "pointer" }} />
          </Box>
          <DialogContentText style={{ color: "#000", fontFamily: "Montserrat", fontWeight: "normal", display: "flex", textAlign: "center", }} >
            <Box style={{ margin: "0", padding: "0" }}>
              <CardContent>
                <Box>
                  <Grid container spacing={2}>
                    {community_data?.length > 0 ? community_data?.map((data, id) => {
                      return (
                        <Grid item xs={12} sm={4} md={4} lg={4} key={id}>
                          {data?.media_type === "image" ? (
                            <CardMedia component="img" image={data?.media !== "" ? `${ImgUrl}${data?.media}` : dummyRestro} alt="dish_image" style={{ borderRadius: "10px 10px 0 0px", height: "100%", }} />
                          ) : (
                            // <video src={data?.media !== "" ? `${ImgUrl}${data?.media}` : dummyRestro} width="200" height="200" controls="controls" />
                            data?.media?.includes('mp4') ?
                              <video width="100%" height="200" controls> <source src={data?.media !== "" ? `${ImgUrl}${data?.media}` : dummyRestro} type="video/mp4" /> </video>
                              :
                              <ReactHlsPlayer
                                src={`${ImgUrl}${data?.media}`}
                                autoPlay={false}
                                width="100%"
                                height="200px"
                                controls
                                style={{ objectFit: "cover" }}
                              />
                          )}
                        </Grid>
                      );
                    }) : <p className="d-flex text-center mx-4" >No data added yet!</p>}
                  </Grid>
                </Box>
              </CardContent>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Show Details */}
      <Modal show={show} onHide={handleCloseDesc}>
        <Modal.Header closeButton ><Modal.Title>Community Post</Modal.Title></Modal.Header>
        <Modal.Body>
          <Box>
            <Box>
              <Box mt={2} textAlign="center">
                {getCommunityPostDetail?.media_type == "image" ?
                  <img src={`${ImgUrl}${getCommunityPostDetail?.media}`} alt='img' className="community_card" />
                  :
                  getCommunityPostDetail?.media?.includes('mp4') ?
                    <video className="community_card" controls src={`${ImgUrl}${getCommunityPostDetail?.media}`} />
                    :
                    <ReactHlsPlayer
                      src={`${ImgUrl}${getCommunityPostDetail?.media}`}
                      className="community_card"
                      controls
                    />
                }
              </Box>
            </Box>
            <Box>
              <p style={{ fontWeight: "bold", margin: "10px 10px 0" }}>{getCommunityPostDetail?.title}</p>
              <TextArea readOnly rows={2} value={getCommunityPostDetail?.description} style={{ margin: "10px 0", border: 0 }} />
            </Box>
            {/* Like, Share, Comment */}
            <CardActions className="d-flex justify-content-between">
              <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer" }} >
                <IconButton aria-label="add to favorites" onClick={() => toggleImage(getCommunityPostDetail?._id)} >
                  <img src={getCommunityPostDetail?.is_liked == 0 ? leafImg : greenLeafImg} alt="like_img" height={20} />&nbsp;
                  <Box onClick={() => handleShowLike(getCommunityPostDetail?._id)}>
                    <Typography className="small_font" style={{ color: `${getCommunityPostDetail?.is_liked == 0 ? "black" : "green"}`, }}>
                      {`${getCommunityPostDetail?.total_likes} ${getCommunityPostDetail?.total_likes > 1 ? "Likes" : "Like"}`}
                    </Typography>
                  </Box>
                </IconButton>
              </Box>
              <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer", }} onClick={() => handleOpenCommentBox(getCommunityPostDetail?._id)} >
                <IconButton aria-label="comment">
                  <img src={commentImg} alt="like_img" height={20} className="me-1" />
                  <Typography className="text-dark small_font"> {getCommunityPostDetail?.total_comments} Comment </Typography>
                </IconButton>
              </Box>
              <FacebookShareButton url={`${ImgUrl}${getCommunityPostDetail?.media}`} quote={`"${getCommunityPostDetail?.title}"`} hashtag={`#${getCommunityPostDetail?.title}`} description={getCommunityPostDetail?.description} className="Demo__some-network__share-button" >
                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer", }} >
                  <IconButton aria-label="share"><img src={shareImg} alt="like_img" height={20} />
                    <Typography className="text-dark small_font">Share</Typography>
                  </IconButton>&nbsp;
                </Box>
              </FacebookShareButton>
            </CardActions>
          </Box>
        </Modal.Body>
      </Modal>

      {/* Like Box */}
      <Modal show={showRB} onHide={handleCloseLike} backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title style={{ display: "flex", justifyContent: "center" }} > Likes </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {getCommunityPostLikesss?.data?.map((data, id) => (

            <Box className="liked_main_style" key={id}>
              <List sx={{ width: "100%", bgcolor: "background.paper", }} >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={data?.user_id?.profile_pic != '' ? `${ImgUrl}${data?.user_id?.profile_pic}` : dummyProfile} />
                  </ListItemAvatar>
                  <ListItemText
                    style={{ color: "orange", fontWeight: "bolder" }}
                    primary={data?.user_id?.full_name}
                    secondary={getDiff(data?.updated_at)}
                  />
                </ListItem>
              </List>
            </Box>
          ))}
        </Modal.Body>
      </Modal>

      {/* Comment Box */}
      <Modal show={openCommentBox} onHide={() => handleCloseCommentBox()} backdrop="static" keyboard={false} >
        <Modal.Header closeButton >
          <Modal.Title style={{ fontWeight: "bold" }}>
            {/* <KeyboardBackspaceIcon onClick={() => handleCloseCommentBox()} fontSize="large" cursor="pointer" /> */}
            <span style={{ fontWeight: "bold", marginLeft: "30px" }}> Comments </span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography style={{ height: "40vh", padding: "10px 0", overflowY: "scroll", }} >
            {getCommunityPostComment?.length > 0 ? getCommunityPostComment?.map((data, id) => {
              return (
                <Box className="liked_main_style" key={id}>
                  <List sx={{ width: "100%", bgcolor: "background.paper", }} >
                    <ListItem secondaryAction={<IconButton edge="end" aria-label="delete" style={{ color: "orange" }} onClick={() => deleteComment(data)} > <DeleteIcon /> </IconButton>} >
                      <ListItemAvatar> <Avatar sx={{ width: 56, height: 56 }} style={{ marginRight: 10 }} src={data?.user_id?.profile_pic != '' ? `${ImgUrl}${data?.user_id?.profile_pic}` : dummyProfile} /> </ListItemAvatar>
                      <ListItemText style={{ wordBreak: 'break-all' }} primary={data?.user_id?.full_name} secondary={<>
                        <p style={{ padding: '0', margin: '0' }}> "{data?.comment}" </p>
                        <p style={{ padding: '0', margin: '0' }}>{getDiff(data?.created_at)}</p>
                      </>} />
                    </ListItem>
                  </List>
                </Box>
              );
            }) : <Box><p>No comments found!</p></Box>}
          </Typography>
          <Box style={{ display: "flex", border: "1px solid orange", margin: "5px 0 0 0", justifyContent: "space-between", padding: '5px 0' }} >
            <input autoFocus placeholder="Your Message..." size="large" value={message} onChange={(e) => { setMessage(e.target.value); console.log(e.target.value) }} style={{ outline: "none", border: "none", width: "100%", marginLeft: 10 }} />
            <Box onClick={() => addComment()} style={{ color: "orange", padding: '2px 5px', cursor: 'pointer' }} > <SendIcon /> </Box>
          </Box>
        </Modal.Body>
      </Modal>
    </Box>
  );
};
export default ProfileCommunity;