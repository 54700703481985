import React, { useState } from "react";
import { Layout, Avatar, Button, Row, Col, Typography } from "antd";
import user from "../../../images/navasset/user.png";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import "./Navbar.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ring from "./ringing.mp3"
import { Badge, Box, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import dummyProfile from "../../../images/CW/profile.jpeg";
import { getUserData, sidebarBadgesdata } from "../../../redux/action/dashboard";
import { ImgUrl } from "../../../constants/const";
import { useEffect } from "react";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { onMessageListener, db } from "../../../firebase"
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { sendChatNotification } from "../../../redux/action/auth";
import { LOGOUT } from "../../../redux/constants";
import { getMyFollowRequest, likedBy, notification } from "../../../redux/action/community";
import { getRequestList } from "../../../redux/action/meet";
import { activateTimer } from "../../../redux/action/common";

const { Header } = Layout;

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user_data = useSelector((state) => state.dashboard.user_data);
  const badgedata = useSelector((state) => state.dashboard?.badgesdata_sidebar);
  const notifybadgedata = useSelector((state) => state.community?.notification_data?.data);
  const badgeData = badgedata?.filter((data) => { return (data?.key === "Notification" ? data?.badge : "") })

  const [callDialog, setCallDialog] = useState(false);
  const [callPayload, setCallPayload] = useState(null)
  const [dis_timeout, setDis_timeout] = useState(null)

  useEffect(() => {
    let token = localStorage.getItem("token");
    let userData = localStorage.getItem("userData")
    const successCb = (resp) => { console.log("resp", resp) }
    const failureCb = () => { }
    dispatch(getUserData(token, userData));
    dispatch(likedBy(token));
    dispatch(notification());
    dispatch(getMyFollowRequest(token));
    dispatch(getRequestList(token));
    dispatch(sidebarBadgesdata());
    if (user_data) {
      // db.collection("Users").doc(user_data?._id).update({
      //   online: true
      // })
    }
  }, [location.pathname]);


  const logout = () => {
    db.collection("Users").doc(user_data?._id).set({
      online: false,
      user_id: user_data?._id
    })
    dispatch({
      payload: false,
      type: LOGOUT,
    });
    localStorage.clear();

    setTimeout(() => {
      window.location.href = "/"
    }, 100);
  }

  onMessageListener((payload) => {
    if ("action" in payload?.data && payload?.data?.action == "end-call") {
      setCallPayload(false)
      document.createElement("audio",)
      let player = document.getElementById("ringer")
      player.pause()
      setCallDialog(false)
      sessionStorage.removeItem("start-timer")
      navigate('/inbox')
      window.location.reload()
    } else if (payload?.data?.type == "missedCall") {
      setCallPayload(false)
      let player = document.getElementById("ringer")
      player.pause()
      setCallDialog(false)
    } else if (payload?.data?.type == "videoCall" || payload?.data?.type == "audioCall") {
      let player = document.getElementById("ringer")
      player.play()
      setCallPayload(payload)
      sessionStorage.setItem("callPayload", JSON.stringify({
        appID: "ab0e65d266354bf684b91530ee7e481a",
        channel: payload?.data?.channelName,
        token: payload?.data?.token,
        sender_id: payload?.data?.sender_id,
        user_id: payload?.data?.user_id,
        receiver_id: payload?.data?.receiver_id,
        sender_name: payload?.data?.name,
        sender_image: payload?.data?.image,
        type: payload?.data?.type == "videoCall" ? "Video" : "Voice",
        call_type: "Incoming"
      }))
      setTimeout(() => {
        setCallDialog(true)
      }, 500)
      setDis_timeout(
        setTimeout(() => {
          player.pause()
          setCallDialog(false)
          let incomingCallObj = {
            call_type: payload?.data?.type == "videoCall" ? "video" : "audio",
            created_at: new Date(),
            type: "missed",
            user: {
              _id: payload?.data?.sender_id,
              full_name: payload?.data?.name,
              profile_pic: payload?.data?.image
            }
          }
          db.collection("call").doc(payload?.data?.receiver_id?.toString()).collection("list").doc().set(incomingCallObj)
          rejectCall(false)
        }, 50000)
      )
    } else if (payload?.data?.action == "start-timer") {
      dispatch(activateTimer(true))
    }
  })

  const acceptCall = () => {
    let player = document.getElementById("ringer")
    player.pause()
    setCallDialog(false)
    clearTimeout(dis_timeout)
    let incomingCallObj = {
      call_type: callPayload?.data?.type == "videoCall" ? "video" : "audio",
      created_at: new Date(),
      type: "incoming",
      user: {
        _id: callPayload?.data?.sender_id || callPayload?.data?.user_id,
        full_name: callPayload?.data?.name,
        profile_pic: callPayload?.data?.image
      }
    }
    db.collection("call").doc(user_data?._id?.toString()).collection("list").doc().set(incomingCallObj)
    db.collection('channel').doc(callPayload?.data?.user_id).set({ join2: true }, { merge: true })
    let pp = {
      action: "start-timer"
    }
    let data = {
      user_id: callPayload?.data?.sender_id || callPayload?.data?.user_id,
      payload: pp
    }
    dispatch(sendChatNotification(data, () => {
      dispatch(activateTimer(true))
      if (callPayload?.data?.type == "videoCall") {
        window.location.href = "/#/video_call"
      } else {
        window.location.href = "/#/call"
      }
    }))
  }

  const rejectCall = (fireAction = true) => {
    clearTimeout(dis_timeout)
    let sender_id = null
    let cp = sessionStorage.getItem("callPayload") ? JSON.parse(sessionStorage.getItem("callPayload")) : null
    sender_id = cp?.sender_id || cp?.user_id
    if (sender_id) {
      let payload = {
        action: "end-call"
      }
      let data = {
        user_id: sender_id,
        payload
      }
      const cb = () => {
        sessionStorage.removeItem("callPayload")
        let player = document.getElementById("ringer")
        player.pause()
        setCallDialog(false)
        if (fireAction) {
          let incomingCallObj = {
            call_type: callPayload?.data?.call_type == "videoCall" ? "video" : "audio",
            created_at: new Date(),
            type: "incoming",
            user: {
              _id: callPayload?.data?.sender_id || callPayload?.data?.user_id,
              full_name: callPayload?.data?.name,
              profile_pic: callPayload?.data?.image
            }
          }
          db.collection("call").doc(user_data?._id?.toString()).collection("list").doc().set(incomingCallObj)
          db.collection('channel').doc(callPayload?.data?.sender_id || callPayload?.data?.user_id).set({ join: false }, { merge: true })
        }
      }
      dispatch(sendChatNotification(data, cb))
    }
  }

  useEffect(() => {
    if (user_data) {
      db.collection("Users").doc(user_data?._id).set({
        online: true,
        user_id: user_data?._id
      });
    }
  }, [user_data])

  const notifypage = () => {
    navigate("/notification")
    window.location.reload()
  }

  return (
    <>
      {/* notification Panel */}
      <audio loop={true} id="ringer" src={ring} type="audio/mp3" />
      <Dialog
        open={callDialog}
        onClose={() => setCallDialog(false)}
        aria-labelledby="responsive-dialog-title">
        <DialogContent>
          <DialogContentText
            style={{
              color: "#000",
              fontFamily: "Montserrat",
              fontWeight: "normal",
              marginTop: "15px 0",
            }}>
            Incoming {callPayload?.data?.type == "videoCall" ? "Video Call" : "Voice Call"} from {callPayload?.data?.name ?? ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={() => rejectCall()} style={{ color: "#000" }}>
            <strong>Decline</strong>
          </Button>
          <Button onClick={() => acceptCall()} style={{ color: "#F8931F" }}>
            <strong>Accept</strong>
          </Button>
        </DialogActions>
      </Dialog>
      {/* notification ends */}

      <Header className="header2">
        {/* <main className="flexing"> */}
        <div className="d-flex align-items-center">
          <Link to='/profile'>
            <Avatar size={60} src={user_data?.profile_pic != "" ? `${ImgUrl}${user_data?.profile_pic}` : dummyProfile} />
          </Link>
          <div className="ms-2">
            <Typography className="nav_b">{user_data?.full_name}</Typography>
            <Typography className="nav_p">{user_data?.email}</Typography>
          </div>
        </div>
        <div className="userlogout">
          {/* <Link to="/profile"> <img src={user} alt="users" className="userlogo" /></Link> */}
          <div onClick={notifypage} style={{ cursor: 'pointer' }}>
            {badgeData?.length > 0 ?
              <Badge badgeContent={badgeData?.map((data) => { return (data?.badge) })} color="primary" max={99} style={{ margin: "5px 5px 0 0", color: 'orange' }}>
                <NotificationsIcon style={{ color: "#fc7c0c", }} fontSize="large" />
              </Badge>
              : <NotificationsIcon style={{ color: "#fc7c0c", }} fontSize="large" />}
          </div>
          <Button type="primary" id="button" onClick={logout}> Logout </Button>
          <Button type="primary" id="button2" onClick={logout}> <ExitToAppIcon /> </Button>
        </div>
        {/* </main> */}
      </Header>

      {/* <div style={{height:"70px"}}></div> */}
    </>
  );
};
export default Navbar;