import AgoraRTC from 'agora-rtc-sdk-ng';
const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
var localTracks = {
    audioTrack: null
};

var options = {
    appid: null,
    channel: null,
    uid: null,
    token: null
};

export const load = async () => {
    try {
        let callPayload = JSON.parse(sessionStorage.getItem("callPayload"))
        options.appid = callPayload?.appID;
        options.token = callPayload?.token;
        options.channel = callPayload?.channel;
        await join();
    } catch (e) {
        console.log("error while start call")
    }
}

const join = async () => {
    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);
    [options.uid, localTracks.audioTrack] = await Promise.all([
        client.join(options.appid, options.channel, options.token),
        AgoraRTC.createMicrophoneAudioTrack()
    ]);
    await client.publish(Object.values(localTracks));
}

export const manageMic = async (state) => {
    await localTracks?.audioTrack?.setMuted(state)
}

const handleUserPublished = async (user, mediaType) => {
    await client.subscribe(user, mediaType);
    if(mediaType == "audio"){
        const remoteAudioTrack = user.audioTrack
        remoteAudioTrack.play()
    }
}

const handleUserUnpublished = async (user) => {
    await client.unsubscribe(user)
}