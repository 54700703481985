import { Call } from "@mui/icons-material";
import { ApiCall } from "../../api";
import { BASE_URL, authToken } from "../../constants/const";
import {
  ADD_RECIPE_COMMENT,
  GET_MENUS,
  GET_RECIPE_COMMENT,
  GET_RECIPE_POST,
  SEARCH_RECIPE,
  GET_OTHER_USER_DETAIL,
  ADD_RECIPE,
  DELETE_RECIPE_POST,
  EDIT_RECIPE,
  GET_RECIPE_POST_DETAILS,
  GET_MY_RECIPE
} from "../constants";

// Done
export const getRecipeMenu = () => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_recipe_menu`,
  };
  let response = await ApiCall(config, dispatch);
  dispatch({
    type: GET_MENUS,
    payload: response?.data,
  });
};

export const searchRecipe = (data) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/search_recipe`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  dispatch({
    type: SEARCH_RECIPE,
    payload: response?.data,
  });
};

// Done
export const getOtherUserDetail = (data) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_other_user_detail`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  dispatch({
    type: GET_OTHER_USER_DETAIL,
    payload: response?.data,
  });
};

// Main User handling function
export const getOtherUserDetails = (data, successCb, failureCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_other_user_detail`,
    data,
  };
  try {
    let response = await ApiCall(config, dispatch);
    if (response?.status) {
      successCb(response?.data)
    } else {
      failureCb(response?.data)
    }
  } catch (error) {
    failureCb(error)
  }
};

export const getOtherUserDetailwithCB = (data, callB) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_other_user_detail`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response?.data?.status) {
    callB(response?.data?.data)
  }
};

// Done
export const getRecipePost = (data) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_recipe_posts`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  dispatch({
    type: GET_RECIPE_POST,
    payload: response?.data,
  });
};

// Done
export const getMyRecipe = () => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_my_recipes`,
  };
  let response = await ApiCall(config, dispatch);
  dispatch({
    type: GET_MY_RECIPE,
    payload: response?.data,
  });
};

// Done
export const addRecipeComment = (data) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/add_recipe_comment`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  dispatch({
    type: ADD_RECIPE_COMMENT,
    payload: response?.data,
  });
};

// Done
export const getRecipeComment = (data) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_recipe_comments`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  dispatch({
    type: GET_RECIPE_COMMENT,
    payload: response?.data,
  });
};

// Done
export const addRecipePost = (data, successCb) => async (dispatch) => {
  const formData = new FormData();
  formData.append("menu_id", data.menu_id);
  formData.append("title", data.title);
  formData.append("description", data.description);
  formData.append("recipe_media", data.recipe_media);
  let config = {
    method: "post",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "multipart/form-data",
    },
    url: `${BASE_URL}users/add_recipe_post`,
    data: formData,
  };
  let response = await ApiCall(config, dispatch);
  if (response?.data?.status) {
    successCb(response?.data);
  }
  dispatch({
    type: ADD_RECIPE,
    payload: response?.data,
  });
};

// Done
export const deleteRecipePost = (data, successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/delete_recipe_post`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response?.data);
  }
  dispatch({
    type: DELETE_RECIPE_POST,
    payload: response?.data,
  });
};

// DOne
export const editRecipePost = (data, successCb) => async (dispatch) => {
  const formData = new FormData();
  formData.append("recipe_post_id", data.recipe_post_id);
  formData.append("title", data.title);
  formData.append("description", data.description);
  formData.append("recipe_media", data.recipe_media);
  let config = {
    method: "post",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "multipart/form-data",
    },
    url: `${BASE_URL}users/edit_recipe_post`,
    data: formData,
  };
  let response = await ApiCall(config, dispatch);
  if (response?.data?.status) {
    successCb(response?.data);
  }
  dispatch({
    type: EDIT_RECIPE,
    payload: response?.data,
  });
};

// UP
export const getRecipePostDetail = (data, successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_recipe_post_detail`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response?.data);
  }
  dispatch({
    type: GET_RECIPE_POST_DETAILS,
    payload: response?.data,
  });
};
