import React, { useEffect, useState } from "react";
import { Button, Card, CardMedia, Grid } from "@mui/material";
import { Box } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CardContent from "@mui/material/CardContent";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOtherUserDetail } from "../../../redux/action/recipe";
import { ImgUrl } from "../../../constants/const";
import dummyProfile from "../../../images/CW/profile.jpeg";
import dummyRestro from "../../../images/CW/dummyRestro.png";
import { updateGallery } from "../../../redux/action/dashboard";
import { Modal } from "react-bootstrap";
import ReactHlsPlayer from "react-hls-player";

const OtherUserGallery = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [imageset, setImageset] = useState(null);
  const [type, setType] = useState(null);

  const handleCloseDesc = () => {
    setShow(false);
    setImageset(null)
    setType(null)
  };
  const handleClickOpenDesc = (data) => {
    setShow(true);
    setImageset(data?.media)
    setType(data?.media_type)
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const uploads = useSelector((state) => state.recipe?.other_user_detail?.data?.uploads);

  useEffect(() => {
    let token = localStorage.getItem("token");
    let other_user_id = id;
    dispatch(getOtherUserDetail({ other_user_id }, token));
  }, []);

  return (
    <Box>
      <Box style={{ display: "flex", flexDirection: "row-reverse", color: "#000" }}>
        <Button className="see_more_card_btn" onClick={handleClickOpen}> See All </Button>
      </Box>
      <Grid container spacing={2}>
        {uploads?.length > 0 ? uploads?.map((data, id) => {
          return (
            <Grid item xs={12} sm={4} md={4} lg={3} key={id}>
              <Card onClick={() => handleClickOpenDesc(data)} style={{ margin: "0", padding: "0", boxSizing: "border-box", position: "relative", boxShadow: "9px 9px 31px -10px rgba(0,0,0,0.75)", borderRadius: "10px", border: "2px solid orange", objectFit: "cover", }}>
                {data?.media_type === "image/png" || "image/jpeg" || "image/gif" ? (
                  <CardMedia component="img" image={data?.media !== "" ? `${ImgUrl}${data?.media}`
                    : dummyRestro} alt="media" height="200px" />
                ) : (
                  <>
                    {data?.media?.includes('mp4') ?
                      <video width="100%" height="200px" controls autoPlay>
                        <source src={data?.media !== "" ? `${ImgUrl}${data?.media}` : ''} type="video/mp4" />
                      </video>
                      :
                      <ReactHlsPlayer
                        src={`${ImgUrl}${data?.media}`}
                        autoPlay={false}
                        width="100%"
                        height="200px"
                        style={{ objectFit: "cover" }}
                        controls
                      />
                    }
                  </>
                )}
              </Card>
            </Grid>
          );
        }) : <p className="d-flex text-center mx-4" >No data added yet!</p>}
      </Grid>
      <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogContent>
          <Box style={{ display: "flex", alignItems: "center", }}>
            <KeyboardBackspaceIcon onClick={handleClose} fontSize="large" style={{ flex: "start", cursor: "pointer" }} />
          </Box>
          <DialogContentText style={{ color: "#000", fontFamily: "Montserrat", fontWeight: "normal", display: "flex", textAlign: "center", }}>
            <Box style={{ margin: "0", padding: "0" }}>
              <CardContent>
                <Box>
                  <Grid container spacing={2}>
                    {uploads?.length > 0 ? uploads?.map((data, id) => {
                      return (
                        <Grid item xs={12} sm={4} md={4} lg={4} key={id}>
                          {data?.media_type === "image/png" || "image/jpeg" || "image/gif" ?
                            <img src={data?.media != "" ? `${ImgUrl}${data?.media}` : `${dummyProfile}`} height={200} width="100%" style={{ border: "2px solid #F8931F", borderRadius: "12px", width: "auto" }} alt="img" />
                            :
                            <video width="100%" height="200px" controls autoPlay> <source src={data?.media !== "" ? `${ImgUrl}${data?.media}` : ''} type="video/mp4" /> </video>}
                        </Grid>
                      );
                    }) : <p className="d-flex text-center mx-4" >No data added yet!</p>}
                  </Grid>
                </Box>
              </CardContent>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* For description */}
      <Modal show={show} onHide={handleCloseDesc}>
        <Modal.Header closeButton />
        <Modal.Body>
          <Box>
            <Box>
              <Box mt={2} textAlign="center">
                {type == "image" ?
                  <img src={`${ImgUrl}${imageset}`} alt='img' className="community_card" />
                  :
                  imageset?.includes('mp4') ?
                    <video controls src={`${ImgUrl}${imageset}`} className="community_card" />
                    :
                    <ReactHlsPlayer
                      src={`${ImgUrl}${imageset}`}
                      autoPlay={false}
                      className="community_card"
                      controls
                    />
                }
              </Box>
            </Box>
          </Box>
        </Modal.Body>
      </Modal>
    </Box>
  );
};
export default OtherUserGallery;