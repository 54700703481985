import React, { useEffect, useState } from "react";
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import dummyProfile from "../../../images/CW/profile.jpeg";
// For API
import { useDispatch, useSelector } from "react-redux";
import { notification, deleteNotification, markAsRead } from "../../../redux/action/community";
import toast from "../../../constants/toast";
import { getDiff, ImgUrl } from "../../../constants/const";
import Spinner from "../../../constants/Spinner";

const Notification = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const notificationData = useSelector((state) => state.community?.notification_data);
  const userData = useSelector((state) => state.dashboard?.user_data);

  const deleteNotify = () => {
    setLoading(true);
    const successCb = (resp) => {
      toast.success(resp?.message)
      if (resp.status === true) {
        dispatch(notification());
        setLoading(false);
      }
    };
    dispatch(deleteNotification(successCb));
  }

  useEffect(() => {
    const successCb = (resp) => {
    }
    let data = {
      user_id: userData?._id,
      type: "community_like"
    }
    dispatch(markAsRead(data, successCb))
  }, [notificationData])

  useEffect(() => {
    let token = localStorage.getItem("token");
    dispatch(notification(token));
  }, []);

  useEffect(() => {
    if (notificationData && loading) {
      setLoading(false);
    }
  }, [notificationData]);

  return (
    <>
      {loading ? (<Spinner />) : (
        <div >
          <div className="d-flex justify-content-between mb-3">
            <Typography className="common_medium_font">Notification</Typography>
            {notificationData?.data?.length > 0 ?
              <Box style={{ color: "orange", cursor: "pointer"}} >
                <DeleteIcon fontSize="large" onClick={deleteNotify}/>
              </Box> : ""}
          </div>
          <Box className="">
            {notificationData?.data?.length > 0 ? notificationData?.data?.map((data, id) => {
              return (
                <Box className="mb-2 rounded" key={id} style={{ background: "#fff" }}>
                  <List >
                    <ListItem className="" secondaryAction={getDiff(data?.created_at)}>
                      <Box className="liked_text_style">
                        <Box>
                          <ListItemAvatar>
                            <Avatar className="liked_avatar_style" src={data?.sent_by?.profile_pic !== "" ? `${ImgUrl}${data?.sent_by?.profile_pic}` : `${dummyProfile}`} />
                          </ListItemAvatar>
                        </Box>
                        <ListItemText primary={data?.title} secondary={<Typography sx={{ display: "inline" }} component="span" variant="body2" style={{ color: "#444444" }}> {data?.body} </Typography>} />
                      </Box>
                    </ListItem>
                  </List>
                </Box>
              );
            }) : <Box style={{ display: "flex", color: "orange", margin: "20px 0 0 30px" }}> No Notifications Found! </Box>}
          </Box>
        </div>
      )}
    </>
  );
};
export default Notification;