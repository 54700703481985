import React, { useEffect, useState } from "react";
import { Card, CardMedia, Grid, Typography, Box } from "@mui/material";
import "./prDating.css";
import { Button } from "antd";
import deleteBtn from "../../../../images/CW/008-delete.png";
import correctBtn from "../../../../images/CW/003-check-mark-sign.png";
import { Link } from "react-router-dom";
import info from "../../../../images/CW/Path 22517.png";
import vmdLogo from "../../../../images/Group 33033@2x.png";
import vmdFLogo from "../../../../images/Group 17276@2x.png";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import dummyProfile from "../../../../images/CW/profile.jpeg";
import Spinner from "../../../../constants/Spinner";
// API
import { useDispatch, useSelector } from "react-redux";
import { getRequestList } from "../../../../redux/action/meet";
import { ImgUrl } from "../../../../constants/const";
import { acceptRejectRequest, markAsRead, acceptRejectRequestMultiple } from "../../../../redux/action/community";
import toast from "../../../../constants/toast";

const PRDating = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const datingConnectionData = useSelector((state) => state.meet?.get_request_list);
  const userData = useSelector((state) => state.dashboard?.user_data);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAccept = async (type, request_id) => {
    if (type === "accept") {
      setOpen(true);
      setLoading(true);
      let token = localStorage.getItem("token");
      const successCb = (resp) => {
        if (resp.status === true) {
          dispatch(getRequestList(token));
          setLoading(false);
        }
      };
      dispatch(acceptRejectRequest(token, { type, request_id }, successCb));
    } else {
      setOpen(false);
      setLoading(true);
      let token = localStorage.getItem("token");
      const successCb = (resp) => {
        if (resp.status === true) {
          dispatch(getRequestList(token));
          setLoading(false);
        }
      };
      dispatch(acceptRejectRequest(token, { type, request_id }, successCb));
    }
  };

  const acceptAllRequest = () => {
    const successCb = (resp) => {
      toast.success(resp?.message)
      let token = localStorage.getItem("token");
      dispatch(getRequestList(token));
    }
    const data = {
      request_ids: JSON.stringify(datingConnectionData?.data?.map((d) => d?._id)),
      type: 'accept',
    }
    dispatch(acceptRejectRequestMultiple(data, successCb))
  }

  useEffect(() => {
    const successCb = (resp) => { }
    let data = {
      user_id: userData?._id,
      type: "right_swipe"
    }
    dispatch(markAsRead(data, successCb))
  }, [datingConnectionData])

  useEffect(() => {
    let token = localStorage.getItem("token");
    dispatch(getRequestList(token));
  }, []);

  useEffect(() => {
    if (datingConnectionData && loading) {
      setLoading(false);
    }
  }, [datingConnectionData]);

  return (
    <>
      {loading ? (<Spinner />) : (
        <>
          {datingConnectionData?.data?.length > 0 ? <Button onClick={() => acceptAllRequest()} style={{ background: '#56a050', color: '#fff', borderRadius: 36, height: 42, width: 162, float: 'right' }} >Accept All</Button> : ''}
          <Grid container rowSpacing={2} columnSpacing={2} sx={{ p: 4 }} style={{ padding: "0", minHeight: '50vh' }} >
            {datingConnectionData?.data?.length > 0 ? (datingConnectionData?.data?.map((data, id) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={2} key={id}>
                  <Card sx={{ width: 1 }}
                    style={{ margin: "0", padding: "0", boxSizing: "border-box", position: "relative", boxShadow: "9px 9px 31px -10px rgba(0,0,0,0.75)", borderRadius: "12px" }}>
                    <CardMedia component="img" alt="green iguana" height="200" style={{ margin: "0", padding: "0" }} image={data?.requested_by?.profile_pic !== "" ? `${ImgUrl}${data?.requested_by?.profile_pic}` : `${dummyProfile}`} />
                    <Typography className="pr-dating-text-icon-style">
                      <Link style={{ color: "#000", fontWeight: '600' }} to={`/user_profile_pr/${data?.requested_by?._id}`}>
                        {data?.requested_by?.full_name} &nbsp; <img src={info} style={{ height: "15px", cursor: "pointer" }} alt="logo-img" />
                      </Link>
                    </Typography>
                    <Box className="pr-btn-style-section">
                      <Button type="primary" shape="circle" size="large" style={{ background: "#9A080899", border: "none" }} onClick={() => handleCloseAccept("reject", data?._id)}>
                        <img className="pr-dating-right-img" src={deleteBtn} alt="remove--icon" />
                      </Button>
                      <Button type="primary" shape="circle" size="large" style={{ background: "#16682FAD", border: "none" }} className="pr-dating-img-style-right" onClick={() => handleCloseAccept("accept", data?._id)}>
                        <img className="pr-dating-right-img" src={correctBtn} alt="add-icon" />
                      </Button>
                    </Box>
                  </Card>
                </Grid>
              );
            })
            ) : (<Box style={{ display: "flex", color: "orange", margin: "20px 0 0 30px" }}> No Request Found! </Box>)}
          </Grid>

          {/* Pop-up after accepting request */}
          <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
            <DialogTitle id="alert-dialog-title" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <img src={vmdLogo} height={60} alt="vmdLogo" style={{ boxShadow: "-4px 6px 30px -15px rgba(0,0,0,0.75)", borderRadius: "50%" }} />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#F8931F", fontWeight: "bold" }}>
                Congratulations!
              </DialogContentText>
              <DialogContentText id="alert-dialog-description" style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#000", margin: "10px", fontWeight: "bold" }}>
                You made a new Connection.
              </DialogContentText>
              <DialogContentText id="alert-dialog-description" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={vmdFLogo} height={60} alt="vmdFLogo" />
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};
export default PRDating;