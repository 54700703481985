import React, { useEffect, useState } from "react";
import { Box, Button, Typography, IconButton, InputBase, Paper, CardHeader, Avatar, CardMedia, CardContent, Divider, CardActions, Grid, Card } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SearchIcon from "@mui/icons-material/Search";
import "./DRacipes.css";
import shareImg from "../../../images/CW/Icon ionic-ios-share-alt@2x.png";
import dummyRestro from "../../../images/CW/dummyRestro.png";
import dummyProfile from "../../../images/CW/profile.jpeg";
import { FacebookShareButton } from "react-share";
// Importing Cards
import BreakFastCard from "./BreakFastCard";
import DinnerCard from "./DinnerCard";
import LunchCard from "./LunchCrad";
import SnackCard from "./SnackCard";
// Advt. Img
import advtImg from "../../../images/CW/advertise.jpeg";
import { getDiff, ImgUrl } from "../../../constants/const";
import { useDispatch, useSelector } from "react-redux";
import { getRecipeMenu, searchRecipe } from "../../../redux/action/recipe";
import Spinner from "../../../constants/Spinner";
import { Link, useNavigate } from "react-router-dom";

const DRacipes = () => {
  const defaultID = `628ddaf8d3acde9900c18e1c`;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState("Breakfast");
  const [activeIndex, setActiveIndex] = useState(`Breakfast`);
  const [activeId, setActiveId] = useState(defaultID);

  const recipe_data = useSelector((state) => state.recipe.call_response?.data);
  const searchRecipeData = useSelector((state) => state.recipe?.search_recipe?.data);
  const searchData = async (e) => {
    setSearch(e);
    let data = {
      keyword: e,
    };
    dispatch(searchRecipe(data));
  };
  const emptySearchBar = () => {
    setSearch('')
    let data = {
      keyword: '',
    };
    dispatch(searchRecipe(data));
  }

  useEffect(() => {
    localStorage.setItem("air", activeIndex ?? "Breakfast")
  }, [activeIndex])
  useEffect(() => {
    localStorage.setItem("air_id", activeId ?? defaultID)
  }, [activeId])

  const setDataRecipe = (data) => {
    setActiveIndex(data?.name);
    setActive(data?.name);
    setActiveId(data._id);
  };
  useEffect(() => {
    let token = localStorage.getItem("token");
    emptySearchBar()
    dispatch(getRecipeMenu(token));
  }, []);
  useEffect(() => {
    if (recipe_data && loading) {
      setLoading(false);
    }
  }, [recipe_data]);

  // FOR ADVT.
  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle
        adsbygoogle.push({})
      } catch (e) {
        console.error('error',e)
      }
    }

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd()
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval)
      }
    }, 300)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    
    <>
      {loading ? (<Spinner />) : (
        <Box>
          <Box className="recipes_top_buttons_style" >
            <Box> <Typography className="common_medium_font" > Recipes </Typography> </Box>
            {/* Search Box Desktop */}
            <Box className="recipes_search_bar_add_btn">
              <Box className="recipes_search_bar">
                <Paper sx={{ p: "0 4px", display: "flex", alignItems: "center", marginRight: "10px", background: "#F89601", }} style={{ height: "50px", font: "normal 18px/20px Montserrat", }} >
                  <InputBase sx={{ ml: 1, flex: 1, color: "#fff" }} placeholder="Search" inputProps={{ "aria-label": "Search" }} value={search} onChange={(e) => searchData(e.target.value)} />
                  <IconButton sx={{ p: "10px" }} aria-label="search" > <SearchIcon style={{ color: "#fff", fontSize: "32px", fontWeight: "800", }} /> </IconButton>
                </Paper>
                <Box style={{ maxHeight: "90vh", overflowY: "scroll", position: "absolute", zIndex: "1", width: "30%", background: "#fff", }} >
                  <Grid container spacing={2}>  
                    {searchRecipeData?.map((data, id) => {
                      return (
                        <Grid item xs={12} sm={12} md={12} lg={12} key={id}>
                          <Card sx={{ width: 1 }} style={{ borderRadius: "12px", width: "75%", margin: "10px 0", }} className="mx-auto recipes_foot_card_style" >
                            <Link to={`/cc_profile/${data?.user_id?._id}`} style={{ color: "orange" }} >
                              <CardHeader
                                avatar={<Avatar alt="per_img" src={data?.user_id?.profile_pic !== "" ? `${ImgUrl}${data?.user_id?.profile_pic}` : `${dummyProfile}`} sx={{ width: 56, height: 56, border: "2px solid orange", }} />}
                                title={data?.user_id?.full_name} subheader={getDiff(data?.created_at)}
                              />
                            </Link>
                            {data?.media_type === "image" ? (
                              <CardMedia component="img" height="200" image={data?.media !== "" ? `${ImgUrl}${data?.media}` : dummyRestro} alt="dish_image" style={{ borderRadius: "10px 10px 0 0px" }} />
                            ) : (
                              <video width="100%" height="300" controls> <source src={data?.media !== "" ? `${ImgUrl}${data?.media}` : ''} type="video/mp4" /> </video>
                            )}
                            <CardContent>
                              <Typography variant="body1" color="text"> {data?.title} </Typography>
                              <Typography variant="body2" color="text.secondary" > {data?.description} </Typography>
                            </CardContent>
                            <Divider style={{ background: "grey" }} />
                            <CardActions disableSpacing style={{ margin: "0 20px", display: "flex", flexDirection: "row", justifyContent: "center", }} >
                              <FacebookShareButton url={`${ImgUrl}${data?.media}`} quote={`"${data?.title}"`} hashtag={`#${data?.title}`} description={data?.description} className="Demo__some-network__share-button" >
                                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} > <IconButton aria-label="share"> <img src={shareImg} height={15} alt="s_icon" /> </IconButton> &nbsp; <Typography> <strong>Share</strong> </Typography> </Box>
                              </FacebookShareButton>
                            </CardActions>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>
              <AddOutlinedIcon fontSize="large" className="addicon_style" onClick={() => navigate("/add_recipes")} />
            </Box>
          </Box>

          {/* Tablet Search Bar View */}
          <Box className="recipes_search_bar_tablet">
            <Paper sx={{ p: "0 4px", display: "flex", alignItems: "center", marginRight: "10px", background: "#F89601", }} style={{ height: "50px", font: "normal 18px/20px Montserrat", width: "90%", margin: "auto", }} >
              <InputBase sx={{ ml: 1, flex: 1, color: "#fff" }} placeholder="Search Recipes" inputProps={{ "aria-label": "Search" }} value={search} onChange={(e) => searchData(e.target.value)} />
              <IconButton sx={{ p: "10px" }} aria-label="search" > <SearchIcon style={{ color: "#fff", fontSize: "32px", fontWeight: "800" }} /> </IconButton>
            </Paper>
            <Box style={{ maxHeight: "90vh", overflowY: "scroll", position: "absolute", zIndex: "1", width: "95%", background: "#fff", }} >
              <Grid container spacing={2}>
                {searchRecipeData?.map((data, id) => {
                  return (
                    <Grid item xs={12} sm={12} md={12} lg={12} key={id}>
                      <Card sx={{ width: 1 }} style={{ borderRadius: "12px", width: "75%", margin: "10px 0", }} className="mx-auto recipes_foot_card_style" >
                        <Link to={`/cc_profile/${data?.user_id?._id}`} style={{ color: "orange" }} >
                          <CardHeader
                            avatar={<Avatar alt="per_img" src={data?.user_id?.profile_pic !== "" ? `${ImgUrl}${data?.user_id?.profile_pic}` : `${dummyProfile}`} sx={{ width: 56, height: 56, border: "2px solid orange", }} />}
                            title={data?.user_id?.full_name}
                            subheader={getDiff(data?.created_at)}
                          />
                        </Link>
                        {data?.media_type === "image" ? (
                          <CardMedia component="img" height="200" image={data?.media !== "" ? `${ImgUrl}${data?.media}` : dummyRestro} alt="dish_image" style={{ borderRadius: "10px 10px 0 0px" }} />
                        ) : (
                          <video width="100%" height="300" controls> <source src={data?.media !== "" ? `${ImgUrl}${data?.media}` : ''} type="video/mp4" /> </video>
                        )}
                        <CardContent>
                          <Typography variant="body1" color="text"> {data?.title} </Typography>
                          <Typography variant="body2" color="text.secondary"> {data?.description} </Typography>
                        </CardContent>
                        <Divider style={{ background: "grey" }} />
                        <CardActions disableSpacing style={{ margin: "0 20px", display: "flex", flexDirection: "row", justifyContent: "center", }} >
                          <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", }} >
                            <IconButton aria-label="share"> <img src={shareImg} height={15} alt="s_icon" /> </IconButton> &nbsp;<Typography> <strong>Share</strong> </Typography>
                          </Box>
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
          {/* Tablet Search Bar View End */}

          {/* Recipe Buttons */}
          <Box className="second-nav" onClick={emptySearchBar} >
            {recipe_data?.map((data, id) => {
              return (
                <Button className="DRacipes__btne" key={id} style={{ backgroundColor: activeIndex === data?.name ? "#F8931F" : "#fff", borderRadius: "13px", }} onClick={() => { setDataRecipe(data); }} >
                  <Box> <img className="DRacipes__img" src={`${ImgUrl}${data?.media}`} alt={data?.alt} /> </Box>
                  <Typography className="DRacipes-text" style={{ color: activeIndex === data?.name ? "#fff" : "#000", }} > <strong>{data?.name}</strong> </Typography>
                </Button>
              );
            })}
          </Box>
          <Box className="recipes_card_styling">
            <Box style={{ height: "100vh", width: "20%" }} className="advt_recipe" >
              {/* <GoogleAdSense
                html={
                  '<ins class="adsbygoogle"\n' +
                  'style="display:block"\n' +
                  'data-ad-client="ca-pub-2720420356744936"\n' +
                  'data-ad-slot="3005737056"\n' +
                  'data-ad-format="auto"\n' +
                  'data-full-width-responsive="true"></ins>'
                }
              /> */}
              <script>
                (adsbygoogle = window.adsbygoogle || []).push({ });
              </script>
            </Box>
            <Box style={{ width: "60%" }} className="advt_recipe_medium_show">
              {active === "Breakfast" && <BreakFastCard />}
              {active === "Lunch" && <LunchCard activeId={activeId} />}
              {active === "Snacks" && <SnackCard activeId={activeId} />}
              {active === "Dinner" && <DinnerCard activeId={activeId} />}
            </Box>
            <Box style={{ height: "100vh", width: "20%" }} className="advt_recipe" >
              {/* <GoogleAdSense
                html={
                  '<ins class="adsbygoogle"\n' +
                  'style="display:block"\n' +
                  'data-ad-client="ca-pub-2720420356744936"\n' +
                  'data-ad-slot="3005737056"\n' +
                  'data-ad-format="auto"\n' +
                  'data-full-width-responsive="true"></ins>'
                }
              /> */}
              <script>
                (adsbygoogle = window.adsbygoogle || []).push({ });
              </script>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
export default DRacipes;

// 'width: "300px"\n' +
// 'height: "250px"\n' +