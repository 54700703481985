import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import "./EditProfile.css";
import { DatePicker, Input, Select, Spin, Switch, Button } from "antd";
import EditIcon from "@mui/icons-material/Edit";
import EditProfileGallery from "./EditProfileGallery";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "../../../constants/toast";
import { getUserData, updateUserProfile } from "../../../redux/action/dashboard";
import { setCallResponse } from "../../../redux/action/auth";
import EditProfileCommunity from "./EditProfileCommunity";
import EditProfileRecipe from "./EditProfileRecipe";
import { ImgUrl } from "../../../constants/const";
import moment from "moment";
import JoditEditor from 'jodit-react';
import dayjs from "dayjs";

const { TextArea } = Input;

const styles = {
  border: "none",
  outline: "none",
};

const NavigationButton = [
  {
    id: "1",
    title: "Uploads",
    fun: "U",
  },
  {
    id: "2",
    title: "Recipes",
    fun: "R",
  },
  {
    id: "3",
    title: "Community",
    fun: "C",
  },
];

const EditProfile = () => {
  const editor = useRef(null);
  const user_data = useSelector((state) => state.dashboard?.user_data);
  const [userData, setUserData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setUserData(user_data);
  }, [user_data]);

  const [full_name, setFull_name] = useState(user_data?.full_name);
  const [username, setUsername] = useState(user_data?.username);
  const [gender, setGender] = useState(user_data?.gender);
  const [dob, setDob] = useState("");
  const [bio, setBio] = useState(user_data?.bio);
  const [profile_pic, setProfile_pic] = useState("");
  const [active, setActive] = useState("U");
  const [activeIndex, setActiveIndex] = useState("1");
  const [datingSwitch, setDatingSwitch] = useState("");
  const [accountType, setAccountType] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (profile_pic) {
      setImageUrl(URL.createObjectURL(profile_pic));
    }
  }, [profile_pic]);
  // const call_response = useSelector((state) => state.dashboard?.call_response);

  // Getting Age... :)
  const date = new Date().getFullYear();
  let currAge = new Date(dob).getFullYear();
  const age = date - currAge;

  const updateProfile = async () => {
    setLoading(true);
    const successCb = (resp) => {
      toast.success(resp?.message)
      setLoading(false);
      navigate("/profile");
      return resp;
    };
    let dobb = dayjs(dob);
    let data;
    if (username == user_data?.username) {
      data = { full_name, gender, dob: dobb.format("YYYY-MM-DD"), age, profile_pic, bio };
    } else {
      data = { full_name, username, gender, dob: dobb.format("YYYY-MM-DD"), age, profile_pic, bio };
    }
    dispatch(updateUserProfile(data, successCb));
  };

  const handleDob = (datestring, date) => {
    setDob(dayjs(date));
  };

  useEffect(() => {
    if (user_data) {
      let dateee = dayjs(user_data?.dob)
      setDob(dateee);
      setFull_name(user_data?.full_name);
      setUsername(user_data?.username);
      setBio(user_data?.bio);
      setGender(user_data?.gender);
      setDatingSwitch(user_data?.dating);
      setAccountType(user_data?.account_type);
    }
  }, [user_data])

  const onAccountClick = () => {
    let accountStatusUpdate = "private";
    if (user_data?.account_type == "private") {
      accountStatusUpdate = "public";
    }
    setAccountType(accountStatusUpdate);
    const successCb = (resp) => {
      setAccountType(accountStatusUpdate);
      let token = localStorage.getItem("token") ? localStorage.getItem("token") : null;
      dispatch(getUserData(token));
      return resp;
    };
    let data = { account_type: accountStatusUpdate };
    dispatch(updateUserProfile(data, successCb));
  };

  const handleDatingSwitch = () => {
    let dateStatusUpdate = 1;
    if (user_data?.dating == 1) {
      dateStatusUpdate = 0;
    }
    const successCb = (resp) => {
      let updatedSwitch = resp?.data?.dating
      setDatingSwitch(updatedSwitch)
      let token = localStorage.getItem("token") ? localStorage.getItem("token") : null;
      dispatch(getUserData(token));
      return resp;
    };
    let data = { dating: dateStatusUpdate };
    dispatch(updateUserProfile(data, successCb));
  };

  const handleUploadFile = (e) => {
    const file = e.target.files[0]
    if (file?.size > 10 * 1024 * 1024) {
      toast.error("Image should be less than 10MB!");
      return
    }
    setProfile_pic(file)
  }

  // useEffect(() => {
  //   if (call_response) {
  //     if (!call_response?.status) {
  //       setLoading(false);
  //     } else {
  //       setLoading(false);
  //     }
  //     dispatch(setCallResponse(null));
  //   }
  // }, [call_response]);

  const config = {
    readOnly: false
  }

  return (
    <Box>
      <Box>
        <Box className="profile__text__btn" style={{ margin: "0 0 20px 0" }}>
          <Typography className="common_medium_font">Edit Profile</Typography>
          <Box style={{ fontWeight: "500", display: "flex", flexDirection: "column" }} className="profile__text__btn" >
            <Box className="profile_switch_style mb-2">
              Account Type ({user_data?.account_type == "public" ? "Public" : "Private"}) ;
              <Switch checked={user_data?.account_type == "private" ? "checked" : ""} onClick={() => onAccountClick()} style={{ backgroundColor: accountType === "Private" ? "#F8931F" : "#F8931F" }} />
            </Box>
            <Box className="profile_switch_style">
              Dating ({user_data?.dating === 0 ? "Off" : "On"}) &nbsp;
              <Switch checked={user_data?.dating === 1 ? "checked" : ""} onClick={() => handleDatingSwitch()} style={{ backgroundColor: datingSwitch === "Off" ? "#F8931F" : "#F8931F" }} />
            </Box>
          </Box>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <Box style={{ position: "relative", display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
              <Box className="profile_image_ansh_edit" style={{ display: "flex", flexDirection: "row", position: "relative" }} >
                <Box component="label" className="user_background_img_setting" style={{ height: "200px", width: "200px", cursor: "pointer", borderRadius: "50%", border: "1px solid #F89601", display: "flex", flexDirection: "left", background: 'url("' + ImgUrl + userData?.profile_pic + '")', backgroundPosition: "center", backgroundSize: "cover", }} >
                  {imageUrl == null ?
                    <Box className="edit-btn-style"> <EditIcon style={{ color: "#F8931F", position: "absolute" }} /> </Box>
                    : ""}
                  <input hidden accept="image/*" type="file" onChange={handleUploadFile} />
                  {imageUrl && profile_pic && (
                    <Box> <img src={imageUrl} alt={profile_pic.name} style={{ height: "200px", width: "200px", cursor: "pointer", borderRadius: "50%", display: "flex", flexDirection: "left", border: '2px solid orange' }} /> </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Input className="common_input_fields mb-0" placeholder="Full Name" value={full_name ?? userData?.full_name} onChange={(e) => setFull_name(e.target.value)} style={styles} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Input className="common_input_fields mb-0" placeholder="Username" value={username ?? userData?.username} onChange={(e) => setUsername(e.target.value)} style={styles} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Select className="common_input_fields" onChange={(e) => { setGender(e) }} value={gender || user_data?.gender} style={{ width: "100%" }} >
                  <Select.Option value="male">Male</Select.Option>
                  <Select.Option value="female">Female</Select.Option>
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <DatePicker
                  disabledDate={(current) => current?.isAfter(moment())}
                  onChange={handleDob}
                  className="common_input_fields"
                  value={dob}
                // format={"YYYY-MM-DD"}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="my-2">
              <JoditEditor
                ref={editor}
                config={config}
                value={bio || userData?.bio}
                tabIndex={1} // tabIndex of textarea
                onBlur={newContent => setBio(newContent)}
                onChange={e => { }}
              />
            </Grid>
          </Grid>
        </Grid>

        <div className="row g-1 g-lg-2 my-3">
          {NavigationButton.map((data, id) => {
            return (
              <div className="col-4 col-md-3 col-lg-3 col-xxl-2">
                <Button
                  className="common_btn w-100"
                  onClick={() => {
                    setActiveIndex(data.id);
                    setActive(data.fun);
                  }}
                  style={{
                    backgroundColor: activeIndex === data.id ? "#F8931F" : "#fff",
                  }}
                  key={id}
                >
                  <Typography
                    className="profile_btn_card"
                    style={{
                      color: activeIndex === data.id ? "#fff" : "#F8931F",
                    }}
                  >
                    {data.title}
                  </Typography>
                </Button>
              </div>
            );
          })}
        </div>
        {active === "U" && <EditProfileGallery />}
        {active === "R" && <EditProfileRecipe />}
        {active === "C" && <EditProfileCommunity />}
      </Box>


      <div className="row mt-4 container">
        <div className="col-5 col-md-4 col-lg-3 mx-auto">
          <Button loading={loading} className="common_btn w-100" onClick={() => { updateProfile() }} >update</Button>
        </div>
      </div>
    </Box>
  );
};
export default EditProfile;