import {
    GET_HOME_CARDS,
    LEFT_SWIPE,
    RIGHT_SWIPE,
    GET_REQUEST_LIST,
    UPDATE_LAT_LONG,
    DELETE_MY_CONNECTION
  } from "../constants";
  const INIT_STATE = {
    token: "",
    user_data: null,
    call_response: null,
    delete_my_conn: null,
    get_dating_card:null,
    left_swipe_card:null,
    get_request_list: null,
  };
  
  export default (state = INIT_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_HOME_CARDS:
        return {
          ...state,
          get_dating_card: payload,
        };
      case LEFT_SWIPE:
        return {
          ...state,
          left_swipe_card: payload,
        };
      case RIGHT_SWIPE:
        return {
          ...state,
          call_response: payload,
        };
      case GET_REQUEST_LIST:
        return {
          ...state,
          get_request_list: payload,
        };
      case UPDATE_LAT_LONG:
        return {
          ...state,
          call_response: payload,
        };
      case DELETE_MY_CONNECTION:
        return {
          ...state,
          delete_my_conn: payload,
        };
      default:
        return state;
    }
  };
  