import { START_TIMER, STORE_GENDER_DATA } from "../constants";
export const activateTimer = (data) => async (dispatch) => {
  dispatch({
    type: START_TIMER,
    payload: data
  });
}

export const storeCommonData = (data) => async (dispatch) => {
  dispatch({
      type: STORE_GENDER_DATA,
      payload: data,
  });
};