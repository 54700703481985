import AgoraRTC from 'agora-rtc-sdk-ng';
import $ from 'jquery';

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

var localTracks = {
    videoTrack: null,
    audioTrack: null
};
var remoteUsers = {};

var options = {
    appid: null,
    channel: null,
    uid: null,
    token: null
};

export const load = async () => {
    try {
        let callPayload = JSON.parse(sessionStorage.getItem("callPayload"))
        options.appid = callPayload?.appID;
        options.token = callPayload?.token;
        options.channel = callPayload?.channel;
        await join();
    } catch (e) {
        console.log("error while start call")
    }
}

const join = async () => {
    client.on("user-published", handleUserPublished);
    client.on("user-left", handleUserUnpublished);
    [options.uid, localTracks.audioTrack, localTracks.videoTrack] = await Promise.all([
        client.join(options.appid, options.channel, options.token),
        AgoraRTC.createMicrophoneAudioTrack(),
        AgoraRTC.createCameraVideoTrack()
    ]);
    localTracks.videoTrack.play("local-player");
    await client.publish(Object.values(localTracks));
}

export const manageCam = async (state) => {
    await localTracks?.videoTrack?.setMuted(state)
}

export const manageMic = async (state) => {
    await localTracks?.audioTrack?.setMuted(state)
}

const subscribe = async (user, mediaType) => {
    const uid = user.uid;
    await client.subscribe(user, mediaType);
    if (mediaType === 'video') {
        const player = $(`
            <div id="player-wrapper-${uid}">
                <div id="player-${uid}" class="player"></div>
            </div>
        `);
        $("#remote-playerlist").html(player);
        user.videoTrack.play(`player-${uid}`);
    }
    if (mediaType === 'audio') {
        user.audioTrack.play();
    }
}

const handleUserPublished = (user, mediaType) => {
    const id = user.uid;
    remoteUsers[id] = user;
    subscribe(user, mediaType);
}

const handleUserUnpublished = (user) => {
    const id = user.uid;
    delete remoteUsers[id];
    $(`#player-wrapper-${id}`).remove();
}