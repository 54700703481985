import React, { useEffect, useState } from "react";
import "./prStyle.css";
import { Card, Grid, Typography } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import { Box } from "@mui/system";
import info from "../../../../images/CW/Path 22517.png";
import { Link } from "react-router-dom";
import vmdLogo from "../../../../images/Group 33033@2x.png";
import vmdFLogo from "../../../../images/Group 17276@2x.png";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { acceptRejectFollowRequestMultiple, getMyFollowRequest, markAsRead } from "../../../../redux/action/community";
import { acceptRejectFollowRequest } from "../../../../redux/action/community";
import dummyPic from "../../../../images/CW/profile.jpeg";
import { ImgUrl } from "../../../../constants/const";
import Spinner from "../../../../constants/Spinner";
import { Button } from "antd";
import toast from "../../../../constants/toast";

const PRCommunity = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const getMyFollowRequestData = useSelector((state) => state?.community?.get_my_follow_request?.data);
  const userData = useSelector((state) => state.dashboard?.user_data);

  const handleClickOpen = async (status, _id) => {
    if (status === "accepted") {
      setLoading(true);
      let token = localStorage.getItem("token");
      const successCb = (resp) => {
        if (resp.status === true) {
          dispatch(getMyFollowRequest(token));
          setLoading(false);
        }
      };
      dispatch(acceptRejectFollowRequest(token, { status, _id }, successCb));
    } else {
      setLoading(true);
      let token = localStorage.getItem("token");
      const successCb = (resp) => {
        if (resp.status === true) {
          dispatch(getMyFollowRequest(token));
          setLoading(false);
        }
      };
      dispatch(acceptRejectFollowRequest(token, { status, _id }, successCb));
    }
  };

  useEffect(() => {
    const successCb = (resp) => { }
    let data = {
      user_id: userData?._id,
      type: "follow_request"
    }
    dispatch(markAsRead(data, successCb))
  }, [getMyFollowRequestData])

  useEffect(() => {
    let token = localStorage.getItem("token");
    dispatch(getMyFollowRequest(token));
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (getMyFollowRequestData && loading) {
      setLoading(false);
    }
  }, [getMyFollowRequestData]);

  const acceptAllRequest = () => {
    const successCb = (resp) => {
      toast.success(resp?.message)
      let token = localStorage.getItem("token");
      dispatch(getMyFollowRequest(token));
    }
    const data = {
      follow_ids: JSON.stringify(getMyFollowRequestData?.map((d) => d?._id)),
      status: 'accepted',
    }
    dispatch(acceptRejectFollowRequestMultiple(data, successCb))
  }

  return (
    <>
      {loading ? (
        <>
          <Spinner />
        </>
      ) : (
        <>
          {getMyFollowRequestData?.length > 0 ? <Button onClick={() => acceptAllRequest()} style={{ background: '#56a050', color: '#fff', borderRadius: 36, height: 42, width: 162, float: 'right' }} >Accept All</Button> : ''}
          <Grid
            container
            rowSpacing={2}
            columnSpacing={2}
            sx={{ p: 4 }}
            style={{ padding: "0", minHeight: '50vh' }}
          >
            {getMyFollowRequestData?.length > 0 ? (
              getMyFollowRequestData?.map((data, id) => {
                return (
                  <>
                    {data?.follow_by?._id === userData?._id ?
                      <Grid item xs={12} sm={6} md={4} lg={2} key={id}>
                        <Card
                          sx={{ width: 1 }}
                          style={{
                            margin: "0",
                            padding: "0",
                            boxShadow: "9px 9px 31px -10px rgba(0,0,0,0.75)",
                            borderRadius: "12px",
                          }}
                        >
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="200"
                            image={
                              data?.follow_to?.profile_pic !== ""
                                ? `${ImgUrl}${data?.follow_to?.profile_pic}`
                                : dummyPic
                            }
                            style={{ margin: "0", padding: "0" }}
                          />
                          <Typography className="pr-text-icon-style">
                            <Link
                              style={{ color: "#000", fontWeight: '600' }}
                              to={`/user_profile_pr/${data?.follow_to?._id}`}
                            >
                              {data?.follow_to?.full_name} &nbsp;
                              <img
                                src={info}
                                style={{ height: "15px", cursor: "pointer" }}
                                alt="logo-img"
                              />
                            </Link>
                          </Typography>
                          <CardActions
                            style={{ margin: "0", padding: "0", height: "40px" }}
                          >
                            <Box
                              size="medium"
                              className="card-dating-btn"
                              style={{ color: "red", background: "#fff" }}
                              onClick={() => handleClickOpen("rejected", data?._id)}
                            >
                              Decline
                            </Box>
                            <Box
                              size="small"
                              className="card-dating-btn"
                              style={{
                                background: "green",
                                color: "#f6f6f6",
                                height: "40px",
                              }}
                              onClick={() => handleClickOpen("accepted", data?._id)}
                            >
                              Accept
                            </Box>
                          </CardActions>
                        </Card>
                      </Grid>
                      :
                      <Grid item xs={12} sm={6} md={4} lg={2} key={id}>
                        <Card
                          sx={{ width: 1 }}
                          style={{
                            margin: "0",
                            padding: "0",
                            boxShadow: "9px 9px 31px -10px rgba(0,0,0,0.75)",
                            borderRadius: "12px",
                          }}
                        >
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="200"
                            image={
                              data?.follow_by?.profile_pic !== ""
                                ? `${ImgUrl}${data?.follow_by?.profile_pic}`
                                : dummyPic
                            }
                            style={{ margin: "0", padding: "0" }}
                          />
                          <Typography className="pr-text-icon-style">
                            <Link
                              style={{ color: "#000", fontWeight: '600' }}
                              to={`/user_profile_pr/${data?.follow_by?._id}`}
                            >
                              {data?.follow_by?.full_name} &nbsp;
                              <img
                                src={info}
                                style={{ height: "15px", cursor: "pointer" }}
                                alt="logo-img"
                              />
                            </Link>
                          </Typography>
                          <CardActions
                            style={{ margin: "0", padding: "0", height: "40px" }}>
                            <Box
                              size="medium"
                              className="card-dating-btn"
                              style={{ color: "red", background: "#fff" }}
                              onClick={() => handleClickOpen("rejected", data?._id)}>
                              Decline
                            </Box>
                            <Box
                              size="small"
                              className="card-dating-btn"
                              style={{
                                background: "green",
                                color: "#f6f6f6",
                                height: "40px",
                              }}
                              onClick={() => handleClickOpen("accepted", data?._id)}>
                              Accept
                            </Box>
                          </CardActions>
                        </Card>
                      </Grid>
                    }
                  </>
                );
              })
            ) : (
              <Box
                style={{
                  display: "flex",
                  color: "orange",
                  margin: "20px 0 0 30px",
                }}
              >
                No Request Found!
              </Box>
            )}
          </Grid>

          {/* Pop-up */}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={vmdLogo}
                height={60}
                alt="vmdLogo"
                style={{
                  boxShadow: "-4px 6px 30px -15px rgba(0,0,0,0.75)",
                  borderRadius: "50%",
                }}
              />
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#F8931F",
                  fontWeight: "bold",
                }}
              >
                Congratulations!
              </DialogContentText>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#000",
                  margin: "10px",
                  fontWeight: "bold",
                }}
              >
                You made a new Connection.
              </DialogContentText>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={vmdFLogo} height={60} alt="vmdFLogo" />
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};
export default PRCommunity;
