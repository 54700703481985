import { useEffect, useState } from "react";
import { CameraFilled } from "@ant-design/icons";
import { Box, Button, Typography } from "@mui/material";
import { Input, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "../../../constants/toast";
import { addRecipePost, getRecipeMenu } from "../../../redux/action/recipe";
import "./AddRacipes.css";
import CloseIcon from "@mui/icons-material/Close";
import { ImgUrl } from "../../../constants/const";
import advtImg from "../../../images/CW/advertise.jpeg";

const { TextArea } = Input;
const AddRacipes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDesc] = useState("");
  const [recipe_media, setMedia] = useState(null);
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState(null);
  const [activeIndex, setActiveIndex] = useState(localStorage.getItem("air") ?? `Breakfast`);
  const [activeId, setActiveId] = useState(localStorage.getItem("air_id"));

  const recipe_data = useSelector((state) => state.recipe.call_response?.data);
  let mediatype = recipe_media?.type.split("/")
  useEffect(() => {
    if (recipe_media) {
      setImageUrl(URL.createObjectURL(recipe_media));
    }
  }, [recipe_media]);

  const setDataRecipe = (data) => {
    setActiveIndex(data?.name);
    setActiveId(data._id ?? localStorage.getItem("air_id"));
  };
  // Clear the Image box
  const clearImage = () => {
    setImageUrl(null);
    setMedia(null);
  };

  const sendRecipePost = async () => {
    setLoading(true)
    if (title === "" || description === "") {
      setLoading(false);
      return false;
    }
    const successCb = () => {
      setLoading(false);
      toast.success("Post uploaded successfully");
      navigate(`/DRacipes`);
    };
    let data = { menu_id: activeId ? activeId : `628ddaf8d3acde9900c18e1c`, title, description, recipe_media, };
    dispatch(addRecipePost(data, successCb));
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    dispatch(getRecipeMenu(token));
  }, []);

  useEffect(() => {
    sendRecipePost();
  }, []);

  // FOR ADVT.
  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle
        adsbygoogle.push({})
      } catch (e) {
        console.error('error', e)
      }
    }

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd()
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval)
      }
    }, 300)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const handleUploadFile = (e) => {
    const file = e.target.files[0]
    if (file?.type == "image/jpeg" && file?.size > 10 * 1024 * 1024) {
      toast.error("Image should be less than 10MB!");
      return
    }
    setMedia(e.target.files[0])
  }

  return (
    <>
      <Box className="second-nav">
        {recipe_data?.map((data, id) => {
          return (
            <Button className="DRacipes__btne" key={id} style={{ backgroundColor: activeIndex === data?.name ? "#F8931F" : "#fff", borderRadius: "13px", }} onClick={() => { setDataRecipe(data); }} >
              <Box> <img className="DRacipes__img" src={`${ImgUrl}${data?.media}`} alt={data?.alt} /> </Box>
              <Typography className="DRacipes-text" style={{ color: activeIndex === data?.name ? "#fff" : "#000", }} > <strong>{data?.name}</strong> </Typography>
            </Button>
          );
        })}
      </Box>
      <div className="row">
        <div className="d-none d-lg-block col-lg-3" >
          {/* <GoogleAdSense
            html={
              '<ins class="adsbygoogle"\n' +
              'style="display:block"\n' +
              'data-ad-client="ca-pub-5665970448524979"\n' +
              'data-ad-slot="4528146213"\n' +
              'data-ad-format="auto"\n' +
              'data-full-width-responsive="true"></ins>'
            }
          /> */}
        </div>
        <div style={{}} className="col-12 col-lg-6">
          <Box>
            {recipe_media === null ?
              <Box component="label" style={{ border: "1px dotted black", display: "flex", justifyContent: "center", flexDirection: "column", height: "25vh", alignItems: "center", borderRadius: "15px", }} >
                <CameraFilled style={{ color: "#000", fontSize: "34px" }} /> Upload Media <input hidden type="file" name="file" accept="video/mp4,image/*" onChange={handleUploadFile} />
              </Box> : ''}
            {imageUrl && recipe_media && (<Box mt={2} textAlign="center">
              <Box
                onClick={() => clearImage()}
                style={{
                  cursor: "pointer",
                  float: "right",
                  fontSize: "large"
                }}>
                <CloseIcon />
              </Box>
              {mediatype[0] == "image" ? <img src={imageUrl} alt={recipe_media.name} height="150px" />
                : <video height="250" controls > <source src={imageUrl} type="video/mp4" /> </video>}
            </Box>)}
          </Box>
          <Box className="mt-3">
            <Input placeholder="Title" className="common_input_fields" value={title} onChange={(e) => setTitle(e.target.value)} />
            <TextArea placeholder="Description" rows={6} value={description} onChange={(e) => setDesc(e.target.value)} className="common_input_fields rounded" />
          </Box>
          <Box className="addRecipes--btn" style={{ textTransform: "capitalize" }} >
            <Spin spinning={loading}>
              <Button onClick={() => sendRecipePost()} className="AddRacipes-btn" > Publish </Button>
            </Spin>
          </Box>
        </div>
        <div style={{}} className="d-none d-lg-block col-lg-3" >
          {/* <GoogleAdSense
            html={
              '<ins class="adsbygoogle"\n' +
              'style="display:block"\n' +
              'data-ad-client="ca-pub-5665970448524979"\n' +
              'data-ad-slot="4528146213"\n' +
              'data-ad-format="auto"\n' +
              'data-full-width-responsive="true"></ins>'
            }
          /> */}
        </div>
      </div>
    </>
  );
};
export default AddRacipes;