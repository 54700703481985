import {
  GET_COMMUNITY_POST,
  GET_COMMUNITY_GRID,
  GET_MY_COMMUNITY_POST,
  ADD_COMMUNITY_POST,
  EDIT_COMMUNITY_POST,
  EDIT_COMMUNITY_POST_DETAIL,
  ADD_COMMENT_COMMUNITY,
  GET_POST_COMMENT,
  LIKE_POST_OF_COMMUNITY,
  GET_COMMUNITY_POST_LIKE,
  DELETE_COMMENT,
  DELETE_COMMUNITY_POST,
  SEARCH_COMMUNITY,
  GET_MY_FOLLOW_REQUEST,
  ACCEPT_REJECT_REQUEST,
  GET_FRIEND_LIST,
  SEARCH_BY_USERNAME,
  SEARCH_BY_USERNAME_COPY,
  GET_MY_LIKE_NOTIFICATION,
  GET_ALL_NOTIFICATION,
  ACCEPT_REJECT_FOLLOW_REQUEST,
  DELETE_FRIEND,
  DELETE_NOTIFICATION,
  SEND_FOLLOW_REQUEST,
  GET_MY_CONNECTION,
  ADD_COMMUNITY_COMMENTS,
  GET_COMMUNITY_COMMENTS,
  LIKE_COMMUNITY_POST,
  GET_COMMUNITY_POST_DETAIL,
  MARK_AS_READ,
  ACCEPT_REJECT_FOLLOW_REQUEST_MULTIPLE,
  ACCEPT_REJECT_REQUEST_MULTIPLE
} from "../constants";
const INIT_STATE = {
  user_data: null,
  call_response: null,
  community_explore: null,
  community_post: null,
  like_by: null,
  get_community:null,
  search_community : null,
  accept_reject_request:null,
  accept_reject_request_multiple:null,
  get_my_follow_request:null,
  accept_reject_follow_request: null,
  accept_reject_follow_request_multiple: null,
  delete_friend: null,
  delete_notification: null,
  notification_data:null,
  get_friend_data:null,
  search_by_username:null,
  send_follow_request:null,
  get_connection:null,
  get_community_comment:null,
  add_community_comment:null,
  like_community:null,
  delete_comment:null,
  community_post_detail:null,
  delete_communiy_poste:null,
  get_community_post_likes:null,
  edit_community_post_dtl:null,
  mark_read:null,
};

export default (state = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MY_CONNECTION:
      return {
        ...state,
        get_connection: payload,
      };
    case GET_COMMUNITY_POST:
      return {
        ...state,
        get_community: payload,
      };
    case GET_COMMUNITY_GRID:
      return {
        ...state,
        community_explore:payload,
      };
    case GET_MY_COMMUNITY_POST:
      return {
        ...state,
        community_post: payload,
      };
    case ADD_COMMUNITY_POST:
      return {
        ...state,
        call_response: payload,
      };
    case EDIT_COMMUNITY_POST:
      return {
        ...state,
        call_response: payload,
      };
    case EDIT_COMMUNITY_POST_DETAIL:
      return {
        ...state,
        edit_community_post_dtl: payload,
      };
    case ADD_COMMENT_COMMUNITY:
      return {
        ...state,
        call_response: payload,
      };
    case GET_POST_COMMENT:
      return {
        ...state,
        call_response: payload,
      };
    case LIKE_POST_OF_COMMUNITY:
      return {
        ...state,
        call_response: payload,
      };
    case GET_COMMUNITY_POST_LIKE:
      return {
        ...state,
        get_community_post_likes: payload,
      };
    case DELETE_COMMENT:
      return {
        ...state,
        delete_comment: payload,
      };
    case DELETE_COMMUNITY_POST:
      return {
        ...state,
        delete_communiy_poste: payload,
      };
    case SEARCH_COMMUNITY:
      return {
        ...state,
        search_community: payload,
      };
    case GET_MY_FOLLOW_REQUEST:
      return {
        ...state,
        get_my_follow_request: payload,
      };
    case ACCEPT_REJECT_REQUEST:
      return {
        ...state,
        accept_reject_request: payload,
      };
    case ACCEPT_REJECT_REQUEST_MULTIPLE:
      return {
        ...state,
        accept_reject_request_multiple: payload,
      };
    case GET_FRIEND_LIST:
      return {
        ...state,
        get_friend_data: payload,
      };
    case SEARCH_BY_USERNAME:
      return {
        ...state,
        search_by_username: payload,
      };
    case SEARCH_BY_USERNAME_COPY:
      return {
        ...state,
        call_response: payload,
      };
    case GET_MY_LIKE_NOTIFICATION:
      return {
        ...state,
        like_by: payload,
      };
    case GET_ALL_NOTIFICATION:
      return {
        ...state,
        notification_data: payload,
      };
    case ACCEPT_REJECT_FOLLOW_REQUEST:
      return {
        ...state,
        accept_reject_follow_request: payload,
      };
    case ACCEPT_REJECT_FOLLOW_REQUEST_MULTIPLE:
      return {
        ...state,
        accept_reject_follow_request_multiple: payload,
      };
    case DELETE_FRIEND:
      return {
        ...state,
        delete_friend: payload,
      };
    case DELETE_NOTIFICATION:
      return {
        ...state,
        delete_notification: payload,
      };
    case SEND_FOLLOW_REQUEST:
      return {
        ...state,
        send_follow_request: payload,
      };
    case ADD_COMMUNITY_COMMENTS:
      return {
        ...state,
        add_community_comment: payload,
      };
    case GET_COMMUNITY_COMMENTS:
      return {
        ...state,
        get_community_comment: payload,
      };
    case LIKE_COMMUNITY_POST:
      return {
        ...state,
        like_community: payload,
      };
    case GET_COMMUNITY_POST_DETAIL:
      return {
        ...state,
        community_post_detail: payload,
      };
    case MARK_AS_READ:
      return {
        ...state,
        mark_read: payload,
      };
    default:
      return state;
  }
};
