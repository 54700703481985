import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import "../DRacipes/Card.css";
import { Box } from "@mui/system";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid } from "@mui/material";
import dummyRestro from "../../../images/CW/dummyRestro.png";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ImgUrl } from "../../../constants/const";
import { useDispatch, useSelector } from "react-redux";
import { deleteCommunityPost, communityPostDetail, editCommunityPost, getCommunityPost } from "../../../redux/action/community";
import { Modal } from "react-bootstrap";
import { Input, Spin, Typography } from "antd";
import { getUserData } from "../../../redux/action/dashboard";
import toast from "../../../constants/toast";
import ReactHlsPlayer from "react-hls-player";

const { TextArea } = Input;
const styles = {
  position: "absolute",
  top: "8px",
  right: "8px",
  background: "#fff",
  height: "25px",
  width: "25px",
  padding: "5px",
  borderRadius: "50%",
  color: "#F89601",
  cursor: "pointer",
  boxShadow: "0px 3px 6px #00000029",
};

const styles2 = {
  position: "absolute",
  top: "40px",
  right: "8px",
  background: "#fff",
  height: "25px",
  width: "25px",
  padding: "5px",
  borderRadius: "50%",
  color: "#F89601",
  cursor: "pointer",
  boxShadow: "0px 3px 6px #00000029",
};

export default function EditProfileCommunity() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [idData, setIdData] = useState(null);

  // For Edit
  const [show, setShow] = useState(false);
  const [idData1, setIdData1] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDesc] = useState("");
  const [community_media, setCommunity_media] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (community_media) {
      setImageUrl(URL.createObjectURL(community_media));
    }
  }, [community_media]);

  // Edit Start
  const handleCloseEdit = () => setShow(false);
  const handleShowEdit = (id) => {
    setShow(true);
    setIdData1(id);
    const successCb = (res) => {
      setTitle(res?.data?.title)
      setDesc(res?.data?.description)
      return res;
    };
    let data = { community_post_id: id };
    dispatch(communityPostDetail(data, successCb));
  };

  const editCommunitySave = () => {
    setLoading(true)
    setShow(false);
    const successCb = (res) => {
      toast.success(res?.message)
      let token = localStorage.getItem("token") ? localStorage.getItem("token") : null;
      dispatch(getCommunityPost(token, { page: '1' }));
      setLoading(false)
      return res;
    };
    let data = {
      post_id: idData1,
      title,
      description,
      community_media,
    };
    dispatch(editCommunityPost(data, successCb));
  };

  // Edit End

  const userCommunityData = useSelector((state) => state.dashboard?.user_data?.community_data);

  const editCommunityPostDtl = useSelector(
    (state) => state.community?.community_post_detail?.data
  );

  // Delete Confirmation Start
  const handleClickOpen = (id) => {
    setOpen(true);
    setIdData(id);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // Delete Confirmation Start

  const deleteCommunityy = async () => {
    setLoading(true)
    setOpen(false);
    const successCb = (res) => {
      toast.success(res?.message)
      let token = localStorage.getItem("token") ? localStorage.getItem("token") : null;
      dispatch(getCommunityPost(token, { page: '1' }));
      setLoading(false)
      return res;
    };
    let data = {
      post_id: idData,
    };

    dispatch(deleteCommunityPost(data, successCb));
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    dispatch(getCommunityPost(token, { page: '1' }));
  }, [])

  return (
    <Box style={{ margin: "0 20px" }}>
      <Grid container spacing={2} style={{ maxHeight: "40vh", overflowY: "scroll" }}>
        {userCommunityData?.length > 0 ? userCommunityData?.map((data, id) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={2} key={id}>
              <Card style={{ boxShadow: "9px 9px 31px -10px rgba(0,0,0,0.75)", borderRadius: "10px", position: "relative" }} >
                {data?.media_type === "image" ? (
                  <CardMedia component="img" image={data?.media !== "" ? `${ImgUrl}${data?.media}` : dummyRestro} alt="media"
                    height="200px" />
                ) : (
                  data?.media.includes('mp4') ?
                    <video width="100%" height="200" controls> <source src={data?.media !== "" ? `${ImgUrl}${data?.media}` : dummyRestro} type="video/mp4" /> </video>
                    :
                    <ReactHlsPlayer
                      src={`${ImgUrl}${data?.media}`}
                      autoPlay={false}
                      width="100%"
                      height="200px"
                      style={{ objectFit: "cover" }}
                      controls
                    />
                )}
                <EditIcon style={styles} onClick={() => handleShowEdit(data?._id)} />
                <DeleteIcon style={styles2} onClick={() => handleClickOpen(data?._id)} />
              </Card>
            </Grid>
          );
        }) : 'No data added yet!'}
      </Grid>

      {/* Delete Post Pop-up */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText
            style={{
              color: "#000",
              fontFamily: "Montserrat",
              fontWeight: "normal",
              marginTop: "15px 0",
            }}
          >
            Are you sure you want to delete this picture?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button onClick={handleClose} style={{ color: "#000" }}>
            <strong>No</strong>
          </Button>
          <Button onClick={() => deleteCommunityy()} style={{ color: "#F8931F" }}>
            <Spin spinning={loading}><strong>Yes</strong></Spin>
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Pop-Up */}
      <Modal show={show} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Community Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Box>
            {/* <Box>
              {community_media == null || community_media?.type == undefined ?
                <Box component="label" className="user_background_img_setting" style={{ border: "1px dotted black", width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", height: "25vh", alignItems: "center", borderRadius: "15px", cursor: "pointer", background: 'url("' + ImgUrl + editCommunityPostDtl?.media + '")', }} >
                  <input hidden type="file" name="file" onChange={(e) => setCommunity_media(e.target.files[0])} />
                </Box>
                : ''}
              {imageUrl && community_media && (
                <Box mt={2} textAlign="center">
                  {community_media?.type == "image/png" || "image/jpeg" || "image/gif" ?
                    <img src={imageUrl} alt={community_media.name} height="75px" width="100%" />
                    : <video width="auto" height="100px" controls > <source src={imageUrl} type="video" /> </video>}
                </Box>
              )}
            </Box> */}

            {community_media == null || community_media?.type == undefined ?
              <Box component="label" className="user_background_img_setting"
                style={{
                  border: "1px dotted black",
                  width: "100%", display: "flex",
                  justifyContent: "center", flexDirection: "column",
                  height: "400px", alignItems: "center", borderRadius: "10px", cursor: "pointer",
                }}
              >
                <h2 className="btn btn-dark btn-sm">upload</h2>
                {editCommunityPostDtl?.media_type == "video" && editCommunityPostDtl?.media.includes('mp4') ?
                  <video className="community_card">
                    <source src={`${ImgUrl}${editCommunityPostDtl?.media}`} type="video/mp4" />
                  </video>
                  :
                  editCommunityPostDtl?.media_type == "image" ?
                    <img className="community_card" src={`${ImgUrl}${editCommunityPostDtl?.media}`} />
                    :
                    <ReactHlsPlayer
                      src={`${ImgUrl}${editCommunityPostDtl?.media}`}
                      autoPlay={false}
                      className="community_card"
                      style={{ objectFit: "cover" }}
                      controls
                    />
                }
                <input hidden type="file" name="file" onChange={(e) => setCommunity_media(e.target.files[0])} />
              </Box>
              : ''}

            <div className="mt-3">
              <Typography className="small_font mb-1">Title</Typography>
              <Input className="common_input_fields" value={title} onChange={(e) => setTitle(e.target.value)} />
              <Typography className="small_font mb-1">Description</Typography>
              <TextArea value={description} onChange={(e) => setDesc(e.target.value)} className="common_input_fields rounded" 
                style={{minHeight:"100px"}}
              />
            </div>
            <Box className="addRecipes--btn mt-3" style={{ textTransform: "capitalize" }} >
              <Spin spinning={loading}> <Button className="AddRacipes-btn" onClick={() => editCommunitySave()} > Save </Button></Spin>
            </Box>
          </Box>
        </Modal.Body>
      </Modal>
    </Box>
  );
}
