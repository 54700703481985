import React, { useState } from "react";
import { Button, Card, CardMedia, Grid } from "@mui/material";
import { Box } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CardContent from "@mui/material/CardContent";
import { useSelector } from "react-redux";
import { ImgUrl } from "../../../constants/const";
import dummyRestro from "../../../images/CW/dummyRestro.png";
import { Input } from "antd";
import { Modal } from "react-bootstrap";
import Divider from "@mui/material/Divider";
import shareImg from "../../../images/CW/Icon ionic-ios-share-alt@2x.png";
import { FacebookShareButton } from "react-share";
import ReactHlsPlayer from "react-hls-player";

const { TextArea } = Input;

const ProfileRecipe = () => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState(null);
  const [description, setDesc] = useState(null);
  const [imageset, setImageset] = useState(null);
  const [type, setType] = useState(null);

  const handleClose = () => { setOpen(false) };
  const handleClickOpen = () => { setOpen(true) };
  const recipe_data = useSelector((state) => state.dashboard?.user_data?.recipe_data);

  const handleClickOpenDesc = (data) => {
    setShow(true)
    setTitle(data?.title)
    setDesc(data?.description)
    setImageset(data?.media)
    setType(data?.media_type)
  }
  const handleCloseDesc = () => {
    setShow(false);
    setTitle('')
    setDesc('')
    setImageset('')
    setType('')
  };

  const previewMedia = () => {

  }

  return (
    <Box>
      <Box style={{ display: "flex", flexDirection: "row-reverse", color: "#000" }} >
        <Button className="see_more_card_btn" onClick={handleClickOpen}> See All </Button>
      </Box>

      <Grid container spacing={2} style={{ maxHeight: "40vh", overflowY: "scroll" }}>
        {recipe_data?.length > 0 ? recipe_data?.map((data, id) => {
          return (
            <Grid item xs={12} sm={3} md={2} lg={2} key={id}>
              <Card onClick={() => handleClickOpenDesc(data)} sx={{ maxWidth: 320 }} style={{ margin: "0", padding: "0", boxSizing: "border-box", position: "relative", boxShadow: "9px 9px 31px -10px rgba(0,0,0,0.75)", borderRadius: "10px", border: "2px solid orange", objectFit: "cover", }} >
                {data?.media_type === "image" ? (
                  <CardMedia component="img" image={data?.media !== "" ? `${ImgUrl}${data?.media}` : dummyRestro} alt="dish_image" height="200px" />
                ) : (
                  data?.media.includes('mp4') ?
                    <video width="100%" height="200px" controls> <source src={data?.media !== "" ? `${ImgUrl}${data?.media}` : ''} type="video/mp4" /></video>
                    :
                    <ReactHlsPlayer
                      src={`${ImgUrl}${data?.media}`}
                      autoPlay={false}
                      width="100%"
                      height="200px"
                      style={{ objectFit: "cover" }}
                      controls
                    />
                )}
              </Card>
            </Grid>
          );
        }) : <p className="d-flex text-center mx-4" >No data added yet!</p>}
      </Grid>

      {/* Pop-up */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title" style={{ width: "-webkit-fill-available", minHeight: '25vh' }} >
        <DialogContent>
          <Box style={{ display: "flex", alignItems: "center", }} >
            <KeyboardBackspaceIcon onClick={handleClose} fontSize="large" style={{ flex: "start", cursor: "pointer" }} />
          </Box>
          <DialogContentText style={{ color: "#000", fontFamily: "Montserrat", fontWeight: "normal", display: "flex", textAlign: "center", }} >
            <Box style={{ margin: "0", padding: "0" }}>
              <CardContent>
                <Box>
                  <Grid container spacing={2}>
                    {recipe_data?.length > 0 ? recipe_data?.map((data, id) => {
                      return (
                        <Grid item xs={12} sm={4} md={4} lg={4} key={id}>
                          {data?.media_type === "image" ? (
                            <CardMedia component="img" image={data?.media !== "" ? `${ImgUrl}${data?.media}` : dummyRestro} alt="dish_image" style={{ borderRadius: "10px 10px 0 0px", height: "100%", }} />
                          ) : (
                            <video src={data?.media !== "" ? `${ImgUrl}${data?.media}` : ''} width="200" height="200" controls="controls" />)}
                        </Grid>
                      );
                    }) : <p className="d-flex text-center mx-4" >No data added yet!</p>}
                  </Grid>
                </Box>
              </CardContent>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Show Details */}
      <Modal show={show} onHide={handleCloseDesc}>
        <Modal.Header closeButton ><Modal.Title>Recipe Post</Modal.Title></Modal.Header>
        <Modal.Body>
          <Box>
            <Box>
              <Box mt={2} textAlign="center">
                {type == "image" ?
                  <img src={`${ImgUrl}${imageset}`} alt='img' className="community_card" />
                  :
                  imageset?.includes('mp4') ?
                    <video className="community_card" controls src={`${ImgUrl}${imageset}`} />
                    :
                    <ReactHlsPlayer
                      src={`${ImgUrl}${imageset}`}
                      className="community_card"
                      controls
                    />
                }
              </Box>
            </Box>
            <Box>
              <p style={{ fontWeight: "bold", margin: "10px 10px 0" }}>{title}</p>
              <TextArea readOnly rows={2} value={description} style={{ margin: "10px 0", border: 0 }} />
            </Box>
            <Divider style={{ background: "grey", marginBottom: 10 }} />
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <FacebookShareButton url={`${ImgUrl}${imageset}`} quote={`${title}`} hashtag={`#${title}`} description={description} className="Demo__some-network__share-button" >
                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer", }} >
                  <label style={{ cursor: "pointer", fontWeight: "bold" }}>
                    <img src={shareImg} height={15} alt="s_icon" /> Share
                  </label>
                </Box>
              </FacebookShareButton>
            </Box>
          </Box>
        </Modal.Body>
      </Modal>
    </Box>
  );
};
export default ProfileRecipe;