import { CALL_RESPONSE, START_TIMER, STORE_GENDER_DATA } from "../constants";

const INIT_STATE = {
  token: "",
  user_data: null,
  call_response: null,
  timer_status: false
};

export default (state = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case CALL_RESPONSE:
      return {
        ...state,
        call_response: null,
      };
    case START_TIMER:
      return {
        ...state,
        timer_status: payload
      };
    case STORE_GENDER_DATA:
      return {
        ...state,
        gender_data: payload,
      };

    default:
      return state;
  }
};
