import React, { useEffect } from "react";
import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { Layout } from "antd";
import { useSelector } from "react-redux";
import Navbar from "./navbar/Navbar";
import DashFooter from "./DashFooter";
import "./Frame2.css";
import DashHome from "../DashComponents/DashHome";
import Account from "../LayoutItems/Account/Account";
import CConnection from "../LayoutItems/CConnection/CConnection";
import DAbout from "../LayoutItems/DAbout/DAbout";
import AddPost from "../LayoutItems/DCommunity/AddPost";
import DCommunity from "../LayoutItems/DCommunity/DCommunity";
import DDating from "../LayoutItems/DDating/DDating";
import Matching from "../LayoutItems/DDating/Matching";
import Call from "../LayoutItems/DDatingCon/Call";
import DDatingCon from "../LayoutItems/DDatingCon/DDatingCon";
import VideoCall from "../LayoutItems/DDatingCon/VideoCall";
import DPlace from "../LayoutItems/DPlace/DPlace";
import Restaudetails from "../LayoutItems/DPlace/Restaudetails";
import DRacipes from "../LayoutItems/DRacipes/DRacipes";
import Feedback from "../LayoutItems/Feedback/Feedback";
import Help from "../LayoutItems/Help/Help";
import Inbox from "../LayoutItems/Inbox/Inbox";
import Liked from "../LayoutItems/Liked/Liked";
import Notification from "../LayoutItems/Notification/Notification";
import PendingReq from "../LayoutItems/PendingReq/PendingReq";
import EditProfile from "../LayoutItems/Profile/EditProfile";
import Profile from "../LayoutItems/Profile/Profile";
import Terms from "../LayoutItems/T&C/Terms";
import CCProfile from "../LayoutItems/UserProfile/CCProfile";
import UserProfile from "../LayoutItems/UserProfile/UserProfile";
import UserProfilePR from "../LayoutItems/UserProfile/UserProfilePR";
import SideBar from "./SideBar";
import CommunityProfile from "../LayoutItems/UserProfile/CommunityProfile";
import AddRacipes from "../LayoutItems/DRacipes/AddRacipes";
import MapCard from "../LayoutItems/DPlace/MapCard";
import DeleteAccount from "../LayoutItems/Account/DeleteAccount";
import MenuIcon from '@mui/icons-material/Menu';
import { Box } from "@mui/material";

const { Content, Footer, Sider } = Layout;
const Frame2 = () => {

  const user_data = useSelector((state) => state.dashboard.user_data);

  const openSide = () => {
    const side = document.getElementById("hamburger")
    side.style.marginLeft = "0px"
  }
  const closeSide = () => {
    const side = document.getElementById("hamburger")
    side.style.marginLeft = "-300px"
  }

  return (
    <Layout>
      <Router>
        <Sider className="frame_sider_styling" theme="light" breakpoint="md" collapsedWidth="0" onBreakpoint={(broken) => { }} onCollapse={(collapsed, type) => { console.log(collapsed, type) }}>
          <SideBar />
        </Sider>
        <Layout>
          <Navbar />
          <MenuIcon onClick={openSide} className="hamburgerone" />
          <Box id="hamburger">
            <Layout>
              <SideBar />
            </Layout>
            <MenuIcon onClick={closeSide} className="hamburgertwo" />
          </Box>
          <Content>
            <div className="common_dash_content">
              <Routes>
                <Route exact path="/dashome" element={<DashHome />} />
                <Route exact path="/profile" element={<Profile />} />
                <Route exact path="/Editprofile" element={<EditProfile />} />
                <Route exact path="/notification" element={<Notification />} />
                <Route exact path="/inbox" element={<Inbox />} />
                <Route exact path="/liked" element={<Liked />} />
                <Route exact path="/DDatingCon" element={<DDatingCon />} />
                <Route exact path="/PendingReq" element={<PendingReq />} />
                <Route exact path="/CConnection" element={<CConnection />} />
                <Route exact path="/DPlace" element={<DPlace />} />
                <Route exact path="/DCommunity" element={<DCommunity />} />
                <Route exact path="/DDating" element={<DDating />} />
                <Route exact path="/DRacipes" element={<DRacipes />} />
                <Route exact path="/DAbout" element={<DAbout />} />
                <Route exact path="/Feedback" element={<Feedback />} />
                <Route exact path="/Terms" element={<Terms />} />
                <Route exact path="/Help" element={<Help />} />
                <Route exact path="/Account" element={<Account />} />
                <Route exact path="/add_post" element={<AddPost />} />
                <Route exact path="/matching" element={<Matching />} />
                <Route exact path="/restaurant/:id" element={<Restaudetails />} />
                <Route exact path="/user_profile" element={<UserProfile />} />
                <Route exact path="/cc_profile/:id" element={<CCProfile />} />
                <Route exact path="/c_profile/:id" element={<CommunityProfile />} />
                <Route exact path="/call" element={<Call />} />
                <Route exact path="/video_call" element={<VideoCall />} />
                <Route exact path="/user_profile_pr/:id" element={<UserProfilePR />} />
                <Route exact path="/add_recipes" element={<AddRacipes />} />
                <Route exact path="/show_map" element={<MapCard />} />
                <Route exact path="/delete_Account" element={<DeleteAccount />} />
                <Route path="*" element={<Navigate to={'/dashome'} />} />
              </Routes>
            </div>
          </Content>
          <Footer>
            <DashFooter />
          </Footer>
        </Layout>
      </Router>
    </Layout>
  );
};
export default Frame2;