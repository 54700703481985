import { applyMiddleware, createStore, compose } from "redux";
import { thunk } from "redux-thunk";
import root from "./redux/reducer";

const initialState = {};
const middleware = [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(root, initialState, composeEnhancers(applyMiddleware(...middleware)));

export default store;
