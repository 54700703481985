import React, { useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import "./Card.css";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import shareImg from "../../../images/CW/Icon ionic-ios-share-alt@2x.png";
import { Link } from "react-router-dom";
import { FacebookShareButton } from "react-share";
import { getDiff, ImgUrl } from "../../../constants/const";
import { useDispatch, useSelector } from "react-redux";
import { getRecipePost } from "../../../redux/action/recipe";
import dummyRestro from "../../../images/CW/dummyRestro.png";
import dummyProfile from "../../../images/CW/profile.jpeg";
import Spinner from "../../../constants/Spinner";

export default function BreakFastCrad() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const recipeData = useSelector((state) => state?.recipe?.recipe_post?.data);
  useEffect(() => {
    let token = localStorage.getItem("token");
    const menu_id = `628ddaf8d3acde9900c18e1c`;
    const page = "1";
    dispatch(getRecipePost({ menu_id, page }, token));
  }, []);

  useEffect(() => {
    if (recipeData && loading) {
      setLoading(false);
    }
  }, [recipeData]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  // For video player function
  const [playingVideo, setPlayingVideo] = useState(null); // Track the currently playing video
  const videoRefs = useRef([]);


  const playVideo = (index) => {
    if (playingVideo !== null && playingVideo !== index) {
      // Pause the previously playing video
      videoRefs.current[playingVideo].pause();
    }
    setPlayingVideo(index);
  };

  const stopVideo = (index) => {
    if (playingVideo === index) {
      setPlayingVideo(null);
    }
  };

  return (
    <>
      {loading ? (<Spinner />) : (
        <>
          <Box className="recipes_card_main_box">
            <Grid container spacing={2}>
              {recipeData?.length > 0 ? recipeData?.map((data, id) => {
                return (
                  <Grid item xs={12} sm={12} md={12} lg={12} key={id}>
                    <Card sx={{ width: 1 }}>
                      {/* ... (other card content) ... */}
                      {data?.media_type === "image" ? (
                        // Render image
                        <CardMedia  className="community_card" component="img" image={data?.media !== "" ? `${ImgUrl}${data?.media}` : dummyRestro} alt="dish_image" />
                      ) : (
                        // Render video
                        <div className="row">
                          <video
                            className="community_card"
                            controls
                            ref={(video) => (videoRefs.current[id] = video)} // Store the video element in a ref array
                            onPlay={() => playVideo(id)}
                            onEnded={() => stopVideo(id)}
                          >
                            <source src={data?.media !== "" ? `${ImgUrl}${data?.media}` : ''} type="video/mp4" />
                          </video>
                        </div>
                      )}
                      {/* ... (other card content) ... */}
                    </Card>
                  </Grid>
                );
              }) : <p style={{ color: 'orange', margin: '2em', display: 'flex', justifyContent: 'center', flex: '1' }}>No data found!</p>}

            </Grid>
          </Box>
        </>
      )}
    </>
  );
}