import {
  SEND_FEEDBACK,
  UPDATE_GALLERY,
  GET_USER_PROFILE,
  GET_USER_GALLERY,
  UPDATE_USER_PROFILE,
  ADD_PROFILE_GALLERY,
  DELETE_PROFILE_IMAGE,
  USER_TOKEN,
  BADGESDATA_SIDEBAR,
  TERMS_ABOUT,
  HELP,
  GOOGLE_PLACE_API,
  RESTRO_DETAILS,
  DELETE_ACCOUNT
} from "../constants";
import { BASE_URL, authToken } from "../../constants/const";
import { ApiCall } from "../../api";

const token = localStorage.getItem("token") ? localStorage.getItem("token") : null;

export const updateUserProfile = (data, successCb) => async (dispatch) => {
  const form = new FormData();
  form.append("profile_pic", data?.profile_pic ?? "");
  form.append("full_name", data?.full_name ?? "");
  form.append("username", data?.username ?? "");
  form.append("gender", data?.gender ?? "");
  form.append("bio", data?.bio ?? "");
  form.append("dob", data?.dob ?? "");
  form.append("age", data?.age ?? "");
  form.append("dating", data?.dating ?? "");
  form.append("account_type", data?.account_type ?? "");
  let config = {
    method: "post",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "multipart/form-data",
    },
    url: `${BASE_URL}users/update_profile`,
    data: form,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response?.data);
  }
  dispatch({
    type: UPDATE_USER_PROFILE,
    payload: response?.data,
  });
};

export const getUserData = (token) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_profile`,
  };
  let response = await ApiCall(config, dispatch);
  if (!response.data.status) {
    // localStorage.removeItem("token");
  } else {
    dispatch({
      type: GET_USER_PROFILE,
      payload: response.data,
    });
    dispatch({
      type: USER_TOKEN,
      payload: token,
    });
  }
};

export const setUserData = (payload) => async (dispatch) => {
  dispatch({
    type: GET_USER_PROFILE,
    payload: payload,
  });
};

// Done
export const updateGallery = (data, successCb) => async (dispatch) => {
  const form = new FormData();
  form.append("gallery_media", data?.gallery_media ?? "");
  form.append("_id", data?._id ?? "");
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/update_gallery_image`,
    data: form,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response?.data);
  }
  if (!response.data.status) {
    // localStorage.removeItem("token");
  } else {
    dispatch({
      type: UPDATE_GALLERY,
      payload: response.data,
    });
    dispatch({
      type: USER_TOKEN,
      payload: token,
    });
  }
};

export const addProfileGallery = (data, successCb) => async (dispatch) => {
  const form = new FormData();
  form.append("gallery_media", data.gallery_media ?? "");
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/add_profile_gallery`,
    data: form,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response?.data);
  } else {
    dispatch({
      type: ADD_PROFILE_GALLERY,
      payload: response.data,
    });
    dispatch({
      type: USER_TOKEN,
      payload: token,
    });
  }
};

// Done
export const deleteProfileImage = (data, successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/delete_gallery_media`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    // localStorage.removeItem("token");
    successCb(response?.data);
  } else {
    dispatch({
      type: DELETE_PROFILE_IMAGE,
      payload: response.data,
    });
    dispatch({
      type: USER_TOKEN,
      payload: token,
    });
  }
};

// Done
export const deleteUserAccount = (data, successCb, failureCallback) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/delete_account`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response?.data?.status) {
    successCb(response?.data);
  } else {
    failureCallback(response?.data)
    dispatch({
      type: DELETE_ACCOUNT,
      payload: response?.data,
    });
  }
};

// Done
export const sendFeedbackForm = (data, successCb) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/send_feedback`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response?.data);
  }
  dispatch({
    type: SEND_FEEDBACK,
    payload: response?.data,
  });
};

export const getUserGallery = () => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_user_gallery`,
  };
  let response = await ApiCall(config, dispatch);
  if (!response.data.status) {
  } else {
    dispatch({
      type: GET_USER_GALLERY,
      payload: response.data,
    });
    dispatch({
      type: USER_TOKEN,
      payload: token,
    });
  }
};

// Terms_&_Condition + About API
export const termsAbout = () => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_terms_conditions`,
  };
  let response = await ApiCall(config, dispatch);
  dispatch({
    type: TERMS_ABOUT,
    payload: response?.data,
  });
};

// Help API
export const getHelp = () => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_help_faq`,
  };
  let response = await ApiCall(config, dispatch);
  dispatch({
    type: HELP,
    payload: response?.data,
  });
};

// Google_place_api API
export const googlePlaceApi = (data, successCb, failureCallback) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/google_places_api`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response.data);
  } else {
    failureCallback(response.data)
  }
  dispatch({
    type: GOOGLE_PLACE_API,
    payload: response?.data,
  });
};
// Restro Detail Page API
export const restroDetailPage = (data, successCb, failureCallback) => async (dispatch) => {
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/google_places_detail_api`,
    data,
  };
  let response = await ApiCall(config, dispatch);
  if (response.data.status) {
    successCb(response.data);
  } else {
    failureCallback(response.data)
  }
  dispatch({
    type: RESTRO_DETAILS,
    payload: response?.data,
  });
};

// sidebarBadgesdata
export const sidebarBadgesdata = () => async (dispatch) => {
  let counter = []
  let config = {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    url: `${BASE_URL}users/get_my_like_notification`,
  };
  let response1 = await ApiCall(config);
  if (response1?.data?.status) {
    let badge = response1?.data?.data?.filter((ld) => ld?.is_read == 0)?.length
    counter?.push({
      key: "Liked",
      badge
    })
  } else {
    counter?.push({
      key: "Liked",
      badge: 0
    })
  }
  // Notification Badges
  let response2 = await ApiCall({ ...config, url: BASE_URL + "users/get_all_notifications" });
  if (response2?.data?.status) {
    let badge = response2?.data?.data?.filter((ld) => ld?.is_read == 0)?.length
    // console.log("10", badge)
    counter?.push({
      key: "Notification",
      badge
    })
  } else {
    counter?.push({
      key: "Notification",
      badge: 0
    })
  }

  // pendingRequest Badges
  let prCommunity = await ApiCall({ ...config, url: BASE_URL + "users/get_all_notifications" });
  if (prCommunity?.data?.status) {
    let badge = prCommunity?.data?.data?.filter((ld) => ld?.type == 'follow_request' && ld?.is_read == 0)?.length
    counter?.push({
      key: "Pending_Request Community",
      badge
    })
  } else {
    counter?.push({
      key: "Pending_Request Community",
      badge: 0
    })
  }

  let prDating = await ApiCall({ ...config, url: BASE_URL + "users/get_all_notifications" });
  if (prDating?.data?.status) {
    let badge = prCommunity?.data?.data?.filter((ld) => ld?.type == 'right_swipe' && ld?.is_read == 0)?.length
    counter?.push({
      key: "Pending_Request Dating",
      badge
    })
  } else {
    counter?.push({
      key: "Pending_Request Dating",
      badge: 0
    })
  }

  dispatch({
    type: BADGESDATA_SIDEBAR,
    payload: counter
  });
};
//   if (prCommunity?.data?.status) {
//     let badge = prCommunity?.data?.data?.filter((ld) => ld?.follow_request == 'pending')
//     console.log("badge 1111 ==>> ", prCommunity?.data?.data)
//     counter?.push({
//       key: "Pending_Request Community",
//       badge: badge?.length > 0 ? badge?.length : 0
//     })
//   } else {
//     counter?.push({
//       key: "Pending_Request Community",
//       badge: 0
//     })
//   }

//   let prDating = await ApiCall({ ...config, url: BASE_URL + "users/get_request_list" });
//   if (prDating?.data?.status) {
//     let badge = prDating?.data?.data?.filter((ld) => ld?.is_read == 0)?.length
//     counter?.push({
//       key: "Pending_Request Dating",
//       badge
//     })
//   } else {
//     counter?.push({
//       key: "Pending_Request Dating",
//       badge: 0
//     })
//   }

//   dispatch({
//     type: BADGESDATA_SIDEBAR,
//     payload: counter
//   });
// };
